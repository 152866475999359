import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css'; // Import ReactTable CSS
import { FaEdit, FaTrash } from 'react-icons/fa'; // Import icons for edit and delete actions
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Card from '../../../components/Card/Card';
import CardHeader from '../../../components/Card/CardHeader';
import Button from '../../../components/CustomButtons/Button';
import CardBody from '../../../components/Card/CardBody';
import { cardTitle } from "../../../assets/jss/material-dashboard-pro-react.jsx";
import withStyles from "@material-ui/core/styles/withStyles"; // Import withStyles
import axios from 'axios'
import { withRouter } from 'react-router-dom';
const { REACT_APP_ADMIN_PATH,REACT_APP_Domain_Url } = process.env;
const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    buttonStyle: {
        float: "right",
        position: "relative",
        marginTop: "20px"
    },
    smallButton: {
        marginRight: "5px",
        backgroundColor: "#00acc1 !important",
        marginTop: "25px"
    },
    imageCell: {
        display: 'flex',
        justifyContent: 'center'
    },
    image: {
        width: '50px',
        height: '50px',
        borderRadius: '50%'
    }
};

class BlogList extends React.Component {
    state = {
        data: []
    };

    componentDidMount = async () => {
        await this.getBlogListFun();
    };

    getBlogListFun = async () => {
        try {
            const res = await axios.post(REACT_APP_Domain_Url + "admin/getAllPosts");
            if (res.data.status === 200) {
                this.setState({ data: res.data.posts });
            } else {
                this.setState({ data: [] });
            }
        } catch (error) {
            console.log(error);
        }
    };

    handleDelete = async (postId) => {
        try {
            const res = await axios.post(`${REACT_APP_Domain_Url}admin/deletePostById`, {post_id : postId});
            if (res.data.status === 200) {
                this.getBlogListFun();
            } else {
                console.log("Failed to delete post");
            }
        } catch (error) {
            console.error("Error deleting post:", error);
        }
    };

    handleEdit = (rowData) => {
        this.props.history.push(`${REACT_APP_ADMIN_PATH}/add-blog?${rowData._id}`);
    };


    renderMyTable = () => {
        const { classes } = this.props;
        const { data } = this.state;
        const columns = [
            {
                Header: 'Image',
                accessor: 'image',
                Cell: row => (
                  <div className={classes.imageCell}>
                    <img 
                      src={REACT_APP_Domain_Url + row.value} 
                      alt="Post" 
                      className={classes.image} 
                      onError={(e) => { console.error("Error loading image:", e); }} // Error handling
                    />
                  </div>
                )
            },
          {
            Header: 'Post Title',
            accessor: 'title'
          },
          {
            Header: 'Description',
            accessor: 'content',
            Cell : row => (
                <div dangerouslySetInnerHTML={{ __html: row.value }} />
            )
          },
          {
            Header: 'Date',
            accessor: 'created_at'
          },
          {
            Header: 'Actions',
            sortable: false,
            accessor: '_id',
            Cell: row => (
                <div className="actions-right">
                <Button
                    onClick={() => this.handleEdit(row.original)}
                    justIcon
                    round
                    simple
                    color="twitter"
                    className="edit"
                >
                    <FaEdit />
                </Button>{" "}
                <Button
                    justIcon
                    round
                    simple
                    color="danger"
                    className="remove"
                    onClick={() => this.handleDelete(row.value)}
                >
                    <FaTrash />
                </Button>{" "}
            </div>
            )
          }
        ];

        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader icon>
                            <Button
                                onClick={() => { this.props.history.push(`${REACT_APP_ADMIN_PATH}/add-blog`); }}
                                color="success"
                                className={classes.buttonStyle} 
                            >
                                Add Blog
                            </Button>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                            data={data}
                            columns={columns}
                            filterable
                            defaultPageSize={10}
                            className="-striped -highlight"
                                minRows={0}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    };

    render() {
        return (
          <div>
            {this.renderMyTable()}
          </div>
        );
    }
}

export default withStyles(styles)(withRouter(BlogList));
