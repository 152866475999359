import {
  dashboardUserComponent,
  ProductDetailsComponent,
  EditprofileComponent,
  OrderViewComponent,
  AdminLoginComponent,
  ContactUsUserComponent,
  ResetComponent,
  SellerDetailsComponent,
  ChatComponent,
  UserDetailComponent, 
  Fingerprint,
  StaticComponent,
  PaymentComponent,
  ProductCategoriesComponent,
  ProductCategoryByIDComponent,
  CategoryVendorComponent,
  VendorCategoriesComponent,
  VendorSubCategoriesComponent,
  FilterVendorComponent,
  BlogDetailsComponent,
  GigsComponent,
  EditProfile2Compoonent,
  BlogComponent
} from './ImportTitles.js'

const { REACT_APP_ADMIN_PATH } = process.env;

const pagesRoutes = [
  {
    path: `${REACT_APP_ADMIN_PATH }/login`,
    name: "Login Page",
    short: "login",
    mini: "LP",
    icon: Fingerprint,
    component: AdminLoginComponent
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    short: "dashboard",
    mini: "DH",
    component: dashboardUserComponent,
    redirect:""
  },
  {
    path: "/pages/:url",
    name: "Privacy Policy",
    short: "privacy policy",
    mini: "PP",
    component: StaticComponent
  },
  {
    path: '/vendor',
    name: "vendorDetails",
    short: "vendorDetails",
    component:  VendorCategoriesComponent,
  },
  {
    path: '/vendorsubcategory/:id',
    name: "vendorSubcategoriesDetails",
    short: "vendorSubcategoriesDetails",
    component:  VendorSubCategoriesComponent,
  },
  {
    path: '/gigslist',
    name: "GigList",
    short: "GigList",
    component:  GigsComponent,
  },
  {
    path: "/Info/contact",
    name: "About Us",
    short: "about us",
    mini: "AP",
    component: ContactUsUserComponent
  },
  {
    path: "/categories",
    name: "Category",
    short: "category",
    mini: "CT",
    component: ProductCategoriesComponent
  },
  {
    path: "/category-product/:id",
    name: "Product By Category",
    short: "product category",
    mini: "CI",
    component: ProductCategoryByIDComponent
  },
  {
    path: "/category-vendor",
    name: "Product By Category",
    short: "product category",
    mini: "CI",
    component: CategoryVendorComponent
  },
  
  {
    path: "/filter-vendor",
    name: "Filter By Vendor",
    short: "Filter By Vendor",
    mini: "FV",
    component: FilterVendorComponent
  },
  
  {
    path: "/reset-password/:id",
    name: "Reset",
    short: "reset",
    mini: "RP",
    component: ResetComponent
  },

  {
    path: "/EditProfile/:id",
    name: "Reset",
    short: "reset",
    mini: "RP",
    component: EditprofileComponent
  },
  {
    path: "/products/:id",
    name: "Reset",
    short: "reset",
    mini: "RP",
    component: ProductDetailsComponent
  },
  {
    path: "/OrderView/:id",
    name: "Reset",
    short: "reset",
    mini: "RP",
    component: OrderViewComponent
  },
  {
    path: "/api/payment",
    name: "payment",
    short: "payment",
    mini: "pay",
    component: PaymentComponent
  },

  {
    path: "/SellerDetails/:userId",
    name: "Reset",
    short: "reset",
    mini: "RP",
    component: SellerDetailsComponent
  },


  {
    path: "/chat/conversation",
    name: "Reset",
    short: "reset",
    mini: "RP",
    component: ChatComponent
  },
  {
    path: "/blog",
    name: "Reset",
    short: "reset",
    mini: "RP",
    component: BlogComponent
  },
  {
    path: "/blog-details/:id",
    name: "Reset",
    short: "reset",
    mini: "RP",
    component: BlogDetailsComponent
  },
  {
    path: "/user-details",
    name: "Reset",
    short: "reset",
    mini: "RP",
    component: UserDetailComponent
  },
  {
    path: "/editprofile2",
    name: "Reset",
    short: "reset",
    mini: "RP",
    component: EditProfile2Compoonent
  },


  { redirect: true, path: "/reset-password/:id", pathTo: "/reset-password", name: "Reset_Password" }
];

export default pagesRoutes;
