import styles from "../../../assets/jss/material-dashboard-pro-react/components/loginComponent.jsx";
import React from "react";
import Footer from "../Footer/Footer";
import { withStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { animateScroll as scroll } from "react-scroll";
import { compose, graphql } from "react-apollo";
import { GET_CATEGORIES } from "../../../queries";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ScrollTop } from "../css/styledcomponents.js";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";

class ProductCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cUser: {},
      categories: [],
    };
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  componentScroll = () => {
    const scope = this;
    var winheight =
      window.innerHeight ||
      (document.documentElement || document.body).clientHeight;
    var D = document;
    var docheight = Math.max(
      D.body.scrollHeight,
      D.documentElement.scrollHeight,
      D.body.offsetHeight,
      D.documentElement.offsetHeight,
      D.body.clientHeight,
      D.documentElement.clientHeight
    );
    var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    var trackLength = docheight - winheight;
    var pctScrolled = Math.floor((scrollTop / trackLength) * 100);
    if (pctScrolled > 10) {
      scope.setState({
        showScroll: true,
      });
    } else {
      scope.setState({
        showScroll: false,
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener(
      "scroll",
      () => {
        this.componentScroll();
      },
      true
    );
  }

  scrollToTop() {
    scroll.scrollToTop();
  }
  componentDidMount() {
    let { categoryInfo } = this.props;
    let categories = [];
    if (localStorage.getItem("lang")) {
      categoryInfo
        .refetch()
        .then(({ data }) => {
          if (data) {
            categories =
              data.getCategoryDetails && data.getCategoryDetails.category;
            console.log("categories", categories);
            this.setState({
              categories: categories,
            });
          }
        })
        .catch((e) => console.log(e));
    }
    window.addEventListener(
      "scroll",
      () => {
        this.componentScroll();
      },
      true
    );
  }
  render() {
    let { categories, cUser } = this.state;
    const { showScroll } = this.state;
    return (
      <div>
        <div className="explore">
          <Container>
            <Row className="justify-content-center">
              <Col md={10} xl={8} lg={7} sm={10}>
                <div className="section-tittle text-center mb-50">
                  <h2 className="tittle">
                    <span class="shape"></span>Party Supplies by Occasion
                  </h2>
                  <p>Find Decorations for Birthdays, Graduations, and More!</p>
                </div>
              </Col>
            </Row>

            <ul
              className={`cls_licount "nn_licount "}`}
              ref={(cate) => {
                this.cate = cate;
              }}
            >
              {categories
                .slice() // create a shallow copy to avoid mutating original array
                .sort((a, b) => a.name.localeCompare(b.name)) // sort categories alphabetically
                .map((c, i) => {
                  return (
                    <li
                      key={c.id}
                      ref={(li) => {
                        this.li = li;
                      }}
                      className={" nn_catealign"}
                      title={c.name}
                    >
                      <div className="nn_categoryctn">
                        {c.id ? (
                          <Link to={{ pathname: `/category-product/${c.id}` }}>
                            <img
                              src={c.image}
                              alt=""
                              className="cateimg category-icon"
                              ref={(li1) => {
                                this.li1 = li1;
                              }}
                            />
                          </Link>
                        ) : (
                          ""
                        )}
                      </div>
                      <Link
                        to={{ pathname: `/category-product/${c.id}` }}
                        className="nn_catetitle text-truncate d-block"
                      >
                        {c.name.length > 16
                          ? c.name.slice(0, 15) + "..."
                          : c.name}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </Container>
          {showScroll && (
            <ScrollTop>
              <div
                className={
                  cUser && cUser.id
                    ? "anchor-fixed cls_loganchor"
                    : "anchor-fixed"
                }
                onClick={this.scrollToTop}
              >
                <a>
                  <span>
                    {" "}
                    <i className="fa fa-chevron-up" aria-hidden="true"></i>
                  </span>{" "}
                </a>
              </div>
            </ScrollTop>
          )}
        </div>
        <div className="newcontacrf">
          <Footer />
        </div>
      </div>
    );
  }
}

var CategoryFilter = compose(
  graphql(GET_CATEGORIES, {
    name: "categoryInfo",
    options: () => ({
      fetchPolicy: "no-cache",
    }),
  })
)(ProductCategories);

export default withTranslation("common")(withStyles(styles)(CategoryFilter));
