import React, { Component, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { EditProfileMain } from "../css/styledcomponents";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Card from "react-bootstrap/Card";
import gigs from "../../../assets/img/imageGigs.png"
import GradeIcon from "@mui/icons-material/Grade";
import {  Grid } from "@material-ui/core";
import InputRange from "react-input-range-rtl";
import vendorconstant from "../../vendorconstant";
import axios from "axios";
import ChatWindowVendor from "../Chat/ChatWindowVendor";
import ReactStars from "react-rating-stars-component";
import { compose, graphql, ApolloConsumer } from "react-apollo";
import { withTranslation } from "react-i18next";
import {POPUP_STATE_UPDATE} from "../../../queries";
import withStyles from "@material-ui/core/styles/withStyles";
import pagesStyle from "../../../assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";

class EditProfile2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: { max: 30, min: 0 },
      vendorData : {},
      relatedGigs : [],
      baseUrl : "",
      rating : "",
      userid :""
    };
  }
  componentDidMount() {
    let { currentUser} = this.props;
    if(currentUser.getCurrentUser !== null){
     this.setState({userid : currentUser.getCurrentUser.id})
     console.log("currentuserid",currentUser.getCurrentUser.id)
    }
    const datastring = {
      id: this.props.match.params.id
  };
  axios
      .post(vendorconstant.API_URL + "vendor/getgigsvendordata", datastring)
      .then((response) => {
        if(response.data.status == "success"){
        this.setState({vendorData : response.data.vendorData})
        this.setState({relatedGigs : response.data.gigsdata})
        this.setState({baseUrl : response.data.baseurl})
        }
      })
  }
   ratingChanged = (newRating) => {
    this.setState({rating : newRating})
 
  };

  submitRatings=()=>{
    let { currentUser, updateLoginPopupStatus } = this.props;
        if (currentUser.getCurrentUser !== null){
          const datastring = {
            userid: this.state.userid,
            vendorid: this.state.vendorData._id,
            ratings: this.state.rating
        };
        axios
            .post(vendorconstant.API_URL + "vendor/addvendorratingsdata", datastring)
            .then((response) => {
              if(response.data.status == "success"){
                alert(response.data.message)
              }
              else if (response.data.status == "error"){
                alert(response.data.message)
              }
            })
        }
        else{
            updateLoginPopupStatus({ variables: {isOpen: true}});  
        }
   
  }

  handleLogin(e, open) {
    e.preventDefault();
    let { updateLoginPopupStatus, session } = this.props;
    updateLoginPopupStatus({ variables: { isOpen: open } });
}

  render() {
    let { classes } = this.props;
    const gigsdata = [
      {
        image: gigs,
        name: "Henery",
        amount: "$10",
        description: "Lorem Ipsum is simply dummy text of the printing...",
      },
      {
        image: gigs,
        name: "Henery",
        amount: "$10",
        description: "Lorem Ipsum is simply dummy text of the printing...",
      },
      {
        image: gigs,
        name: "Henery",
        amount: "$10",
        description: "Lorem Ipsum is simply dummy text of the printing...",
      },
      {
        image: gigs,
        name: "Henery",
        amount: "$10",
        description: "Lorem Ipsum is simply dummy text of the printing...",
      },
      {
        image: gigs,
        name: "Henery",
        amount: "$10",
        description: "Lorem Ipsum is simply dummy text of the printing...",
      },
    ];
    return (
      <>
        <div id="content">
          <EditProfileMain>
            <div className="nn_edit_profile">
              <div className="container-fluid">
                <div className="wrapper-edit nn_wrapperedit">
                  <div className="nn_edit_promain">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={3}>
                        <nav className="nn_tabs_nav">
                          <div className="wrappperclass nn_edit_pro_main">
                            <div className="nn_edit_pro">
                              <div className="nn_edit_pro_img">
                                <img src="https://myplo.com/fileStorage/static/default.png" />
                              </div>
                              <div className="nn_edit_pro_ctn">
                                <h4 className="text-truncate"> {this.state.vendorData.vendorname}</h4>
                                <div><svg class="MuiSvgIcon-root-78" focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{width:"20px"}}><path fill="none" d="M0 0h24v24H0V0z" ></path><g><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"></path><circle cx="12" cy="9" r="2.5"></circle></g></svg>{this.state.vendorData.vendoraddress}</div>
                                <span>
                                <ReactStars count={5} onChange={this.ratingChanged} size={24} activeColor="#ffd700" />
                              
                                </span>

                                <div className="border-topB btn-wrapper">
                      <button
                        type="submit"
                       className="cmn-btn2 w-100"
                      //  onClick={(e) => this.setState({ openChat: true })}

onClick={this.submitRatings}>
                        Submit
                      </button>
                    </div >
                              </div>
                            </div>
                          </div>
                          <div className="nav nav-tabs nav-fill px-4" id="nav-tab" role="tablist">
                              <p>{this.state.vendorData.vendordescription}</p>
														</div>
                        </nav>
                      </Grid>
                      <Grid item xs={12} sm={12} md={9}>
                        <div
                          className="tab-content nn_tab_ctn"
                          id="nav-tabContent"
                        >
                          <div
                            className="tab-pane fade show active  nn_edit_sellpromain"
                            id="nav-gigs"
                            role="tabpanel"
                            aria-labelledby="nav-order-tab"
                          >
                            <div className="nn_edit_proname">
                            {this.state.vendorData.companyname} Services
                            </div>
                            <div className="product-list nn_edit_sellpro">
                              <div className="nn_edit_allproducts">
                                <div className="gigssouterfv mb-5">
                                  <Row>
                                    {this.state.relatedGigs.map((value) => (
                                   
                                        <Col lg={3} md={4} sm={6} className="mb-lg-4 mb-4">
                                          <div
                                            className="gigs-cat-outer">
                                            <div
                                              className="nn_homproductctn">
                                              <Link to={'/category-vendor/'+value._id}>                    
                                                <Card.Img variant="top" src={value.gigImages && value.gigImages.length > 0 ?this.state.baseUrl+value.gigImages[0].image:gigs} />
                                              </Link>
                                            </div>
                                            <div className="allprodetails card-content">
                                              <div className="titlegigs-cat">
                                                <div className="inner-catgigstitle">
                                                  <div className="d-flex align-items-center">
                                                    <AccountCircleIcon style={{ fontSize: "30px", marginRight: "5px", color: "#556080" }} />
                                                    <h4>{value.title.slice(1,20)}</h4>
                                                  </div>
                                                  <h6> ${value.price}</h6>
                                                </div>

                                                <p>{value.description.slice(1,40)}</p>

                                              </div>
                                              <div className="d-flex">
                                              {Array.from({ length: 5 }, (_, i) =>
                      i < value.ratings ? (
                        <GradeIcon key={`colored-${i}`} style={{ color: "#E99318" }} />
                      ) : (
                        <GradeIcon key={`gray-${i}`} style={{ color: "#A4A4A4" }} />
                      )
                    )}
                                              </div>
                                            </div>
                                          </div>
                                        </Col>                                    
                                    ))}
                                  </Row>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </EditProfileMain>
        </div>
      </>
    );
  }
}
var EditProfile2export = compose(
	graphql(POPUP_STATE_UPDATE, { name: "updateLoginPopupStatus" }),
)(EditProfile2);

export default withTranslation("common")(
	withStyles(pagesStyle)(EditProfile2export)
);
