import React, { useState, useEffect } from "react";
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import Footer from "../Footer/Footer";
import { ScrollTop } from "../css/styledcomponents";
import { Col, Container, Row } from "react-bootstrap";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import CardBody from "../../../components/Card/CardBody";
import CustomInput from "../../../components/CustomInput/CustomInput";
import Button from "../../../components/CustomButtons/Button";
import axios from 'axios'
import { withRouter } from 'react-router-dom';
import { GET_CURRENT_USER } from "../../../queries";
const BlogDetails = ({ match, currentUser }) => {
  const { REACT_APP_Domain_Url } = process.env;
  const id = match.params.id
  const [showScroll, setShowScroll] = useState(false);
  const [msg, setMsg] = useState("");
  useEffect(() => {
    getBlogDetails()
    getCommentListFun()
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, [id]);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [blogsList, setBlogsList] = useState(null)
  const [commentList, setCommentList] = useState([])
  const getBlogDetails = async () => {
    try {
      const res = await axios.post(REACT_APP_Domain_Url + "admin/getPostById", { post_id: id });
      if (res.data.status == 200) {
        setBlogsList(res.data.post)
      } else {
        setBlogsList(null)
      }
    } catch (error) {
      console.log(error)
    }
  }


  const getCommentListFun = async () => {
    try {
      const res = await axios.post(REACT_APP_Domain_Url + "admin/getCommentsByPostId", { post_id: id });
      console.log("getCommentListFun", res)
      if (res.data.status == 200) {
        setCommentList(res.data.comments)
      } else {
        setCommentList(null)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const [value, setValue] = useState({
    "post_id": id,
    "comment": "",
    "user_id": currentUser && currentUser.getCurrentUser && currentUser.getCurrentUser.id
  })


  const handleCommentSubmit = async () => {
    try {
      if (!value.comment) {
        setMsg("Please write a comment.");
        return;
      }
      const res = await axios.post(REACT_APP_Domain_Url + "admin/createComment", value);
      if (res.data.status == 201) {
        setValue({ ...value, 'comment': '' })
        setMsg("Comment added succesfully! please wait for the admin approval.");
      } else {
        setMsg("Something went wrong");
      }
    } catch (error) {
      console.log(error)
      setMsg("Something went wrong");
    }
  }

  return (
    <>
      <div className="blog-listing">
        <Container>
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="blog-detail-pag">
                {
                  blogsList &&
                  <img className="img-blog-details" src={REACT_APP_Domain_Url + blogsList.image}
                    alt="img"
                  />
                }
                <h2>{blogsList ? blogsList.title : ""}</h2>
                <div class="key-box-inner">
                  <div dangerouslySetInnerHTML={{ __html: blogsList && blogsList.content }} />
                </div>
              </div>
              <Row>
                <Col md={12}>
                  <div className="single-post-item">

                    <div className="conactvw contact-outer">
                      <h3>Post Comments</h3>

                      <ul className="comments-box">
                        {
                          commentList.length > 0 ?
                            <>
                              {
                                commentList.map((item) => (
                                  <li className="comment">
                                    <div className="comment-inner">
                                      <div className="comment-thumb">
                                        {
                                          blogsList &&
                                          <img src={REACT_APP_Domain_Url + blogsList.image}
                                            alt="img"
                                          />
                                        }
                                      </div>
                                      <div className="comment-wrap">
                                        <div className="comments-meta">
                                          <h4 className='text-capitalize'>
                                            {item.userName}
                                            {/* <span>4 May 2024</span> */}
                                          </h4>
                                        </div>
                                        <div className="comment-area">
                                          <p>{item.comment}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                ))
                              }
                            </>
                            :
                            <>
                              <h5>There no comment to display</h5>
                            </>
                        }

                      </ul>


                      <div className="section-tittle ">
                        <h3>Leave a Comment</h3>
                      </div>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <CardBody className="p-0">
                            <GridContainer className="mbing-bottm">
                              <GridItem xs={12} sm={12} className="nn_feedback form-control-input textarea-input">
                                <textarea className="form-control" value={value.comment} onChange={(e) => { setValue({ ...value, 'comment': e.target.value }); value.comment !== "" && setMsg('') }}></textarea>
                              </GridItem>
                              <div className="section-tittle px-3 mt-2">
                                <h6>{msg}</h6>
                              </div>
                            </GridContainer>
                            <GridContainer>
                              <GridItem xs={12} sm={12}>
                                <div className="sav_chang contsave btn-wrapper w-100">
                                  {
                                    currentUser.getCurrentUser ?
                                      <Button className="cmn-btn2 w-100" onClick={() => handleCommentSubmit()}>
                                        Submit
                                      </Button>
                                      :
                                      <button className="cmn-btn2 w-100" disabled={true}>
                                        Submit
                                      </button>
                                  }
                                </div>
                              </GridItem>
                            </GridContainer>
                          </CardBody>
                        </GridItem>
                      </GridContainer>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
      <div className="newcontacrf">
        <Footer />
      </div>
      {showScroll && (
        <ScrollTop>
          <div className="anchor-fixed" onClick={scrollToTop}>
            <span>
              {" "}
              <i className="fa fa-chevron-up" aria-hidden="true"></i>
            </span>{" "}
          </div>
        </ScrollTop>
      )}
    </>
  );
};

export default compose(
  withRouter,
  graphql(GET_CURRENT_USER, { name: "currentUser" }),
)(BlogDetails);
