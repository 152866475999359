import React, { Component, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import { Mutation } from "react-apollo";
import EditIcon from "@material-ui/icons/Edit";
import ShareIcon from "@material-ui/icons/Share";
import importuser from "../../../assets/img/importuser.png";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Error from "../../../assets/img/404error.jpg";
import headeoc from "../../../assets/img/404error.jpg";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import { Category, ChevronRight } from "@material-ui/icons";
import {
  ScrollTop,
  ProductDetailsMain,
  Loader,
  DiscardPopup,
  FeaturedModal,
} from "../css/styledcomponents";
import SliderWithThumb from "./slider";
import Slider from "react-slick";
import { ProviderRefech, ProductConsumer } from "../ProductContext.js";
import Footer from "../Footer/Footer";
import { Badge } from "react-bootstrap";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Card from "react-bootstrap/Card";
import gigs from "../../../assets/img/imageGigs.png";
import GradeIcon from "@mui/icons-material/Grade";
import axios from "axios";
import vendorconstant from "../../vendorconstant";
import { withRouter } from "react-router";
import ChatWindowVendor from "../Chat/ChatWindowVendor";
import io from "socket.io-client";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "../Login/PaymentMode";
import CategoryVendorPaymentMode from "./Filters/CategoryVendorPaymentMode";
import { withTranslation } from "react-i18next";
import withStyles from "@material-ui/core/styles/withStyles";
import pagesStyle from "../../../assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";
import { compose, graphql, ApolloConsumer } from "react-apollo";
import CloseIcon from "@material-ui/icons/Close";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FacebookShareButton, WhatsappShareButton } from "react-share";
import * as Toastr from "../Toast.jsx";
import Input from "@material-ui/core/Input";
import { POPUP_STATE_UPDATE } from "../../../queries";
import { Query } from "mongoose";
import { GET_LOGIN_POPUP_STATE } from "../../../queries";
import color from "@material-ui/core/colors/amber";
import Tooltip from "@mui/material/Tooltip";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}
const customStylessocila = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    padding: "15px",
  },
};
const customStyles2 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "auto",
  },
};
// const paramid = match.params.id;
// const timestamp = Date.now();
const stripePromise = loadStripe("pk_test_txDJRlXoAFelCWzOrBdENCRS");
const initialState = {
  currentUser: {},
};
class CategoryVendor extends Component {
  handleLogin(e, open) {
    e.preventDefault();
    let { updateLoginPopupStatus, session } = this.props;
    updateLoginPopupStatus({ variables: { isOpen: open } });
  }
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      gigsData: {},
      baseUrl: "",
      imagesArray: [],
      vendorId: {},
      relatedGigs: [],
      categoryData: {},
      relatedgigsimagesArray: [],
      offerSection: false,
      openChat: false,
      offerPrice: "",
      date: "",
      time: "",
      amount: "",
      afterBuyNow: false,
      afterBuyMake: false,
      getRenderStatus: false,
      userid: "",
      modalIsOpenshare: false,
      copied: false,
      toggled: false,
      reportlistco: false,
      reportingList: true,
      vendorBaseUrl: "",
    };
    this.handleLogin = this.handleLogin.bind(this);
  }
  componentDidMount() {
    let { currentUser } = this.props;
    let userid = "";
    if (currentUser.getCurrentUser !== null) {
      this.setState({ userid: currentUser.getCurrentUser.id });
      userid = currentUser.getCurrentUser.id;
    }
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
    const datastring = {
      gigId: this.props.match.params.id,

      userid: userid,
    };
    axios
      .post(
        vendorconstant.API_URL + "frontendgigs/getgigsdatadetail",
        datastring
      )
      .then((response) => {
        let data = response.data.data;
        let baseurl = response.data.baseurl;
        let imagearray = response.data.data.gigImages;
        let vendorid = response.data.data.vendorid;
        let relatedgigs = response.data.relatedgigs;
        let categorydata = response.data.data.category;
        let imagesarray = response.data.relatedgigs.gigImages;
        this.setState({ gigsData: data });
        this.setState({ baseUrl: baseurl });
        this.setState({ imagesArray: imagearray });
        this.setState({ vendorId: vendorid });
        this.setState({ relatedGigs: relatedgigs });
        this.setState({ categoryData: categorydata });
        this.setState({ relatedgigsimagesArray: imagesarray });
        this.setState({ vendorBaseUrl: response.data.vendorBaseUrl });
        if (response.data.favstatus == 1) {
          this.setState({ toggled: true });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  closechatmodel = (status) => {
    console.log(status);
    this.setState({ openChat: !this.state.openChat });
  };
  openModalshare = () => {
    this.setState({ modalIsOpenshare: true });
  };
  handleSendMessage = async () => {
    let { currentUser, updateLoginPopupStatus } = this.props;
    if (currentUser.getCurrentUser !== null) {
      if (this.state.date == "") {
        alert("Pleaae Enter Date");
        return false;
      }
      if (this.state.time == "") {
        alert("Please Enter Time");
        return false;
      }
      if (this.state.offerPrice == "") {
        alert("Pleaae Enter Something");
        return false;
      }
      // Replace 'http://localhost:5000' with the URL of your backend server where the Socket.io server is running
      const socket = io("https://vendor.myplo.com/", {
        withCredentials: true, // Include credentials such as cookies in requests (if applicable)
      });
      // Emit the 'sendMessage' event with the message content
      socket.emit("sendMessage", this.state.offerPrice);
      try {
        const response = await axios.post(
          vendorconstant.API_URL + "chat/sendmessage",
          {
            userid: this.state.userid, // Replace 'user_id' with the actual user ID of the sender
            gigId: this.state.gigsData._id, // Replace 'vendor_id' with the actual user ID of the recipient
            vendorid: this.state.gigsData.vendorid._id,
            content:
              "I would like to purchase this at $" +
              this.state.offerPrice +
              " on " +
              this.state.date +
              " @ " +
              this.state.time,
            sentby: "USER",
            makeanoffer: true,
            amount: this.state.offerPrice,
            date: this.state.date,
            time: this.state.time,
          }
        );
        this.setState({ openChat: true });
        console.log(response.data); // You can handle the response as needed
      } catch (error) {
        console.error("Failed to send message:", error);
      }
      // Reset the message input after sending
      this.setState({ offerPrice: "" });
      this.setState({ date: "" });
      this.setState({ time: "" });
    } else {
      updateLoginPopupStatus({ variables: { isOpen: true } });
    }
  };

  showTable = () => {
    this.setState({ show: false });
    let { currentUser, updateLoginPopupStatus } = this.props;
    if (currentUser.getCurrentUser !== null) {
      if (this.state.date == "") {
        alert("Please Enter Date");
        return false;
      }
      if (this.state.time == "") {
        alert("Please Enter Time");
        return false;
      }
      this.setState({ afterBuymake: true });
    }
  };

  clickonbuynow = () => {
    let { currentUser, updateLoginPopupStatus } = this.props;
    if (currentUser.getCurrentUser !== null) {
      let totalamout = this.state.gigsData.price;

      let twentpecent = totalamout * (1 / 5);
      let finalprice = this.state.price;
      if (this.state.price >= twentpecent) {
        this.setState({ amount: finalprice });
        this.setState({ afterBuyNow: true });

        return false;
      }
      alert("Please 20%");
      this.setState({ afterBuyNow: false });
    } else {
      updateLoginPopupStatus({ variables: { isOpen: true } });
    }
  };

  handleLoader = (value) => {
    this.setState({ getRenderStatus: value });
  };
  openChatNow = () => {
    let { currentUser, updateLoginPopupStatus } = this.props;
    if (currentUser.getCurrentUser !== null) {
      this.setState({ openChat: true });
    } else {
      updateLoginPopupStatus({ variables: { isOpen: true } });
    }
  };

  addfavgigs = () => {
    let { currentUser, updateLoginPopupStatus } = this.props;
    if (currentUser.getCurrentUser !== null) {
      console.log("fav");

      const datastring = {
        gigId: this.props.match.params.id,
        userid: this.state.userid,
        vendorid: this.state.gigsData.vendorid._id,
      };
      axios
        .post(vendorconstant.API_URL + "frontendgigs/savefavgig", datastring)
        .then((response) => {
          if (response.data.status == "success") {
            this.setState({ toggled: true });
            Toastr.success(
              <div className="msgg">
                <div>
                  <svg
                    viewBox="0 0 24 24"
                    width="32"
                    height="32"
                    style={{ fill: "green" }}
                  >
                    <path d="M21.621,12.166 C21.621,6.953 17.38,2.711 12.166,2.711 C6.952,2.711 2.711,6.953 2.711,12.166 C2.711,17.38 6.952,21.622 12.166,21.622 C17.38,21.622 21.621,17.38 21.621,12.166 M23.332,12.166 C23.332,18.324 18.323,23.333 12.166,23.333 C6.009,23.333 1,18.324 1,12.166 C1,6.009 6.009,1 12.166,1 C18.323,1 23.332,6.009 23.332,12.166 M17.274,8.444 C17.43,8.61 17.512,8.829 17.504,9.058 C17.495,9.287 17.398,9.499 17.23,9.654 L10.507,15.93 C10.348,16.078 10.141,16.159 9.925,16.159 C9.695,16.159 9.48,16.07 9.319,15.909 L7.078,13.667 C6.917,13.507 6.827,13.292 6.827,13.064 C6.826,12.835 6.916,12.619 7.078,12.457 C7.4,12.134 7.965,12.134 8.287,12.457 L9.944,14.114 L16.065,8.402 C16.393,8.094 16.965,8.113 17.274,8.444"></path>
                  </svg>
                </div>
                <div>{response.data.message}</div>
              </div>
            );
          } else if (response.data.status == "error") {
            this.setState({ toggled: false });
            Toastr.success(
              <div className="msgg">
                <div>
                  <svg
                    viewBox="0 0 24 24"
                    width="32"
                    height="32"
                    style={{ fill: "red" }}
                  >
                    <path d="M11.09,12.167 L7.589,15.669 C7.291,15.966 7.291,16.448 7.589,16.745 C7.886,17.043 8.368,17.043 8.665,16.745 L12.167,13.244 L15.669,16.745 C15.966,17.043 16.448,17.043 16.745,16.745 C17.042,16.448 17.042,15.966 16.745,15.669 L13.243,12.167 L16.745,8.665 C17.042,8.368 17.042,7.886 16.745,7.589 C16.448,7.291 15.966,7.291 15.669,7.589 L12.167,11.09 L8.665,7.589 C8.368,7.291 7.886,7.291 7.589,7.589 C7.291,7.886 7.291,8.368 7.589,8.665 L11.09,12.167 Z M2.711,12.166 C2.711,17.38 6.953,21.622 12.166,21.622 C17.38,21.622 21.621,17.38 21.621,12.166 C21.621,6.952 17.38,2.711 12.166,2.711 C6.953,2.711 2.711,6.952 2.711,12.166 Z M1,12.166 C1,6.009 6.01,1 12.166,1 C18.323,1 23.332,6.009 23.332,12.166 C23.332,18.323 18.323,23.333 12.166,23.333 C6.01,23.333 1,18.323 1,12.166 Z"></path>
                  </svg>
                </div>
                <div>{response.data.message}</div>
              </div>
            );
          }
        });
    } else {
      updateLoginPopupStatus({ variables: { isOpen: true } });
    }
  };

  copyData = () => {
    let shareUrl = window.location.href;
    // Copy the URL to the clipboard
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        // Update state or show a success message
        this.setState({ copied: true });
        // You can also show a success message to the user here
        console.log("URL copied to clipboard!");
        Toastr.success(
          <div className="msgg">
            <div>
              <svg
                viewBox="0 0 24 24"
                width="32"
                height="32"
                style={{ fill: "green" }}
              >
                <path d="M21.621,12.166 C21.621,6.953 17.38,2.711 12.166,2.711 C6.952,2.711 2.711,6.953 2.711,12.166 C2.711,17.38 6.952,21.622 12.166,21.622 C17.38,21.622 21.621,17.38 21.621,12.166 M23.332,12.166 C23.332,18.324 18.323,23.333 12.166,23.333 C6.009,23.333 1,18.324 1,12.166 C1,6.009 6.009,1 12.166,1 C18.323,1 23.332,6.009 23.332,12.166 M17.274,8.444 C17.43,8.61 17.512,8.829 17.504,9.058 C17.495,9.287 17.398,9.499 17.23,9.654 L10.507,15.93 C10.348,16.078 10.141,16.159 9.925,16.159 C9.695,16.159 9.48,16.07 9.319,15.909 L7.078,13.667 C6.917,13.507 6.827,13.292 6.827,13.064 C6.826,12.835 6.916,12.619 7.078,12.457 C7.4,12.134 7.965,12.134 8.287,12.457 L9.944,14.114 L16.065,8.402 C16.393,8.094 16.965,8.113 17.274,8.444"></path>
              </svg>
            </div>
            <div>{this.props.t("Homepageheader._linkcopied")}</div>
          </div>
        );
      })
      .catch((error) => {
        console.error("Error copying URL to clipboard:", error);
      });
  };

  closeModalshare = () => {
    this.setState({ modalIsOpenshare: false });
  };
  saveReportedGigs = () => {
    let { currentUser, updateLoginPopupStatus } = this.props;
    if (currentUser.getCurrentUser !== null) {
      const datastring = {
        gigId: this.props.match.params.id,
        userid: this.state.userid,
        vendorid: this.state.gigsData.vendorid._id,
      };
      axios
        .post(vendorconstant.API_URL + "frontendgigs/reportedgig", datastring)
        .then((response) => {
          if (response.data.status == "success") {
            this.setState({
              reportlistco: false,
            });
            alert(response.data.message);
            window.location.reload();
          }
        });
    } else {
      updateLoginPopupStatus({ variables: { isOpen: true } });
    }
  };
  reporList = () => {
    this.setState({
      reportlistco: true,
    });
  };

  closereortmodel = () => {
    this.setState({
      reportlistco: false,
    });
  };

  render() {
    let { classes } = this.props;
    var settingsrtl = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 3,
      rtl: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
            dots: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 3,
            infinite: false,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
      ],
    };
    var settingtop = {
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      //rtl: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    var settings = {
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      //rtl: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    var produslider = {
      infinite: false,
      speed: 500,
      arrows: false,
      slidesToScroll: 1,
      fade: true,
      slidesToShow: 1,
    };
    let shareUrl;
    shareUrl = window.location.href;
    function getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      let month = today.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      let day = today.getDate();
      if (day < 10) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    }

    // console.log("Deepak_FullStackDevstate", this.state)
    return (
      <>
        {!this.state.getRenderStatus ? (
          <ProviderRefech>
            <ProductDetailsMain>
              <div className="nn_productdetails">
                <Container>
                  <Grid>
                    <div className="nn_prodetailsctn">
                      <div className="nn_breadcrum breadcrumb">
                        <div className="nn_breadcrumctn">
                          <span className="nn_bredcum" title="Home">
                            {" "}
                            <Link to={'/'}>Home</Link>
                          </span>
                          <span className="nn_bredslash nn_ftrt">
                            {" "}
                            <ChevronRight></ChevronRight>{" "}
                          </span>
                          <span className="nn_ftrt">  <Link to={'/vendor'}>Vendor</Link> </span>
                          <span className="nn_bredslash nn_ftrt">
                            {" "}

                            <ChevronRight></ChevronRight>{" "}
                          </span>
                          <span className="nn_ftrt">
                            {" "}
                            <Link to={'/filter-vendor'}>{this.state.categoryData.name}{" "}</Link>

                          </span>
                          <span className="nn_bredslash nn_ftrt">
                            {" "}
                            <ChevronRight></ChevronRight>{" "}
                          </span>
                          <span className="nn_bretitle">
                            {this.state.gigsData.title}
                          </span>
                        </div>
                      </div>
                      <GridContainer className="nn_gdcontainer">
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          className="pos_rel"
                          id="pos_rel"
                        >
                          <div className="product-left nn_product_bg">
                            <div>
                              <Slider
                                {...settingtop}
                                asNavFor={this.state.nav2}
                                ref={(slider) => (this.slider1 = slider)}
                              >
                                <div>
                                  <video
                                    src={
                                      this.state.baseUrl +
                                      this.state.gigsData.video
                                    }
                                    class="slider"
                                    controls
                                    width="100%"
                                    height={499}
                                  />
                                </div>
                                {this.state.imagesArray.map((value, index) => (
                                  <div>
                                    <img
                                      src={this.state.baseUrl + value.image}
                                      class="slider"
                                    />
                                  </div>
                                ))}
                              </Slider>
                              <div className="thumbnail-slider-wrap">
                                <Slider
                                  {...settings}
                                  asNavFor={this.state.nav1}
                                  ref={(slider) => (this.slider2 = slider)}
                                  slidesToShow={3}
                                  swipeToSlide={true}
                                  focusOnSelect={true}
                                >
                                  <div>
                                    <video
                                      src={
                                        this.state.baseUrl +
                                        this.state.gigsData.video
                                      }
                                      class="slider"
                                      controls
                                      height={85}
                                    />
                                  </div>
                                  {this.state.imagesArray.map(
                                    (value, index) => (
                                      <div>
                                        <img
                                          src={this.state.baseUrl + value.image}
                                          class="slider"
                                        />
                                      </div>
                                    )
                                  )}
                                </Slider>
                              </div>
                            </div>
                          </div>
                        </GridItem>

                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          className="nn_procontainer"
                          id="nn_procontainer"
                        >
                          <div className="bottsnn details_oUter">
                            <div className="product-right cls_prodright">
                              <div className="float-left w-100 cls_sticky nn_prodetails">
                                <div className="nn_prodetails_title">
                                  <div className="nn_pro_title float-left">
                                    <h1 className="featureTittle">
                                      {this.state.gigsData.title}
                                    </h1>
                                  </div>
                                  <div className="nn_pro_amt float-left">
                                    <span className="featureTittle">
                                      Price :
                                      <span className="nn_currency ml-1">
                                        ${this.state.gigsData.price}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                                <div className="nn_pro_share">
                                  <ul>
                                    <li>
                                      <button
                                        className="nn_bttn"
                                        type="button"
                                        onClick={this.addfavgigs}
                                      >
                                        <span>
                                          <svg
                                            className={`MuiSvgIcon-root-141 button ${this.state.toggled
                                              ? "toggled"
                                              : ""
                                              }`}
                                            focusable="false"
                                            viewBox="0 0 24 24"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            style={{
                                              fill: "currentcolor",
                                              userSelect: "none",
                                              display: "inline-block",
                                              verticalAlign: "middle",
                                              lineHeight: 1,
                                              transition: "fill 0.25s ease 0s",
                                              color: this.state.toggled
                                                ? "var(--theme-color)"
                                                : "",
                                            }}
                                          >
                                            <path
                                              fill="none"
                                              d="M0 0h24v24H0V0z"
                                            ></path>
                                            <g>
                                              <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"></path>
                                            </g>
                                          </svg>
                                        </span>
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        type="button"
                                        onClick={this.openModalshare}
                                        className="nn_bttn"
                                      >
                                        <span>
                                          <ShareIcon />
                                        </span>
                                      </button>
                                    </li>
                                    <li className="drop-btn dropdown noresponsivew nn_dropdn">
                                      <button
                                        type="button"
                                        className="btn dropdown-toggle"
                                        data-toggle="dropdown"
                                      >
                                        <span>
                                          <svg
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            className="sc-jTzLTM fznnpf"
                                          >
                                            <path d="M11.785 17.139c1.375 0 2.5 1.125 2.5 2.5s-1.125 2.5-2.5 2.5a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5zm0-2.5a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5s2.5 1.125 2.5 2.5-1.125 2.5-2.5 2.5zm0-7.5a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5s2.5 1.125 2.5 2.5-1.125 2.5-2.5 2.5z"></path>
                                          </svg>
                                        </span>
                                      </button>
                                      <div className="dropdown-menu">
                                        <span
                                          className="dropdown-item"
                                          onClick={this.reporList}
                                          data-toggle="modal"
                                          data-target="#report"
                                        >
                                          Report listing
                                        </span>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div>
                              <Row className="mb-3">
                                <Col sm="6" className="mb-sm-0 mb-3">
                                  <input
                                    type="date"
                                    className="form-control"
                                    min={getCurrentDate()}
                                    onChange={(e) => {
                                      this.setState({ date: e.target.value });
                                    }}
                                  />
                                </Col>
                                <Col sm="6" className="mb-sm-0 mb-3">
                                  <input
                                    type="time"
                                    className="form-control"
                                    onChange={(e) => {
                                      this.setState({ time: e.target.value });
                                    }}
                                  />
                                </Col>
                              </Row>

                              <div className="nn_pro_chatbtn nn_buybtn btn-wrapper mb-3">
                                <button
                                  className="cmn-btn-outline2"
                                  onClick={this.showTable}
                                >
                                  Make Deposit
                                </button>
                              </div>
                              <p>
                                Lock in your dream vendor today! Deposits are
                                final, but ensure you secure your vendor(s).
                                Final payments happen directly with the
                                vendor(s), using their preferred method.
                              </p>
                              {this.state.afterBuymake == true ? (
                                <div className="mt-3 mb-3">
                                  <label>Please Enter Amout </label>
                                  <input
                                    className="form-control"
                                    type="number"
                                    placeholder="Enter Amount"
                                    onChange={(e) => {
                                      this.setState({ price: e.target.value });
                                    }}
                                  />
                                  <button
                                    className="nn-chhting mt-2"
                                    onClick={this.clickonbuynow}
                                  >
                                    Send
                                  </button>
                                </div>
                              ) : (
                                ""
                              )}
                              {this.state.afterBuyNow == true ? (
                                <Elements stripe={stripePromise}>
                                  <CategoryVendorPaymentMode
                                    date={this.state.date}
                                    time={this.state.time}
                                    gigsdata={this.state.gigsData}
                                    amount={this.state.amount}
                                    type="makeanoffer"
                                    setLoader={this.handleLoader}
                                    userid={this.state.userid}
                                  />
                                </Elements>
                              ) : (
                                ""
                              )}
                              {this.state.offerSection && (
                                <div className="mt-3">
                                  <label>Enter Offer Price :</label>
                                  <input
                                    className="form-control"
                                    type="number"
                                    placeholder="Enter Price (e.g. $100)"
                                    onChange={(e) => {
                                      this.setState({
                                        offerPrice: e.target.value,
                                      });
                                    }}
                                    value={this.state.offerPrice}
                                  />
                                  <button
                                    className="nn-chhting mt-2"
                                    onClick={(e) => this.handleSendMessage()}
                                  >
                                    Send
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>

                          <div class="singleFlexitem mb-24 d-block">
                            <div class="recentCaption">
                              <Row>
                                {this.state.vendorId !== null ? (
                                  <Col sm="4">
                                    <div>
                                      <p class="featureCap">
                                        <strong class="subCap">Owner</strong>
                                      </p>
                                      <h5 class="featureTittle">
                                        {this.state.vendorId.vendorname}
                                      </h5>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                                <Col sm="8">
                                  <button
                                    className="nn-chhting"
                                    onClick={(e) => this.openChatNow()}
                                  >
                                    chat Now
                                  </button>
                                </Col>
                              </Row>
                            </div>
                          </div>
                          <div class="singleFlexitem ">
                            <div class="descriptionMid">
                              <h4 class="priceTittle">Description</h4>

                              <p class="pera">
                                {this.state.gigsData.description}{" "}
                              </p>
                            </div>
                          </div>
                        </GridItem>
                      </GridContainer>
                    </div>

                    {this.state.vendorId != null ? (
                      <div className="nn_map_details">
                        <div className="section-tittle ">
                          <h2 className="tittle ">
                            <span class="shape"></span>Vendor Information
                          </h2>
                        </div>
                        <div className="reviewpage mt-4">
                          <div className="reviewpageinner">
                            <Image
                              src={
                                this.state.vendorId.image !== null &&
                                  this.state.vendorId.image !== ""
                                  ? this.state.vendorBaseUrl +
                                  this.state.vendorId.image
                                  : importuser
                              }
                            ></Image>
                            <div>
                              <h4>{this.state.vendorId.vendorname}</h4>
                              {this.state.vendorId.ratings > 0 ? (
                                <div className="d-flex">
                                  {Array.from({ length: 5 }, (_, i) =>
                                    i < this.state.vendorId.ratings ? (
                                      <GradeIcon
                                        key={`colored-${i}`}
                                        style={{ color: "#E99318" }}
                                      />
                                    ) : (
                                      <GradeIcon
                                        key={`gray-${i}`}
                                        style={{ color: "#A4A4A4" }}
                                      />
                                    )
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              <p>{this.state.vendorId.vendorstate}</p>
                            </div>
                          </div>

                          {this.state.vendorId != null ? (
                            <p class="pera">
                              {this.state.vendorId.vendordescription}
                            </p>
                          ) : (
                            ""
                          )}


                          {this.props.currentUser.getCurrentUser !== null && (
                            <a
                              href={"/editprofile2/" + this.state.vendorId._id}
                              className="contact-btn"
                            >
                              Contact Me this
                            </a>
                          )}




                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <DiscardPopup
                      isOpen={this.state.modalIsOpenshare}
                      // onAfterOpen={this.afterOpenModal}
                      //  onRequestClose={this.closeModalshare}
                      style={customStylessocila}
                      contentLabel="Example Modal"
                    >
                      <div className="">
                        <div className="nn_share_ctn">
                          <button
                            type="button"
                            onClick={this.closeModalshare}
                            className="nn_close_btn"
                          >
                            {" "}
                            <CloseIcon className="nn_close_icon" />
                          </button>
                        </div>
                        <div className="ovarsoculaspave">
                          <div className="nn_share_title">
                            <h3>Share this listing</h3>
                          </div>

                          <div className="input-group kPEHgG">
                            <svg
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              className="imgbgclg"
                            >
                              <path
                                d="M8.994 13.713a1.368 1.368 0 1 1 2.19-1.638 3.103 3.103 0 0 0 4.68.335l2.666-2.666a3.103 3.103 0 0 0-.038-4.35C17.296 4.2 15.36 4.18 14.156 5.343l-1.538 1.529a1.368 1.368 0 1 1-1.929-1.94l1.552-1.543a5.839 5.839 0 0 1 8.185.071c2.254 2.254 2.282 5.896.054 8.202l-2.682 2.682a5.839 5.839 0 0 1-8.804-.63zm5.767-3.426a1.368 1.368 0 1 1-2.19 1.638 3.103 3.103 0 0 0-4.68-.335l-2.666 2.666a3.103 3.103 0 0 0 .037 4.35c1.195 1.195 3.13 1.215 4.334.054l1.529-1.529a1.368 1.368 0 1 1 1.934 1.934l-1.546 1.546a5.839 5.839 0 0 1-8.185-.071c-2.254-2.254-2.281-5.896-.054-8.202l2.682-2.682a5.839 5.839 0 0 1 8.805.63z"
                                fill="rgb(158, 158, 158)"
                              ></path>
                            </svg>
                            <input
                              type="text"
                              className="form-control noborser"
                              placeholder="Search this blog"
                              value={shareUrl}
                              maxlength={0}
                            />
                            <div className="input-group-append ">
                              <CopyToClipboard
                                text={shareUrl}
                                onCopy={this.copyData}
                              >
                                <button
                                  onClick={this.copyStatus}
                                  className="btn btn-default copyfunction"
                                  type="button"
                                >
                                  Copy
                                  {/* {this.state.copied
																			? t("Productdetails._Copy")
																			: t("Productdetails._Copy")} */}
                                </button>
                              </CopyToClipboard>
                            </div>
                          </div>

                          <div className="social-link nn_social_link">
                            <FacebookShareButton
                              url={shareUrl}
                              //quote={getProductMount.title}
                              className="nn_share_icon"
                            >
                              <div className="bg-color-media float-left">
                                <div className="soc-icon float-left">
                                  <span>
                                    {" "}
                                    <svg
                                      viewBox="0 0 24 24"
                                      width="24"
                                      height="24"
                                    >
                                      <path d="M12 0C5.373 0 0 5.395 0 12.05c0 5.97 4.326 10.912 9.999 11.87v-9.356H7.104v-3.366h2.895V8.715c0-2.88 1.752-4.45 4.31-4.45 1.226 0 2.28.092 2.585.133v3.01l-1.775.001c-1.391 0-1.66.664-1.66 1.638v2.149h3.32l-.432 3.367H13.46V24C19.397 23.274 24 18.205 24 12.047 24 5.395 18.627 0 12 0z"></path>
                                    </svg>
                                  </span>
                                </div>
                              </div>
                            </FacebookShareButton>
                            <WhatsappShareButton
                              url={shareUrl}
                              className="nn_share_icon"
                            >
                              <div className="bg-color-media whatsapp nn_whatsapp_icon float-left">
                                <div className="soc-icon float-left ">
                                  <svg
                                    viewBox="0 0 48 48"
                                    width="100"
                                    height="100"
                                  >
                                    <path
                                      fill="#fff"
                                      d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
                                    />
                                    <path
                                      fill="rgb(4, 167, 78)"
                                      fill-rule="evenodd"
                                      d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </WhatsappShareButton>
                          </div>
                        </div>
                      </div>
                    </DiscardPopup>
                    {this.props.currentUser.getCurrentUser !== null && (
                      <DiscardPopup
                        isOpen={this.state.reportlistco}
                        contentLabel="Minimal Modal Example"
                        style={customStyles2}
                      >
                        <div className="nn_share_ctn">
                          <button
                            type="button"
                            onClick={this.closereortmodel}
                            className="nn_close_btn"
                          >
                            <CloseIcon className="nn_close_icon" />
                          </button>
                        </div>
                        {this.state.reportingList ? (
                          <div className="botnbtn">
                            <p className="nn_report_title">
                              Are you sure you want to report this listing?
                            </p>

                            <Input
                              defaultValue={this.state.inputValue}
                              className={classes.popsp}
                              fullWidth
                              inputProps={{
                                onChange: (e) => this.updateInputReportValue(e),
                              }}
                            />

                            <div className="brbtn">
                              <button
                                type="button"
                                className="reporlst"
                                onClick={this.saveReportedGigs}
                              >
                                Report Listing
                              </button>
                              <button
                                type="button"
                                className="reporcl"
                                onClick={this.closePopup}
                              >
                                {" "}
                                Cancel
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="text-center thankmsg mt-5">
                            <p className="nn_popup_title1">
                              {/* {t("Productdetails._listingreported")} */}
                            </p>{" "}
                          </div>
                        )}
                      </DiscardPopup>
                    )}
                    {/* <div className="nn_map_details">
                                        <div className="nn_mapsloc"> */}
                    {/* <svg
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          className="sc-jTzLTM fznnpf"
                        >
                          <path d="M12.364 2c2.204 0 4.327.865 5.915 2.463a8.4 8.4 0 0 1 2.448 5.939 8.4 8.4 0 0 1-2.448 5.942c-2.669 2.684-5.094 5.445-5.383 5.561a1.326 1.326 0 0 1-.532.095c-.19 0-.358-.024-.544-.1-.305-.123-2.767-2.937-5.372-5.556-3.264-3.282-3.264-8.6 0-11.88A8.319 8.319 0 0 1 12.364 2zm.091 11.91A3.455 3.455 0 1 0 9 10.455a3.455 3.455 0 0 0 3.455 3.455z"></path>
                        </svg> */}
                    {/* <LocationOnOutlinedIcon />
                                            <span> */}
                    {/* {" "}
                                                        {getProductMount.title} in{" "}
                                                        {formatted_address && ( */}
                    {/* <span>{formatted_address}</span> */}
                    {/* )}{" "} */}
                    {/* </span>
                                        </div> */}
                    {/* <GridContainer>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <div className="nn_maps"> */}

                    {/* {getProductMount && getProductMount.location && getProductMount.location.lat_lon !== null ?
                                                                <StaticGoogleMap size="1400x250" apiKey={this.state.googleApi} language={localStorage && localStorage.getItem("lang")}>
                                                                    <Marker location={{
                                                                        lat: getProductMount.location.lat_lon[0],
                                                                        lng: getProductMount.location.lat_lon[1]
                                                                    }} color="red" label="P" />
                                                                </StaticGoogleMap> : "No location found"
                                                            } */}

                    {/* </div>
                                            </GridItem>


                                        </GridContainer>
                                    </div> */}
                    {/* 
                                            <DiscardPopup
                                                isOpen={this.state.modalIsOpenshare}
                                                
                                                style={customStylessocila}
                                                contentLabel="Example Modal"
                                            >
                                                <div className="">
                                                    <div className="nn_share_ctn">
                                                        <button
                                                            type="button"
                                                            onClick={this.closeModalshare}
                                                            className="nn_close_btn"
                                                        >
                                                            {" "}
                                                            <CloseIcon className="nn_close_icon" />
                                                        </button>
                                                    </div>
                                                    <div className="ovarsoculaspave">
                                                        <div className="nn_share_title">
                                                            <h3> {t("Productdetails._Sharelisting")}</h3>
                                                        </div>

                                                        <div className="input-group kPEHgG">
                                                            <svg
                                                                viewBox="0 0 24 24"
                                                                width="24"
                                                                height="24"
                                                                className="imgbgclg"
                                                            >
                                                                <path
                                                                    d="M8.994 13.713a1.368 1.368 0 1 1 2.19-1.638 3.103 3.103 0 0 0 4.68.335l2.666-2.666a3.103 3.103 0 0 0-.038-4.35C17.296 4.2 15.36 4.18 14.156 5.343l-1.538 1.529a1.368 1.368 0 1 1-1.929-1.94l1.552-1.543a5.839 5.839 0 0 1 8.185.071c2.254 2.254 2.282 5.896.054 8.202l-2.682 2.682a5.839 5.839 0 0 1-8.804-.63zm5.767-3.426a1.368 1.368 0 1 1-2.19 1.638 3.103 3.103 0 0 0-4.68-.335l-2.666 2.666a3.103 3.103 0 0 0 .037 4.35c1.195 1.195 3.13 1.215 4.334.054l1.529-1.529a1.368 1.368 0 1 1 1.934 1.934l-1.546 1.546a5.839 5.839 0 0 1-8.185-.071c-2.254-2.254-2.281-5.896-.054-8.202l2.682-2.682a5.839 5.839 0 0 1 8.805.63z"
                                                                    fill="rgb(158, 158, 158)"
                                                                ></path>
                                                            </svg>
                                                            <input
                                                                type="text"
                                                                className="form-control noborser"
                                                                placeholder="Search this blog"
                                                                value={shareUrl}
                                                                maxlength={0}
                                                            />
                                                            <div className="input-group-append ">
                                                                <CopyToClipboard
                                                                    text={shareUrl}
                                                                    onCopy={this.copyData}
                                                                >
                                                                    <button
                                                                        onClick={this.copyStatus}
                                                                        className="btn btn-default copyfunction"
                                                                        type="button"
                                                                    >
                                                                        {this.state.copied
                                                                            ? t("Productdetails._Copy")
                                                                            : t("Productdetails._Copy")}
                                                                    </button>
                                                                </CopyToClipboard>
                                                            </div>
                                                        </div>

                                                        <div className="social-link nn_social_link">
                                                            <FacebookShareButton
                                                                url={shareUrl}
                                                                className="nn_share_icon"
                                                            >
                                                                <div className="bg-color-media float-left">
                                                                    <div className="soc-icon float-left">
                                                                        <span>
                                                                            {" "}
                                                                            <svg
                                                                                viewBox="0 0 24 24"
                                                                                width="24"
                                                                                height="24"
                                                                            >
                                                                                <path d="M12 0C5.373 0 0 5.395 0 12.05c0 5.97 4.326 10.912 9.999 11.87v-9.356H7.104v-3.366h2.895V8.715c0-2.88 1.752-4.45 4.31-4.45 1.226 0 2.28.092 2.585.133v3.01l-1.775.001c-1.391 0-1.66.664-1.66 1.638v2.149h3.32l-.432 3.367H13.46V24C19.397 23.274 24 18.205 24 12.047 24 5.395 18.627 0 12 0z"></path>
                                                                            </svg>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </FacebookShareButton>
                                                            <WhatsappShareButton
                                                                url={shareUrl}
                                                                className="nn_share_icon"
                                                            >
                                                                <div className="bg-color-media whatsapp nn_whatsapp_icon float-left">
                                                                    <div className="soc-icon float-left ">
                                                                        <svg
                                                                            viewBox="0 0 48 48"
                                                                            width="100"
                                                                            height="100"
                                                                        >
                                                                            <path
                                                                                fill="#fff"
                                                                                d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
                                                                            />
                                                                            <path
                                                                                fill="rgb(4, 167, 78)"
                                                                                fill-rule="evenodd"
                                                                                d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
                                                                                clip-rule="evenodd"
                                                                            />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </WhatsappShareButton>

                                                        </div>
                                                    </div>
                                                </div>
                                            </DiscardPopup> */}
                    {/* <div></div> */}

                    <div className="nn_related_pro">
                      <div className="section-tittle ">
                        <h2 className="tittle ">
                          <span class="shape"></span>Related Services
                        </h2>
                      </div>

                      <div className="product-list nn_product_list">
                        <Slider {...settingsrtl}>
                          {this.state.relatedGigs.map((value, image) => (
                            <a href={"/category-vendor/" + value._id}>
                              <div className="gigs-cat-outer">
                                <div className="nn_homproductctn">
                                  <Card.Img
                                    variant="top"
                                    src={
                                      this.state.relatedgigsimagesArray == []
                                        ? this.state.baseUrl +
                                        this.state.relatedgigsimagesArray[0]
                                          .image
                                        : gigs
                                    }
                                  />
                                </div>
                                <div className="allprodetails card-content">
                                  <div className="titlegigs-cat">
                                    <div className="inner-catgigstitle">
                                      <div className="d-flex align-items-center">
                                        {value.vendorid.image != null ? (
                                          <Card.Img
                                            variant="top"
                                            src={
                                              this.state.vendorBaseUrl +
                                              value.vendorid.image
                                            }
                                            width={15}
                                            height={50}
                                          />
                                        ) : (
                                          <AccountCircleIcon
                                            style={{
                                              fontSize: "30px",
                                              marginRight: "5px",
                                              color: "#556080",
                                            }}
                                          />
                                        )}
                                        <h4>{value.title.slice(0, 20)}</h4>
                                      </div>
                                      <h6> {value.price}</h6>
                                    </div>

                                    {/* <p>{value.description.slice(0,40)}</p> */}
                                  </div>
                                  {value.ratings > 0 ? (
                                    <div className="d-flex">
                                      {Array.from({ length: 5 }, (_, i) =>
                                        i < value.ratings ? (
                                          <GradeIcon
                                            key={`colored-${i}`}
                                            style={{ color: "#E99318" }}
                                          />
                                        ) : (
                                          <GradeIcon
                                            key={`gray-${i}`}
                                            style={{ color: "#A4A4A4" }}
                                          />
                                        )
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </a>
                          ))}
                        </Slider>
                      </div>
                    </div>
                  </Grid>
                </Container>
              </div>
            </ProductDetailsMain>
          </ProviderRefech>
        ) : (
          <Loader>
            <div className="stage" id="loader">
              <div className="dot-bricks"></div>
            </div>
          </Loader>
        )}
        {/* {console.log("catven",this.state.gigsData)} */}

        {this.state.openChat ? (
          <ChatWindowVendor
            giData={this.state.gigsData}
            closemodel={this.closechatmodel}
            userid={this.props.currentUser}
          />
        ) : (
          ""
        )}
        <Footer />
      </>
    );
  }
}

var CategoryVendorexport = compose(
  graphql(POPUP_STATE_UPDATE, { name: "updateLoginPopupStatus" })
)(CategoryVendor);

export default withTranslation("common")(
  withStyles(pagesStyle)(CategoryVendorexport)
);
