import React from "react";
import { compose, graphql, Query } from "react-apollo";

// react component for creating dynamic tables
import ReactTable from "react-table";
import "react-table/react-table.css";
import axios from "axios";


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Delete";

// core components
import Dialogue from "../../../components/Dialog/index.jsx";
import Snackbar from "../../../components/Snackbar/Snackbar.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";

import {GET_ALL_ADMIN_PRODUCTS,USER_PRODUCT_DELETE,  GET_TOGGLE_STATUS, UPDATE_TOGGLE_STATUS} from "../../../queries/index.js";
import {downloadCSV, dateSet, downloadXLSX, getColumnWidth, exportData, onTableViewChange} from "../../../helper.js";

// style component
import { cardTitle } from "../../../assets/jss/material-dashboard-pro-react.jsx";
import vendorconstant from "../../vendorconstant.js";
import { EditAttributes } from "@material-ui/icons";
const { REACT_APP_ADMIN_PATH } = process.env;

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  buttonStyle:{
    float: "right",
    position: "relative",
    marginTop: "20px"
  },
  smallButton: {
    marginRight: "5px",
    backgroundColor: "#00acc1 !important",
    marginTop: "25px"
  }
};


class MembershipList extends React.Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      data: [],
      currentChosen: {},
      canDelete: false,
      open: false,
      preLoadr: false,
      vendorData :[],
      deletevendorid:''
    };
    // this.dataMapper = this.dataMapper.bind(this);
  }

  componentDidMount=()=>{
   this.vendoralldatalist()
  }

  vendoralldatalist=()=>{
    axios
    .get(vendorconstant.API_URL+"admin/getvendordata")
    .then((response) => {
      this.setState({vendorData:response.data.data})
    })
    .catch((error) => {
        console.log(error);
    });
  }

  onTableChange = () => {
      this.setState({
        dataToexport: onTableViewChange(this.reactTable.current)
      });
  }

  handleDelete = (id) =>{
    
    this.setState({deletevendorid:id})

    this.setState({ open: true });
  }
  deleteOnDailog=()=>{
    console.log(this.state.deletevendorid)
  }
  render() {
    const { classes } = this.props;
    let {data, dataToexport, currentChosen,preLoadr,vendorData} = this.state;
    console.log(vendorData)
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
          <CardHeader icon>
                      <Button
                        onClick={() => {
                          this.props.history.push(
                            `${REACT_APP_ADMIN_PATH}/membership`
                          );
                        }}
                        color="success"
                        className={classes.buttonStyle}
                      >
                        Change Membership Price
                      </Button>
                    </CardHeader>
            <CardBody>
            <ReactTable
                data={vendorData}
                minRows={0}
                ref={this.reactTable}
                onPageChange={this.onTableChange}
                onPageSizeChange={this.onTableChange}
                onSortedChange={this.onTableChange}
                onExpandedChange={this.onTableChange}
                onFilteredChange={this.onTableChange}
                onResizedChange={this.onTableChange}
                loading={preLoadr}
                filterable
                columns={[
                  {
                    Header: "Vendor ID",
                    accessor: "Vendor_id",
                    style: { "whiteSpace": "unset",  "lineHeight": "1.42857143" },
              
                  },
                  {
                    Header: "Vendor name",
                    accessor: "vendorname",
                    style: { "whiteSpace": "unset",  "lineHeight": "1.42857143" }
                  },
                  {
                    Header: "Membership",
                    accessor: "Membership",
                    style: { "whiteSpace": "unset",  "lineHeight": "1.42857143" },
                  },
                  
                  // {
                  //   Header: "CreatedAt",
                  //   accessor: "created_at",
                  //   style: { "whiteSpace": "unset", "lineHeight": "1.42857143" },
                  // },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                    Cell: row => (
                      <div>
                        <Button
                          justIcon
                          round
                          simple
                          onClick={() => this.handleDelete(row.original._id)}
                          color="danger"
                          className={classes.deleteButton}
                        >
                          <Close />
                        </Button>                       
                      </div>
                    )
                  }
                ]}
                defaultSorted={[
                  {
                    id: "id",
                    desc: true

                  }
                ]}
                defaultPageSize={10}
                // showPaginationTop={false}
                // showPaginationBottom={true}
                className="-striped -highlight"
              />

            </CardBody>
            <Dialogue open={this.state.open}
            handleClickOpen={() => {
              var self = this;
              this.setState({
                open: false,
                canDelete: true,

              },() => {
                const dataString ={
                  "vendorId": this.state.deletevendorid
                }
                axios
                .post(vendorconstant.API_URL+"admin/deletevendor" , dataString)
                .then((response) => {
                  this.vendoralldatalist()
                })
                .catch((error) => {
                    console.log(error);
                });
                
              });
            }}
            handleClose={() => {
              this.setState({
                open: false
              });
            }}
            />
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

var admins = compose(
  graphql(GET_ALL_ADMIN_PRODUCTS, {
    name: "productsInfo",
    options: () => ({
     variables: {
       filter: {}
     }
   })
 }),
  graphql(USER_PRODUCT_DELETE, {name: "deleteProduct"}),
  graphql(UPDATE_TOGGLE_STATUS, {name: "updateToggle"})
)(MembershipList);
export default withStyles(styles)(admins);
