import React, { useEffect, useRef } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Button from "../../../components/CustomButtons/Button.jsx";
import vendorconstant from "../../vendorconstant";
import axios from "axios";


const PaymentForm = ({id , name}) => {
  const didMountRef = useRef(true)
  const cardElementOptions = {hidePostalCode: true};
  useEffect(() => {
    if (didMountRef.current) {
     
    }
    didMountRef.current = false;
  });
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet, so do nothing.
      return;
    }

    // Get a PaymentMethod object representing the card details
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      // Handle error, display error to the user
      console.log(error.message);
      alert(error.message); 
      return false;
    } else {
      // PaymentMethod ID
      const paymentMethodId = paymentMethod.id;
      console.log('sasas',paymentMethodId);

      // Call your API with the paymentMethodId
     
        const datastring ={
          paymentMethodId:paymentMethodId,
          vendorname : name, 
          vendor_id : id 
      };
      axios
      .post(vendorconstant.API_URL+"payment/membershippayment",datastring)
      .then((response) => {
        if(response.data.status == 'success')
        {
         alert(response.data.message)
         window.location.href = vendorconstant.VENDOR_URL+'login/'+response.data.token;
        }else if(response.data.status == 'error'){
          alert(response.data.message);
          return false;
        }
      })
      .catch((error) => {
          console.log(error.message);
      });
    }
  };

  return (
    <>
      <div>
        <label>
          Card details
          <CardElement  options={cardElementOptions}  />
        </label>
      </div>
      <div className="text-center mt-4">
      <Button
        className="btn-wrapper"
        style={{
          color: "rgb(254, 58, 86)",
          padding: "0",
        }}
        size="sm"
        onClick={handleSubmit}
      >Pay
      </Button>
    </div>
    </>
  );
};

export default PaymentForm;
