import React from "react";
import { compose, graphql } from "react-apollo";

// react component for creating dynamic tables
import ReactTable from "react-table";
import "react-table/react-table.css";
import axios from "axios";

import NavPills from "../../../components/NavPills/NavPills.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Close from "@material-ui/icons/Delete";

// core components
import Dialogue from "../../../components/Dialog/index.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import {
  GET_ALL_ADMIN_PRODUCTS,
  USER_PRODUCT_DELETE,
  GET_TOGGLE_STATUS,
  UPDATE_TOGGLE_STATUS,
} from "../../../queries";
import { onTableViewChange } from "../../../helper.js";

// style component
import { cardTitle } from "../../../assets/jss/material-dashboard-pro-react.jsx";
import vendorconstant from "../../vendorconstant";
import { Edit } from "@material-ui/icons";
const { REACT_APP_ADMIN_PATH } = process.env;

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  buttonStyle: {
    float: "right",
    position: "relative",
    marginTop: "20px",
  },
  smallButton: {
    marginRight: "5px",
    backgroundColor: "#00acc1 !important",
    marginTop: "25px",
  },
};

class VendorCategoryList extends React.Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      data: [],
      currentChosen: {},
      canDelete: false,
      open: false,
      preLoadr: false,
      vendorData: [],
      deletevendorid: "",
      vendorSubCatData : []
    };
    // this.dataMapper = this.dataMapper.bind(this);
  }
  componentDidMount = () => {
    this.vendoralldatalist();
    this.vendorallsubcatlist()
  };
  vendoralldatalist = () => {
    axios
      .get(vendorconstant.API_URL + "admin/getvendorcatdata")
      .then((response) => {
        this.setState({ vendorData: response.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  vendorallsubcatlist = () => {
    axios
      .get(vendorconstant.API_URL + "admin/getvendorsubcatdata")
      .then((response) => {
        this.setState({ vendorSubCatData: response.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onTableChange = () => {
    this.setState({
      dataToexport: onTableViewChange(this.reactTable.current),
    });
  };

  handleDelete = (id) => {
    this.setState({ deletevendorid: id });

    this.setState({ open: true });
  };
  handleEdit = (id) => {
    window.location.href = "/admin/vendor-subcategories-edit?id="+id

  };
  handlecatEdit = (id) => {
    window.location.href = "/admin/vendor-categories-edit?id="+id

  };
  deleteOnDailog = () => {
    console.log(this.state.deletevendorid);
  };

  deletecategory=(id)=>{
    const shouldDelete = window.confirm("Are you sure you want to delete this category?");
    if (shouldDelete) {
    const dataString ={
      "id" : id
    }
    axios
    .post(vendorconstant.API_URL + "admin/deletecategory" , dataString)
    .then((response) => {
      if(response.data.status == "success"){
        window.location.reload()
      }

    })
    .catch((error) => {
      console.log(error);
    });
  }
  }
  deletesubcategory=(id)=>{
    const shouldDelete = window.confirm("Are you sure you want to delete this category?");
    if (shouldDelete) {
    const dataString ={
      "id" : id
    }
    axios
    .post(vendorconstant.API_URL + "admin/deletesubcategory" , dataString)
    .then((response) => {
      if(response.data.status == "success"){
        window.location.reload()
      }

    })
    .catch((error) => {
      console.log(error);
    });
  }
  }
  render() {
    const { classes } = this.props;
    let { data, dataToexport, currentChosen, preLoadr, vendorData, vendorSubCatData} =
      this.state;
    console.log(vendorData);
    return (
      <GridContainer>
        <GridItem xs={12}>
          <NavPills
            color="primary"
            tabs={[
              {
                tabButton: "Category",
                tabContent: (
                  <Card>
                    <CardHeader icon>
                      <Button
                        onClick={() => {
                          this.props.history.push(
                            `${REACT_APP_ADMIN_PATH}/vendor-categories-edit`
                          );
                        }}
                        color="success"
                        className={classes.buttonStyle}
                      >
                        Add Category
                      </Button>
                    </CardHeader>
                    <CardBody>
                      <ReactTable
                        data={vendorData}
                        minRows={0}
                        ref={this.reactTable}
                        onPageChange={this.onTableChange}
                        onPageSizeChange={this.onTableChange}
                        onSortedChange={this.onTableChange}
                        onExpandedChange={this.onTableChange}
                        onFilteredChange={this.onTableChange}
                        onResizedChange={this.onTableChange}
                        loading={preLoadr}
                        filterable
                        columns={[
                          {
                            Header: "ID",
                            accessor: "_id",
                            style: {
                              whiteSpace: "unset",
                              lineHeight: "1.42857143",
                            },
                          },
                          {
                            Header: "Name",
                            accessor: "name",
                            style: {
                              whiteSpace: "unset",
                              lineHeight: "1.42857143",
                            },
                          },
                          // {
                          //   Header: "Description",
                          //   accessor: "description",
                          //   style: {
                          //     whiteSpace: "unset",
                          //     lineHeight: "1.42857143",
                          //   },
                          // },
                          {
                            Header: "Status",
                            accessor: "status",
                            style: {
                              whiteSpace: "unset",
                              lineHeight: "1.42857143",
                            },
                          },
                          {
                            Header: "CreatedAt",
                            accessor: "created_at",
                            style: {
                              whiteSpace: "unset",
                              lineHeight: "1.42857143",
                            },
                          },
                          {
                            Header: "Actions",
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                            Cell: (row) => (
                              <div>
                                <Button
                                  justIcon
                                  round
                                  simple
                                  onClick={() =>
                                    this.deletecategory(row.original._id)
                                  }
                                  color="danger"
                                  className={classes.deleteButton}
                                >
                                  <Close />
                                </Button>
                                <Button
                                  justIcon
                                  round
                                  simple
                                  onClick={() =>
                                    this.handlecatEdit(row.original._id)
                                  }
                                  color="success"
                                  className="edit"
                                >
                                  <Edit />
                                </Button>
                              </div>
                            ),
                          },
                        ]}
                        defaultSorted={[
                          {
                            id: "id",
                            desc: true,
                          },
                        ]}
                        defaultPageSize={10}
                        // showPaginationTop={false}
                        // showPaginationBottom={true}
                        className="-striped -highlight"
                      />
                    </CardBody>
                    <Dialogue
                      open={this.state.open}
                      handleClickOpen={() => {
                        var self = this;
                        this.setState(
                          {
                            open: false,
                            canDelete: true,
                          },
                          () => {
                            const dataString = {
                              vendorId: this.state.deletevendorid,
                            };
                            axios
                              .post(
                                vendorconstant.API_URL + "admin/deletevendor",
                                dataString
                              )
                              .then((response) => {
                                this.vendoralldatalist();
                              })
                              .catch((error) => {
                                console.log(error);
                              });
                          }
                        );
                      }}
                      handleClose={() => {
                        this.setState({
                          open: false,
                        });
                      }}
                    />
                  </Card>
                ),
              },
              {
                tabButton: "Sub Category",
                tabContent: (
                  <Card>
                    <CardHeader icon>
                      <Button
                        onClick={() => {
                          this.props.history.push(
                            `${REACT_APP_ADMIN_PATH}/vendor-subcategories-edit`
                          );
                        }}
                        color="success"
                        className={classes.buttonStyle}
                      >
                        Add Sub Category
                      </Button>
                    </CardHeader>
                    <CardBody>
                      <ReactTable
                        data={vendorSubCatData}
                        minRows={0}
                        ref={this.reactTable}
                        onPageChange={this.onTableChange}
                        onPageSizeChange={this.onTableChange}
                        onSortedChange={this.onTableChange}
                        onExpandedChange={this.onTableChange}
                        onFilteredChange={this.onTableChange}
                        onResizedChange={this.onTableChange}
                        loading={preLoadr}
                        filterable
                        columns={[
                          {
                            Header: "ID",
                            accessor: "_id",
                            style: {
                              whiteSpace: "unset",
                              lineHeight: "1.42857143",
                            },
                          },
                          {
                            Header: "Name",
                            accessor: "name",
                            style: {
                              whiteSpace: "unset",
                              lineHeight: "1.42857143",
                            },
                          },
                          // {
                          //   Header: "Description",
                          //   accessor: "description",
                          //   style: {
                          //     whiteSpace: "unset",
                          //     lineHeight: "1.42857143",
                          //   },
                          // },
                          {
                            Header: "Status",
                            accessor: "status",
                            style: {
                              whiteSpace: "unset",
                              lineHeight: "1.42857143",
                            },
                          },
                          {
                            Header: "CreatedAt",
                            accessor: "created_at",
                            style: {
                              whiteSpace: "unset",
                              lineHeight: "1.42857143",
                            },
                          },
                          {
                            Header: "Actions",
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                            Cell: (row) => (
                              <div>
                                <Button
                                  justIcon
                                  round
                                  simple
                                  onClick={() =>
                                    this.deletesubcategory(row.original._id)
                                  }
                                  color="danger"
                                  className={classes.deleteButton}
                                >
                                  <Close />
                                </Button>
                                <Button
                                  justIcon
                                  round
                                  simple
                                  onClick={() =>
                                    this.handleEdit(row.original._id)
                                  }
                                  color="success"
                                  className="edit"
                                >
                                  <Edit />
                                </Button>
                              
                              
                              </div>
                            ),
                          },
                        ]}
                        defaultSorted={[
                          {
                            id: "id",
                            desc: true,
                          },
                        ]}
                        defaultPageSize={10}
                        // showPaginationTop={false}
                        // showPaginationBottom={true}
                        className="-striped -highlight"
                      />
                    </CardBody>
                    <Dialogue
                      open={this.state.open}
                      handleClickOpen={() => {
                        var self = this;
                        this.setState(
                          {
                            open: false,
                            canDelete: true,
                          },
                          () => {
                            const dataString = {
                              vendorId: this.state.deletevendorid,
                            };
                            axios
                              .post(
                                vendorconstant.API_URL + "admin/deletevendor",
                                dataString
                              )
                              .then((response) => {
                                this.vendoralldatalist();
                              })
                              .catch((error) => {
                                console.log(error);
                              });
                          }
                        );
                      }}
                      handleClose={() => {
                        this.setState({
                          open: false,
                        });
                      }}
                    />
                  </Card>
                ),
              },
              
            ]}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

var admins = compose(
  graphql(GET_ALL_ADMIN_PRODUCTS, {
    name: "productsInfo",
    options: () => ({
      variables: {
        filter: {},
      },
    }),
  }),
  graphql(USER_PRODUCT_DELETE, { name: "deleteProduct" }),
  graphql(UPDATE_TOGGLE_STATUS, { name: "updateToggle" })
)(VendorCategoryList);
export default withStyles(styles)(admins);
