import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios'

export const VendorContext = createContext();

const VendorContextProvider = ({children}) => {
  const [vendorCat, setVendorCat] = useState([]);
  useEffect(() => {
  
    const fetchVendorCategories = async () => {
      
      const categoriesResponse = await axios.get('http:// localhost:4000/vendor');
      
      const categoriesData = await categoriesResponse.json();
      console.log("hello", categoriesData)
      setVendorCat(categoriesData);
    };

    fetchVendorCategories();

  }, []);

  return (
    <VendorContext.Provider value={{ vendorCat}}>
      {children}
    </VendorContext.Provider>
  );
};

export default VendorContextProvider;
