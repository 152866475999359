import { Grid } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import moment from 'moment';
import io from 'socket.io-client';
import vendorconstant from "../../vendorconstant";
import SendIcon from '../../../assets/img/iconsend1.png'
import axios from "axios";

function VendorMessageList({openChat , chatdata , userData , selectedChatData}){

  const [newMessage , setNewMessage] = useState("")
 	useEffect(() => {
		// Replace 'http://localhost:5000' with the URL of your backend server where the Socket.io server is running
		const socket = io("https://vendor.myplo.com/", {
			withCredentials: true, // Include credentials such as cookies in requests (if applicable)
		});
		// Handle new messages received from the server
		socket.on('message', (data) => {
			console.log('New message received:', data);
			setNewMessage((prevMessages) => [...prevMessages, data]);
			// Update the chat interface with the new message
			// You can use state or other methods to display the message in the chat window
		});
		// Clean up the WebSocket connection on component unmount
		return () => {
			socket.disconnect();
		};
	}, []);
      const handleSendMessage = async (msg=null) => {
        if(!msg){
          if(newMessage == ""){
              alert("Pleaae Enter Something");
              return false
          }
      }
        
        const socket = io("https://vendor.myplo.com/", {
          withCredentials: true, 
        });
        socket.emit('sendMessage', { newMessage });
        try {
          const response = await axios.post(vendorconstant.API_URL + 'chat/sendmessage', {
            userid: userData.getCurrentUser.id, 
            gigId: selectedChatData.gigId._id, 
            vendorid: selectedChatData.vendorid._id,
            content: msg?msg:newMessage,
            sentby: 'USER',
          });
          console.log(response.data); 
        } catch (error) {
          console.error('Failed to send message:', error);
        }
        setNewMessage('');
      };
    return(
        <>
    {openChat == true?
                    <div id="nn_chatrt" className="nn_chatrtmain">
                        <div className="nn_chatrtprofile">
                            <div style={{ display: 'flex', width: '95%', alignItems: 'center' }}>
                                <div className="sc-fjdhpX jsvhtV nn_backarrow">
                                    <button type="button" className="Messagesstyles__BackButton">
                                        <svg viewBox="0 0 24 24" width="24" height="24" className="sc-jTzLTM fznnpf">
                                            <path d="M7.513 13.353l3.73 3.863a1.403 1.403 0 0 1-2.016 1.948l-6.082-6.298a1.39 1.39 0 0 1-.393-.998c.006-.359.149-.715.428-.985l6.298-6.082a1.402 1.402 0 0 1 1.948 2.017L7.562 10.55l12.309.215a1.402 1.402 0 1 1-.048 2.804l-12.31-.215z"></path>
                                        </svg>
                                    </button>
                                </div>
                                <div className="nn_pro_img">
                                    <Link to="">
                                        {/* <img src={logoicon} alt="img" style={{ borderRadius: '50%' }} /> */}
                                    </Link>
                                </div>
                                <div className="nn_profile_ctn jss619">
                                    <span className="nn_chatnm">{selectedChatData.vendorid.vendorname}</span>
                                    <div className="nn_urgent_new">
                                        <div className="nn_selling_color"> {selectedChatData.gigId ?selectedChatData.gigId.title:""} </div>
                                    </div>
                                </div>
                           
                            </div>
                          
                        </div>
                        <div className="rtlchatboct nn_chatrtmsgctn">
                            {chatdata.map((value)=>(
                                <>
                            {value.sentby == "VENDOR" ?   
                             <div className="nn_senderpart">
                             <div className="nn_leftpart">
                                 <div className="nortltrans">{value.message}</div>
                                 <span className="dateseen">{moment(value.created_at).fromNow()}</span>
                             </div>
                         </div> 
                          : <div className="nn_receivepart">
                           <div className="nn_leftpart" style={{float:"right"}}>
                               <div className="nortltrans">{value.message}</div>
                               <span className="dateseen">{moment(value.created_at).fromNow()}</span>
                            
                           </div>
                       </div>} 
                            </>))}
                           
                        </div>
                        <div className="nn_chatbt">
                            <div className="nn_chatbtmain">
                                <div className="nn_cus_reschatbtctn nn_chatbtctn">
                                    <div onClick={(e)=>handleSendMessage('You’re welcome!')}>
                                        <button className="nn_chatbtn">You’re welcome!</button>
                                    </div>
                                    <div onClick={(e)=>handleSendMessage('No problem!')}>
                                        <button className="nn_chatbtn">No problem!</button>
                                    </div>
                                    <div onClick={(e)=>handleSendMessage('Thanks!')}>
                                        <button className="nn_chatbtn">Thanks!</button>
                                    </div>
                                    <div onClick={(e)=>handleSendMessage('OK, Thanks!')}>
                                        <button className="nn_chatbtn">OK, Thanks!</button>
                                    </div>
                                
                                </div>
                                <div className="nn_chatinput">
                                    <div style={{ width: '90%' }}>
                                        <textarea type="text" placeholder="Type a message" onChange={(event) => setNewMessage(event.target.value)} ></textarea>
                                    </div>
                                    <div style={{ display: 'flex' }} className="rtlissuesfx nn_chatsendbtn">
                                        <button className="boredrradus" style={{ marginLeft: '0px', paddingLeft: '0px' }} onClick={(e)=>handleSendMessage()}>
                                            <img src={SendIcon} alt="img" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :""
    }
        </>
    )
}
export default VendorMessageList