import React from "react";
import { compose, graphql } from "react-apollo";

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";

// material ui icons
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Snackbar from "../../../components/Snackbar/Snackbar.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardText from "../../../components/Card/CardText.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import ImageUpload from "../../../components/CustomUpload/ImageUpload.jsx";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// style for this view
import validationFormsStyle from "../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import extendedFormsStyle from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

// import defaultImage from "../../assets/img/image_placeholder.jpg";
// import defaultAvatar from "../../assets/img/placeholder.jpg";

import {
  ADD_CATEGORY,
  GET_FILTERS,
  GET_ADMIN_CATEGORIES,
  GET_LANGUAGES,
  UPDATE_TOGGLE_STATUS,
} from "../../../queries";
// import ParentTreeView from "./ParentTreeView"
import Switch from "@material-ui/core/Switch";
import "../../UserPages/css/admin.css";
import vendorconstant from "../../vendorconstant.js";
import axios from "axios";

const { REACT_APP_EDIT_MODE, REACT_APP_ADMIN_PATH } = process.env;

const styles = {
  ...validationFormsStyle,
  ...extendedFormsStyle,
  cardTitle: {
    ...validationFormsStyle.cardTitle,
    color: "white",
  },
};

/**
|----------------------------------------------------------------------------------------
| Selected Language Details - Navbar Button
|----------------------------------------------------------------------------------------
*/
const ListItem = ({
  value,
  onClick,
  removeItemClick,
  classes,
  itemDetails,
}) => {
  let itemName = itemDetails.filter((o) => o.value === value);
  let label = itemName && itemName.length > 0 ? itemName[0].name : "English";
  const bntEff = {
    one: { position: "relative" },
    two: {
      position: "absolute",
      right: 0,
      // backgroundColor: "#218698",

      height: 20,
      width: 20,
      padding: 0,
      top: -5,
      borderRadius: 50,
      fontSize: 9,
      backgroundColor: "#f54a61",
      // borderRadius: 0,
      // borderLeft:"1px solid rgb(221, 221, 221)"
    },
    three: {
      paddingRight: 42,
    },
  };
  return (
    <div style={bntEff.one}>
      <Button
        color="info"
        round
        className="languagechange"
        onClick={onClick}
        style={bntEff.three}
      >
        {label}
      </Button>
      {value !== "en" && (
        <Button onClick={() => removeItemClick(value)} style={bntEff.two}>
          X
        </Button>
      )}
    </div>
  );
};
/**
|----------------------------------------------------------------------------------------
| Map the selected language navbar details
|----------------------------------------------------------------------------------------
*/
const List = ({
  items,
  onItemClick,
  removeItemClick,
  classes,
  itemDetails,
}) => (
  <GridContainer spacing={4}>
    {items.map((item, i) => (
      <ListItem
        key={i}
        value={item}
        onClick={onItemClick}
        removeItemClick={removeItemClick}
        classes={classes}
        itemDetails={itemDetails}
      />
    ))}
  </GridContainer>
);
/**
|----------------------------------------------------------------------------------------
| Starting class component
|----------------------------------------------------------------------------------------
*/
class BasicInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalLanguages: [],
      values: {
        status: "",
        image: "",
        isFeatured: true,
        langCode: "en",
        name: "",
        description: "",
        allFilterId: [],
        allIsMandatory: [],
        instantBuy: false,
      },
      properties: { langCode: "en", name: "", description: "" },
      selectedLangData: [],
      popUpDetails: [],
      errors: {
        submitBtn: false,
      },
      notifyclr: "rose",
      message: "",
      messageOpen: false,
      id: "",
      imageLink: null,
      selectedLanguage: ["en"],
      currentLang: "en",
      addLanguage: false,
      disableLang: false,
      displayForm: false,
      filterTableData: [],
      submitionLoad: false,
      saveAllData: { title: "", description: "", category: "", subcategory: "", price: "", location: "" },
      vendorCatData: [],
      vendorSubCatData: [],
      stateData: [],
      baseUrl: "",
      gigImages: [],
      editGigImage: [],
      catId: "",
      locationValue: "",
      subCatId: ""
      // imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
    };
    this.inputRef = React.createRef();
    this.handleImage = this.handleImage.bind(this);
  }
  /**
|----------------------------------------------------------------------------------------
| Check id exists - if exist then form will act as update form otherwise act as add form
|----------------------------------------------------------------------------------------
*/
  async componentDidMount() {
    await this.getCategoryDetailsData();
    await this.getAllLanguagesData();
    await this.getFilterData();
    const queryString = this.props.location.search;
    const queryParams = new URLSearchParams(queryString);
    const gigid = queryParams.get('gigid');
    console.log(gigid)
    const dataString = {
      gigId: gigid
    }
    axios
      .post(vendorconstant.API_URL + "admin/getgigdatabyid", dataString)
      .then((response) => {
        if (response.data.status == "success") {
          this.setState({ saveAllData: { title: response.data.data.title, description: response.data.data.description, category: response.data.data.category, subcategory: response.data.data.subcategory, price: response.data.data.price, location: response.data.data.location } })
          this.setState({ baseUrl: response.data.baseurl })
          this.setState({ gigImages: response.data.gigImages })
        }


      })
    axios
      .get(vendorconstant.API_URL + "admin/getvendorcatdata")
      .then((response) => {
        if (response.data.status == "success") {
          this.setState({ vendorCatData: response.data.data })
        }


      })
    axios
      .get(vendorconstant.API_URL + "admin/getvendorsubcatdata")
      .then((response) => {
        if (response.data.status == "success") {
          this.setState({ vendorSubCatData: response.data.data })
        }


      })
    axios
      .get(vendorconstant.API_URL + "gigs/getstatesdata")
      .then((response) => {
        if (response.data.status == "success") {
          this.setState({ stateData: response.data.data })
        }


      })

  }

  /**
|----------------------------------------------------------------------------------------
| Get all language details from API
|----------------------------------------------------------------------------------------
*/
  getAllLanguagesData = () => {
    let { getLanguages } = this.props;
    getLanguages.refetch({}).then(({ data }) => {
      if (data) {
        let langData = data && data.getLanguages;
        this.setState({
          totalLanguages: langData,
        });
      }
    });
  };

  //Filter Data for category options
  getFilterData = () => {
    let { getFilters } = this.props;
    getFilters.refetch().then(({ data }) => {
      if (data && data.getFilters) {
        this.setState({
          filterTableData: data.getFilters,
        });
      }
    });
  };
  /**
|----------------------------------------------------------------------------------------
| Get all existing category details for update form - if id exist
|----------------------------------------------------------------------------------------
*/
  getCategoryDetailsData = () => {
    let { categoriesInfo, match } = this.props;
    let id = match.params.id;
    categoriesInfo.refetch().then(({ data }) => {
      if (id && data) {
        var getCategory = data.getAdminCategoryDetails.find((cat) => {
          return Number(cat.id) === Number(id);
        });
        let res = getCategory.language.map((lang) => {
          return lang;
        });

        let englishProperty = res.filter((x) => x.langCode === "en");
        let selectedLanguage = [];
        res &&
          res.map((item) => {
            return selectedLanguage.push(item.langCode);
          });
        this.setState({
          values: { ...englishProperty[0], ...getCategory },
          properties: englishProperty[0],
          selectedLangData: res,
          selectedLanguage,
          imageLink: getCategory.image,
        });
      }
    });
  };

  /**
|----------------------------------------------------------------------------------------
| Image upload action renders
|----------------------------------------------------------------------------------------
*/
  handleImageFile = async (type, file, err) => {
    let { values, errors } = this.state;
    if (type === "image") {
      if (err === "invalid") {
        errors.image =
          "Please upload the images like JPG,JPEG,PNG File Only and Image Must be less than 5mb";
        this.setState({
          image: "",
          errors,
        });
      } else {
        if (file && file.name) {
          values.image = file;
          this.setState({
            values,
            imageLink: null,
          });
        }
        let fieldName = "image";

      }
    }
  };

  // previewImage = () => {
  //   const { values } = this.state;
  //   let imageLink;
  //   if (values.icon) {
  //     imageLink = values.icon;
  //     this.setState({ imageLink: imageLink });
  //   } else {
  //     this.setState({ imageLink: null });
  //   }
  // };

  /**
|----------------------------------------------------------------------------------------
| Action starts after clicking add language button
|----------------------------------------------------------------------------------------
*/
  onClick = () => {
    const {
      currentLang,
      selectedLanguage,
      properties,
      selectedLangData,
      values,
      totalLanguages,
    } = this.state;
    let typeData = "langValid";
    this.validate({ values, typeData });
    if (properties && properties.name && properties.langCode) {
      if (properties.langCode !== currentLang) {
        const nextState = [...selectedLanguage, properties.langCode];
        const prevValues = { ...values };
        const prevProps = { ...properties };
        this.setState({
          selectedLanguage: nextState,
          selectedLangData: [...selectedLangData, properties],
          currentLang: properties.langCode,
          disableLang: false,
          values: { ...values, langCode: "", name: "", description: "" },
          properties: {
            ...properties,
            langCode: "",
            name: "",
            description: "",
          },
        });
        if (totalLanguages.length - 1 === selectedLanguage.length) {
          this.setState({
            displayForm: true,
            values: prevValues,
            properties: prevProps,
          });
        }
      } else if (properties.langCode === currentLang) {
        let selectedProperty = selectedLangData.filter(
          (o) => o.langCode !== currentLang
        );
        this.setState({
          selectedLangData: [...selectedProperty, properties],
          currentLang: currentLang === "en" ? "" : properties.langCode,
          disableLang: false,
          values: { ...values, langCode: "", name: "", description: "" },
          properties: {
            ...properties,
            langCode: "",
            name: "",
            description: "",
          },
        });
      }
      this.setState({
        addLanguage: true,
      });
    } else {
      currentLang === "en"
        ? this.setState({
          addLanguage: false,
        })
        : this.setState({
          addLanguage: true,
          disableLang: true,
        });
    }
  };

  /**
|----------------------------------------------------------------------------------------
| Action starts after clicking individual selected language - Button Label
|----------------------------------------------------------------------------------------
*/
  handleItemClick = (e) => {
    const { totalLanguages, selectedLangData, values } = this.state;
    let checkHtmlContent = e.target.children[0].innerHTML;
    let getLangCode = totalLanguages.filter((o) => o.name === checkHtmlContent);
    let langCode =
      getLangCode && getLangCode.length > 0 ? getLangCode[0].value : "";
    let selectedProperty = selectedLangData.filter(
      (o) => o.langCode === langCode
    );
    let propertyData =
      selectedProperty && selectedProperty.length > 0
        ? selectedProperty[0]
        : { langCode: "", name: "", description: "" };
    let currentValues = { ...values, ...propertyData };
    //let cLang = getLangCode && getLangCode.length > 0 ?
    this.setState({
      currentLang: langCode,
      properties: propertyData,
      addLanguage: false,
      values: { ...values, ...propertyData },
      displayForm: false,
    });
    this.validate({ values: currentValues, typeData: "langValid" });
  };
  /**
|----------------------------------------------------------------------------------------
| Action starts after clicking individual selected language - delete
|----------------------------------------------------------------------------------------
*/
  handleItemRemove = (item) => {
    let { selectedLanguage, selectedLangData, values } = this.state;
    let langData = selectedLangData;
    selectedLanguage = selectedLanguage.filter((x) => x !== item);
    selectedLangData = selectedLangData.filter((x) => x.langCode !== item);
    let propertiesData = langData.filter((x) => x.langCode === "en");
    let propertyValue = propertiesData[0];

    this.setState({
      selectedLanguage,
      selectedLangData,
      properties: propertyValue,
      addLanguage: false,
      currentLang: "en",
      values: { ...values, ...propertyValue },
      displayForm: false,
    });
  };

  /**
|----------------------------------------------------------------------------------------
| Update all existing room type details for update form after clicking update - if id exist
|----------------------------------------------------------------------------------------
*/
  updateCategoryData = async (id) => {
    let { values, selectedLangData, properties, imageLink, submitionLoad } =
      this.state;
    let finalArray = [];
    this.validate({ values });
    if (values.name && values.name.trim() !== "" && values.langCode) {
      if (selectedLangData && selectedLangData.length > 0) {
        let foundIndex = selectedLangData.findIndex(
          (x) => x.langCode === properties.langCode
        );
        if (foundIndex >= 0) {
          selectedLangData[foundIndex] = properties;
        } else {
          selectedLangData.push(properties);
        }
      } else {
        selectedLangData.push(properties);
      }
    }
    const enIndex = selectedLangData.findIndex(
      (lang) => lang.langCode === "en"
    );
    selectedLangData.push(...selectedLangData.splice(0, enIndex));

    if (selectedLangData && selectedLangData.length > 0) {
      selectedLangData.map((item) => {
        return delete item.__typename;
      });
    }

    // Remove duplicate objects in array

    var uniqueArray =
      selectedLangData &&
      selectedLangData.length > 0 &&
      selectedLangData.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });

    uniqueArray &&
      uniqueArray.length > 0 &&
      uniqueArray.map((item, index) => {
        return (finalArray[index] = {
          langCode: item.langCode,
          name: item.name.trim(),
          description: item.description ? item.description.trim() : "",
        });
      });

    let variables;
    if (imageLink) {
      if (
        (values.fields.length === values.allFilterId.length &&
          values.fields.length > 0) ||
        values.productExisted === true
      ) {
        variables = {
          id: Number(id),
          data: {
            status: values.status,
            isFeatured: values.isFeatured,
            language: finalArray,
            instantBuy: values.instantBuy,
          },
        };
      } else {
        variables = {
          id: Number(id),
          data: {
            status: values.status,
            isFeatured: values.isFeatured,
            language: finalArray,
            allFilterId: values.allFilterId,
            allIsMandatory: values.allIsMandatory,
            instantBuy: values.instantBuy,
          },
        };
      }
    } else {
      if (
        (values.fields.length === values.allFilterId.length &&
          values.fields.length > 0) ||
        values.productExisted === true
      ) {
        variables = {
          id: Number(id),
          data: {
            status: values.status,
            isFeatured: values.isFeatured,
            language: finalArray,
            image: values.image,
            instantBuy: values.instantBuy,
          },
        };
      } else {
        variables = {
          id: Number(id),
          data: {
            status: values.status,
            isFeatured: values.isFeatured,
            language: finalArray,
            image: values.image,
            instantBuy: values.instantBuy,
            allFilterId: values.allFilterId,
            allIsMandatory: values.allIsMandatory,
          },
        };
      }
    }
    if (values.status && values.image && values.name && values.langCode) {
      if (REACT_APP_EDIT_MODE !== "prod" && !submitionLoad) {
        this.setState({ submitionLoad: true });
        if (
          (values.productExisted === true &&
            values.fields.length === values.allFilterId.length) ||
          values.productExisted === false
        ) {
          this.props
            .updateCategory({
              variables,
            })
            .then(async ({ data }) => {
              if (data.updateCategory) {
                this.props.updateToggle({
                  variables: {
                    toggleStatus: true,
                    message: "Category Updated Successfully",
                  },
                });
                this.setState({ submitionLoad: false });
                this.props.history.push(`${REACT_APP_ADMIN_PATH}/categories`);
              }
            })
            .catch((error) => {
              this.setState({
                popUpDetails: error.graphQLErrors.map((x) => x.message),
                submitionLoad: false,
              });
            });
        } else {
          let error = ["Can't Edit Filters Fields"];
          this.setState({
            popUpDetails: error,
            submitionLoad: false,
          });
        }
      } else {
        let error = ["Add/Edit Restricted for Live"];
        this.setState({
          popUpDetails: error,
        });
      }
    }
  };
  /**
|----------------------------------------------------------------------------------------
| Add all room type details for Add new form after clicking submit
|----------------------------------------------------------------------------------------
*/

  addCategoryData = async () => {
    let { values, selectedLangData, properties, submitionLoad } = this.state;

    let finalArray = [];
    if (values.name && values.name.trim() !== "" && values.langCode) {
      if (selectedLangData && selectedLangData.length > 0) {
        let foundIndex = selectedLangData.findIndex(
          (x) => x.langCode === properties.langCode
        );
        if (foundIndex >= 0) {
          selectedLangData[foundIndex] = properties;
        } else {
          selectedLangData.push(properties);
        }
      } else {
        selectedLangData.push(properties);
      }

      // Remove duplicate objects in array
      let uniqueArray = selectedLangData.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      uniqueArray &&
        uniqueArray.length > 0 &&
        uniqueArray.map((item, index) => {
          return (finalArray[index] = {
            langCode: item.langCode,
            name: item.name.trim(),
            description: item.description ? item.description.trim() : "",
          });
        });
    }

    let variables = {
      data: {
        status: values.status,
        isFeatured: values.isFeatured,
        image: values.image,
        language: finalArray,
        allFilterId: values.allFilterId,
        allIsMandatory: values.allIsMandatory,
        instantBuy: values.instantBuy,
      },
    };
    if (values.status && values.image && values.name && values.langCode) {
      if (REACT_APP_EDIT_MODE !== "prod" && !submitionLoad) {
        this.setState({ submitionLoad: true });
        this.props
          .updateCategory({
            variables,
          })
          .then(async ({ data }) => {
            if (data.updateCategory) {
              this.props.updateToggle({
                variables: {
                  toggleStatus: true,
                  message: "Category Added Successfully",
                },
              });
              this.setState({ submitionLoad: false });
              this.props.history.push(`${REACT_APP_ADMIN_PATH}/categories`);
            }
          })
          .catch((error) => {
            this.setState({
              popUpDetails: error.graphQLErrors.map((x) => x.message),
              submitionLoad: false,
            });
          });
      } else {
        let error = ["Add/Edit Restricted for Live"];
        this.setState({
          popUpDetails: error,
        });
      }
    }
  };

  /**
|----------------------------------------------------------------------------------------
| Works after chaning individual fields
|----------------------------------------------------------------------------------------
*/
  // handleChange = (event) => {
  //   event.persist();
  //   const { values } = this.state;
  //   let statValues = values;
  //   let name = event.target.name;
  //   statValues[name] = event.target.value;
  //   if (
  //     name === "langCode" ||
  //     name === "description" ||
  //     name === "name" ||
  //     name === "isFeatured"
  //   ) {
  //     let propValues = {};
  //     propValues = {
  //       ...this.state.properties,
  //       [name]: event.target.value,
  //     };
  //     this.setState({
  //       properties: propValues,
  //     });
  //   }
  //   this.validate({ values: statValues, fieldName: name });
  //   this.setState({
  //     values: statValues,
  //   });
  // };

  /**
|----------------------------------------------------------------------------------------
| Works after clicking submit/update button
|----------------------------------------------------------------------------------------
*/
  handleFormSubmit = (e) => {
    e.preventDefault();
    let id = this.props.match.params.id;
    if (id) {
      this.updateCategoryData(id);
    } else {
      this.addCategoryData();
    }
  };

  /**
|----------------------------------------------------------------------------------------
| Close the toastr message
|----------------------------------------------------------------------------------------
*/
  handleMessageClose = () => {
    this.setState({ messageOpen: false });
  };

  /**
|----------------------------------------------------------------------------------------
| Cancel button trigger to view page
|----------------------------------------------------------------------------------------
*/
  cancelButtonClick = () => {
    this.props.history.push(`${REACT_APP_ADMIN_PATH}/categories`);
  };

  /**
  |--------------------------------------------------
  | Validate each field
  |--------------------------------------------------
  */


  handleCheck = (index) => {
    let { match } = this.props;
    let {
      values: { allFilterId },
    } = this.state;
    const id = match.params.id;
    const getValue =
      allFilterId &&
        allFilterId.some((selected_index) => index === selected_index)
        ? allFilterId.filter((i) => i !== index)
        : allFilterId && allFilterId.concat([index]);
    this.setState({
      values: {
        ...this.state.values,
        allFilterId: getValue,
      },
    });
    if (id) {
      this.setState({
        values: {
          ...this.state.values,
          allFilterId: getValue,
        },
        editData: Object.assign(
          {},
          this.state.editData,
          id && { values: { allFilterId: getValue } }
        ),
      });
    }
  };
  saveEditGigData = () => {
    console.log("image", this.state.editGigImage.name)
    if (this.state.saveAllData.title == "") {
      alert("Title is Required")
      return false
    }
    if (this.state.saveAllData.description == "") {
      alert("Description is Required")
      return false
    }
    if (this.state.saveAllData.location == "") {
      alert("Location is Required")
      return false
    }
    if (this.state.saveAllData.category == "") {
      alert("Category is Required")
      return false
    }
    if (this.state.saveAllData.subcategory == "") {
      alert("SubCategory is Required")
      return false
    }
    if (this.state.saveAllData.price == "") {
      alert("Price is Required")
      return false
    }

    const formData = new FormData();
    const queryString = this.props.location.search;
    const queryParams = new URLSearchParams(queryString);
    const gigid = queryParams.get('gigid');
    formData.append('id', gigid);
    formData.append('title', this.state.saveAllData.title);
    formData.append('description', this.state.saveAllData.description);
    formData.append('location', this.state.locationValue ? this.state.locationValue : this.state.saveAllData.location);
    formData.append('category', this.state.catId ? this.state.catId : this.state.saveAllData.category);
    formData.append('subcategory', this.state.saveAllData.subcategory);
    formData.append('price', this.state.saveAllData.price);
    for (let i = 0; i < this.state.editGigImage.length; i++) {
      formData.append("image[]", this.state.editGigImage[i]);
    }

    axios
      .post(vendorconstant.API_URL + "admin/editgigdata", formData)
      .then((response) => {
        console.log(response)
        if (response.data.status == "success") {
          window.location.href = "/admin/gigs"
        }
      })
  }

  getsubcatbycat = (parentcat) => {
    console.log(parentcat)
    this.setState({ catId: parentcat })
    const dataString = {
      parentcat: parentcat
    }
    axios
      .post(vendorconstant.API_URL + "admin/getvendorsubcatdata", dataString)
      .then((response) => {
        if (response.data.status == "success") {
          this.setState({ vendorSubCatData: response.data.data })
        }


      })
  }
  handleClick = (inputRef) => {
  console.log(inputRef)
    if (inputRef.current) {
      inputRef.current.click();


  }
  }


  handleChange = (event) => {
    console.log()
    const key = event.target.name;
    const value = event.target.value;
    if (key == "location") {
      this.setState({ locationValue: value })
    }
    else if (key == "subcategory") {
      this.setState({ subCatId: value })
    }
    else {
      this.setState({ saveAllData: { ...this.state.saveAllData, [key]: value } })
    }

  };

  handleImage = (e) => {
    if (e.target.files) {
      // Access the files property if it exists
      const files = [...e.target.files];
      
      this.setState((prevState) => ({
        editGigImage: [...prevState.editGigImage, ...files],
      }));
    }
    console.log(this.state.editGigImage)

  }
  
  handleSelectMandatory = (index) => {
    let { match } = this.props;
    let {
      values: { allIsMandatory },
    } = this.state;
    const id = match.params.id;
    const mandatoryValue =
      allIsMandatory &&
        allIsMandatory.some((selected_index) => index === selected_index)
        ? allIsMandatory.filter((i) => i !== index)
        : allIsMandatory && allIsMandatory.concat([index]);
    this.setState({
      values: {
        ...this.state.values,
        allIsMandatory: mandatoryValue,
      },
    });
    if (id) {
      this.setState({
        values: {
          ...this.state.values,
          allIsMandatory: mandatoryValue,
        },
        editData: Object.assign(
          {},
          this.state.editData,
          id && { values: { allIsMandatory: mandatoryValue } }
        ),
      });
    }
  };

   handleDeleteImage = (index , gigimageid) => {
   
    if (this.inputRef.current) {
      this.inputRef.current.value = '';
    }
   
    const queryString = this.props.location.search;
    const queryParams = new URLSearchParams(queryString);
    const gigid = queryParams.get('gigid');
    const dataString = {
      
        id : gigid,
        imageIdToDelete : gigimageid
    }
    axios
      .post(vendorconstant.API_URL + "gigs/deletegigimage", dataString)
      .then((response) => {
        if (response.data.message == "Image deleted successfully") {
          const updatedEditGigsImage = [...this.state.gigImages];
          updatedEditGigsImage.splice(index, 1);
          this.setState({gigImages : updatedEditGigsImage})
        }


      })

 
};

 handleDeleteNewImage = (index) => {
    if (this.inputRef.current) {
      this.inputRef.current.value = ''; 
  
    }
  

    const updatedEditGigsImage = [...this.state.editGigImage];
    updatedEditGigsImage.splice(index, 1);
    this.setState({editGigImage : updatedEditGigsImage})
};

  /**
 |----------------------------------------------------------------------------------------
 | Change function for instant buy option
 |----------------------------------------------------------------------------------------
 */
  change = (event) => {
    var isTrueSet;
    isTrueSet = event.target.value === "true";
    this.setState({
      values: {
        ...this.state.values,
        instantBuy: !isTrueSet,
      },
    });
  };

  /**
|----------------------------------------------------------------------------------------
| Render starts for the page
|----------------------------------------------------------------------------------------
*/
  render() {
    const {
      values,
      errors,
      popUpDetails,
      properties,
      disableLang,
      selectedLanguage,
      selectedLangData,
      totalLanguages,
      addLanguage,
      displayForm,
      filterTableData,
      submitionLoad,
    } = this.state;
    const { classes, match, addButtonProps, avatar } = this.props;
    const id = match.params.id;

    let filteredLang = totalLanguages.filter(
      (o) => !selectedLanguage.includes(o.value)
    );
    return (
      <GridContainer justify="center">
        <Snackbar
          place="tc"
          color={this.state.notifyclr}
          message={this.state.message}
          open={this.state.messageOpen}
          closeNotification={this.handleMessageClose}
          close
        />
        <GridItem item xs={12} sm={12}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>
                  {id ? "Edit Services" : "Add Services"}
                </h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    Title<span className="validatcolor">*</span>
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <CustomInput
                    id="title"
                    formControlProps={{ fullWidth: true }}
                    // error={titleError}
                    // helperText={titleError}
                    inputProps={{
                      name: "title",
                      placeholder: "Type Here",
                      onChange: this.handleChange,
                      value: this.state.saveAllData.title,
                      autoComplete: "off",
                    }}
                    name="title"
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    Description
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <TextField
                    id="description"
                    multiline
                    color="secondary"
                    className={classes.textField + " textArea"}
                    style={{ paddingTop: "27px" }}
                    formControlProps={{ fullWidth: true }}
                    error={errors.description ? true : false}
                    helperText={errors.description}
                    inputProps={{
                      name: "description",
                      onChange: this.handleChange,
                      value: this.state.saveAllData.description,
                      maxLength: 1500,
                      autoComplete: "off",
                    }}
                    name="description"
                  />
                  <div>
                    {properties.description != ""
                      ? properties.description.length
                      : 0}
                    /1500
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    Image<span className="validatcolor">*</span>
                  </FormLabel>
                </GridItem>

                <GridItem xs={12} sm={10}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl + " " + "langupload"}
                  >
                    <div className="fileinput text-center">
                      <input style={{ display: 'none' }} type="file" onChange={this.handleImage} accept=".png, .jpg, .jpeg" multiple ref={this.inputRef} id="uploadedImage"/>
                      {this.state.gigImages.length > 0 || this.state.gigImages.length > 0  ?
                      <>
                     {this.state.gigImages.map((value , index) => (
                        <div className={"thumbnail cls_round" + (avatar ? " img-circle" : "")}>
                         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="16"  onClick={() => this.handleDeleteImage(index, value._id)}><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" /></svg>
                          <img src={this.state.baseUrl + value.image} alt="..." height={75} width={100} id="uploadedImage"  />
                        </div>))}
                         
                            
                        
                        
                       {this.state.editGigImage.map((value , index) => (
                        <div className={"thumbnail cls_round" + (avatar ? " img-circle" : "")}>
                           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="16"  onClick={() => this.handleDeleteNewImage(index)}><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" /></svg>
                          <img src={URL.createObjectURL(value)} alt="..." height={75} width={100} id="uploadedImage"  />
                        </div>))}
                        
                        </>
                        :""}
                        
                      <div>
                      <Button {...addButtonProps} onClick={() => this.handleClick(this.inputRef)}>
                               Choose file
                            </Button>

                      </div>
                    </div>
                    <FormHelperText error={errors.image ? true : false}>
                      {errors.image}
                    </FormHelperText>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    <FormLabel className={classes.labelHorizontal}>
                      Category<span className="validatcolor">*</span>
                    </FormLabel>
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="status"
                      className={classes.selectLabel}>
                      Category
                    </InputLabel>
                    <Select
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                      value={this.state.catId ? this.state.catId : this.state.saveAllData.category}
                      name="category"
                      id="category"
                      onChange={(event) => this.getsubcatbycat(event.target.value)}>
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}>
                        Choose Category
                      </MenuItem>
                      {this.state.vendorCatData.map((value, index) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          key={index}
                          value={value._id}>
                          {value.name}
                        </MenuItem>))}
                    </Select>
                    <FormHelperText error={errors.status ? true : false}>
                      {errors.status}
                    </FormHelperText>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    <FormLabel className={classes.labelHorizontal}>
                      Sub Category<span className="validatcolor">*</span>
                    </FormLabel>
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}>
                    <InputLabel
                      className={classes.selectLabel}>
                      Sub Category
                    </InputLabel>
                    <Select
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                      value={this.state.subCatId ? this.state.subCatId : this.state.saveAllData.subcategory}
                      name="subcategory"
                      onChange={(event) => this.handleChange(event)}>
                      <MenuItem
                        disabled
                        classes={{ root: classes.selectMenuItem }}
                      >
                        Choose Sub Category
                      </MenuItem>
                      {this.state.vendorSubCatData.map((value) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={value._id}
                        >
                          {" "}
                          {value.name}
                        </MenuItem>))}
                    </Select>
                    <FormHelperText error={errors.status ? true : false}>
                      {errors.status}
                    </FormHelperText>
                  </FormControl>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    Price<span className="validatcolor">*</span>
                  </FormLabel>
                </GridItem>

                <GridItem xs={12} sm={10}>
                  <CustomInput
                    id="price"
                    formControlProps={{ fullWidth: true }}
                    // error={titleError}
                    // helperText={titleError}
                    inputProps={{
                      placeholder: "Type Here",
                      onChange: this.handleChange,
                      value: this.state.saveAllData.price,
                    }}
                    name="price"
                  />
                </GridItem>
              </GridContainer>
              <GridContainer className="mb-4">
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    <FormLabel className={classes.labelHorizontal}>
                      Location <span className="validatcolor">*</span>
                    </FormLabel>
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <InputLabel
                      className={classes.selectLabel}
                      value={this.state.saveAllData.location}
                    >
                      Location
                    </InputLabel>
                    <Select
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                      value={this.state.locationValue ? this.state.locationValue : this.state.saveAllData.location}
                      name="location"
                      onChange={(event) => this.handleChange(event)}>
                      <MenuItem
                        disabled
                        classes={{ root: classes.selectMenuItem }}>
                        Location
                      </MenuItem>
                      {this.state.stateData.map((value) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={value.name}
                        >
                          {" "}
                          {value.name}
                        </MenuItem>))}
                    </Select>
                    <FormHelperText error={errors.status ? true : false}>
                      {errors.status}
                    </FormHelperText>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <Button
                color="rose"
                type="submit"
                className={classes.registerButton}
                disabled={errors.submitBtn || submitionLoad}
                onClick={this.saveEditGigData}>
                {id ? "Update" : "Submit"}
              </Button>
              <Button
                className={classes.roomTypeCancelBtn}
                onClick={() => this.cancelButtonClick()}
              >
                Cancel
              </Button>
            </CardBody>
            {popUpDetails.length ? (
              <Snackbar
                place="tc"
                color="rose"
                message={popUpDetails[0]}
                open={!!popUpDetails.length}
                closeNotification={() => {
                  this.setState({ popUpDetails: [] });
                }}
                close
              />
            ) : (
              ""
            )}
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
var categoryAction = compose(
  graphql(GET_ADMIN_CATEGORIES, { name: "categoriesInfo" }),
  graphql(GET_FILTERS, { name: "getFilters" }),
  graphql(ADD_CATEGORY, { name: "updateCategory" }),
  graphql(UPDATE_TOGGLE_STATUS, { name: "updateToggle" }),
  graphql(GET_LANGUAGES, { name: "getLanguages" })
)(BasicInfo);

export default withStyles(styles)(categoryAction);


