import React from "react";
import { Mutation, graphql, compose } from "react-apollo";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
// material ui icons
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Snackbar from "../../../components/Snackbar/Snackbar.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardText from "../../../components/Card/CardText.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CardFooter from "../../../components/Card/CardFooter.jsx";
// style for this view
import validationFormsStyle from "../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import extendedFormsStyle from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import {
  EDIT_USER,
  GET_USER,
  SIGNUP_USER,
  SIGNUP_VENDOR,
  UPDATE_TOGGLE_STATUS,
} from "../../../queries/index.js";
import axios from "axios";
import vendorconstant from "../../vendorconstant.js";
const { REACT_APP_EDIT_MODE, REACT_APP_ADMIN_PATH } = process.env;

const styles = {
  ...validationFormsStyle,
  ...extendedFormsStyle,
  cardTitle: {
    ...validationFormsStyle.cardTitle,
    color: "white",
  },
};

const initialState = {
  userName: "",
  email: "",
  password: "",
  status: "",
  vendorname: "",
  vendoremail: "",
  companyName: "",
  vendorpassword: "",
  editData: {},
  errors: {},
  popUpDetails: [],
  isButtonDisabled: true,
  submitionLoad: false,
};

class VendorEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      getVendorData: {vendorname:"",vendormobile:"",vendoraddress:"",vendoremail:"",vendorstate:"",vendorcountry:"",vendordescription:"",status:""},
      errorMessage : ""
    };
    this.vendorHandleSubmit = this.vendorHandleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this)
  }
  componentDidMount(){
    console.log(this.state.getVendorData.vendorname)
    const datastring = {
      vendor_id: this.props.match.params.id
  };
    axios
    .post(vendorconstant.API_URL+"admin/getvendordatabyid",datastring)
    .then((response) => {
      this.setState({getVendorData:response.data.data})
    })
    .catch((error) => {
        console.log(error);
    });
  }

  componentWillMount() {
    let { userInfo, match } = this.props;
    let id = match.params.id;
    // if (id) {
    //   userInfo.refetch({ id: Number(id) });
    // }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.userInfo && nextProps.userInfo.getUserDetails) {
      let { email, userName, password, status } =
        nextProps.userInfo.getUserDetails.foundUser;
      var b = "***";
      var c = email.split("@");
      var a = c[0].slice(0, -3);
      var d = a.concat(b).concat("@").concat(c[1]);
      this.setState({
        email: REACT_APP_EDIT_MODE === "prod" ? d : email,
        userName,
        password,
        status,
      });
    }
  }

  clearState() {
    this.setState({ ...initialState });
  }

  validateInput() {
    let { match } = this.props;
    let id = match.params.id;
    var self = this;
    let required = ["vendorname", "email", "password", "status"];
    let error = {},
      flag = false;
    required.forEach((data) => {
      if (
        id &&
        data === "password" &&
        self.state.editData.password === undefined
      ) {
        error[data] = "";
      } else if (
        data === "password" &&
        id &&
        (self.state.editData.password === "" ||
          self.state.editData.password.trim() === "")
      ) {
        error[data] = `The ${data} field is required.`;
      } else if (!self.state[data] && !self.state[data].length) {
        error[data] = `The ${data} field is required.`;
      } else if (
        self.state[data] &&
        data === "password" &&
        self.state[data].length < 4
      ) {
        error[data] = "Password must have minimum 4 letters.";
      } else {
        error[data] = "";
      }
      if (required === "email" && !!this.state.email) {
        //eslint-disable-next-line
        var emailRex =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailRex.test(this.state.email)) {
          error.email = "The Email must be a valid email address.";
        } else {
          error.email = "";
        }
      }
    });
    this.setState({
      errors: error,
    });
    flag = Object.keys(error).find((obj) => {
      if (error[obj]) {
        return true;
      }
    });
    if (id) {
      if (Object.keys(this.state.editData).length) {
        return flag;
      } else {
        return true;
      }
    }
    return flag;
  }
  handleSubmitvendor(event, userAction) {
    const { submitionLoad } = this.state;
    let { match } = this.props;
    let id = match.params.id;
    event.preventDefault();
    if (!this.validateInput()) {
      if (REACT_APP_EDIT_MODE !== "prod" && !submitionLoad) {
        this.setState({
          isButtonDisabled: true,
          submitionLoad: true,
        });
        userAction()
          .then(async ({ data }) => {
            if (data.editProfile || data.signup.result) {
              this.props.updateToggle({
                variables: {
                  toggleStatus: true,
                  message: id
                    ? "User Updated Successfully"
                    : "User Added Successfully",
                },
              });
              this.setState({ submitionLoad: false });
              this.props.history.push(`${REACT_APP_ADMIN_PATH}/users`);
            }
          })
          .catch((error) => {
            this.setState({
              popUpDetails: error.graphQLErrors.map((x) => x.message),
              submitionLoad: false,
            });
          });
      } else {
        let error = ["Add/Edit Restricted for Live"];
        this.setState({
          popUpDetails: error,
        });
      }
    }
  }
  change(id, event, stateName) {
    this.setState({
      isButtonDisabled: false,
    });
    this.setState({ [stateName]: event.target.value });
    if (id) {
      this.setState({
        editData: Object.assign({}, { id: Number(id) }, this.state.editData, {
          [stateName]: event.target.value,
        }),
      });
    }
  }
  cancelButtonClick = () => {
    this.props.history.push(`${REACT_APP_ADMIN_PATH}/users`);
  };
  handleChange = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    this.setState({getVendorData : {...this.state.getVendorData, [key]: value} })
    console.log(this.state.getVendorData.vendorname)
  };
  vendorHandleSubmit=()=>{
    console.log(this.state.getVendorData.vendoraddress )
    if(this.state.getVendorData.vendorname == "" || this.state.getVendorData.vendorname == undefined){
    alert( "User Name is required")
       return false
    }
    if(this.state.getVendorData.vendormobile == "" || this.state.getVendorData.vendormobile == undefined){
     alert( "Phone number is required")
       return false
    }
    if(this.state.getVendorData.vendoraddress == "" || this.state.getVendorData.vendoraddress == undefined){
     alert( "Location is required")
       return false
    }
    if(this.state.getVendorData.vendoremail == "" || this.state.getVendorData.vendoremail == undefined){
     alert("Email is required")
       return false
    }
    if(this.state.getVendorData.vendorstate == "" || this.state.getVendorData.vendorstate  == undefined){
     alert("State is required")
       return false
    }
    if(this.state.getVendorData.vendorcountry == "" || this.state.getVendorData.vendorcountry == undefined){
      alert( "Country is required")
       return false
    }
    if(this.state.getVendorData.vendordescription == "" || this.state.getVendorData.vendordescription == undefined){
     alert("Description is required")
       return false
    }
    if(this.state.getVendorData.status == "" || this.state.getVendorData.status == undefined){
     alert("status is required")
       return false
    }
  const dataString ={
    id :  this.props.match.params.id , 
    userName : this.state.getVendorData.vendorname,
    phonenumber : this.state.getVendorData.vendormobile,
    location : this.state.getVendorData.vendoraddress,
    email : this.state.getVendorData.vendoremail , 
    state : this.state.getVendorData.vendorstate , 
    country : this.state.getVendorData.vendorcountry , 
    description : this.state.getVendorData.vendordescription,
    status : this.state.getVendorData.status
  }
  axios
  .post(vendorconstant.API_URL+"admin/editvendordata",dataString)
  .then((response) => {
    if(response.data.status == "success"){
      console.log("hudfh")
      window.location.href ="/admin/vendor"  
    }
  })
  .catch((error) => {
      console.log(error);
  });
  }
  render() {
    const { classes, match } = this.props;
    const id = match.params.id;
    const {
      email,
      userName,
      password,
      status,
      editData,
      errors,
      popUpDetails,
      submitionLoad,
      vendorname,
      vendoremail,
      companyName,
      vendorpassword,
    } = this.state;
    editData.type = "Admin";
    const variables = id
      ? editData
      : {
          email: email.trim(),
          password: password.trim(),
          userName: userName.trim(),
          status,
          type: "Admin",
        };
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Mutation
            mutation={id ? EDIT_USER : SIGNUP_USER}
            variables={variables}
          >
            {(userAction, { data, loading, error }) => {
              return (
                <form
                 
                >
                  <Card>
                    <CardHeader color="rose" text>
                      <CardText color="rose">
                        <h4 className={classes.cardTitle}>Edit Vendor</h4>
                      </CardText>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={2}>
                          <FormLabel className={classes.labelHorizontal}>
                            User Name<span className="validatcolor">*</span>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={10}>
                       
                          <CustomInput
                            id="userName"
                            // error={!!errors.vendorname}
                            // success={!!errors.vendorname}
                            // helpText={errors.vendorname}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            name="vendorname"
                            inputProps={{
                              onChange: this.handleChange,
                                value: this.state.vendorname ||(this.state.getVendorData.vendorname),
                                autoComplete: "off"
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={2}>
                          <FormLabel className={classes.labelHorizontal}>
                           Phone Number<span className="validatcolor">*</span>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={10}>
                          <CustomInput
                          type="number"
                            id="phonenumber"
                            error={!!errors.phonenumber}
                            success={!!errors.phonenumber}
                            helpText={errors.phonenumber}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            name = "vendormobile"
                            inputProps={{
                              onChange: (event) =>
                              this.handleChange(event),
                              value: this.state.getVendorData.vendormobile
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={2}>
                          <FormLabel className={classes.labelHorizontal}>
                           Location<span className="validatcolor">*</span>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={10}>
                          <CustomInput
                            id="location"
                            error={!!errors.location}
                            success={!!errors.location}
                            helpText={errors.location}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            name = "vendoraddress"
                            inputProps={{
                              onChange: (event) =>
                              this.handleChange(event),
                              type: "text",
                              autoComplete: "off",
                              value: this.state.getVendorData.vendoraddress
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={2}>
                          <FormLabel className={classes.labelHorizontal}>
                            Email<span className="validatcolor">*</span>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={10}>
                          <CustomInput
                            id="email"
                            error={!!errors.email}
                            success={!!errors.email}
                            helpText={errors.email}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            name = "vendoremail"
                            inputProps={{
                              onChange: (event) =>
                              this.handleChange(event),
                          
                              autoComplete: "new-password",
                              value: this.state.getVendorData.vendoremail
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={2}>
                          <FormLabel className={classes.labelHorizontal}>
                           State Name<span className="validatcolor">*</span>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={10}>
                          <CustomInput
                            id="statename"
                            error={!!errors.statename}
                            success={!!errors.statename}
                            helpText={errors.statename}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            name = 'vendorstate'
                            inputProps={{
                              onChange: (event) =>
                              this.handleChange(event),
                          
                              type: "text",
                              autoComplete: "off",
                              value: this.state.getVendorData.vendorstate
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={2}>
                          <FormLabel className={classes.labelHorizontal}>
                           Country<span className="validatcolor">*</span>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={10}>
                          <CustomInput
                            id="country"
                            error={!!errors.country}
                            success={!!errors.country}
                            helpText={errors.country}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            name = "vendorcountry"
                            inputProps={{
                              onChange: (event) =>
                              this.handleChange(event),
                              type: "text",
                              autoComplete: "off",
                              value: this.state.getVendorData.vendorcountry
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={2}>
                          <FormLabel className={classes.labelHorizontal}>
                          Description<span className="validatcolor">*</span>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={10}>
                          <CustomInput
                            id="description"
                            error={!!errors.description}
                            success={!!errors.description}
                            helpText={errors.description}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            name ="vendordescription"
                            inputProps={{
                              onChange: (event) =>
                              this.handleChange(event),
                              value: this.state.getVendorData.vendordescription
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                     
                      <GridContainer>
                        <GridItem xs={12} sm={2}>
                          <FormLabel className={classes.labelHorizontal}>
                            Status<span className="validatcolor">*</span>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={4}>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="status"
                              className={classes.selectLabel}
                            >
                              {!status && "Choose Status"}
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              name =  "status"
                         
                              inputProps={{
                                value: this.state.getVendorData.status,
                                onChange: (event) =>
                                this.handleChange(event),
                                id: "status",
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                              >
                                Choose Status
                              </MenuItem>
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value="Active"
                              >
                                Active
                              </MenuItem>
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value="Inactive"
                              >
                                Inactive
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <FormHelperText error={errors.status}>
                            {errors.status}
                          </FormHelperText>
                        </GridItem>
                      </GridContainer>

                      {this.state.errorMessage ?
                      <div style={{color:"red"}}>{this.state.errorMessage}</div>
                      :""} 
                    </CardBody>
                    <CardFooter>
                      <Button
                        className={classes.roomTypeCancelBtn}
                        onClick={() => this.cancelButtonClick()}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="button"
                        color="rose"
                        // disabled={this.state.isButtonDisabled || submitionLoad}
                        onClick={this.vendorHandleSubmit}
                      >
                        Submit
                      </Button>
                    </CardFooter>
                    {popUpDetails.length ? (
                      <Snackbar
                        place="tc"
                        color="rose"
                        message={popUpDetails[0]}
                        open={!!popUpDetails.length}
                        closeNotification={() => {
                          this.setState({ popUpDetails: [] });
                        }}
                        close
                      />
                    ) : (
                      ""
                    )}
                  </Card>
                </form>
              );
            }}
          </Mutation>
        </GridItem>
      </GridContainer>
    );
  }
}
// const VendorEdit = compose(
//   graphql(GET_USER, {name: "userInfo"}),
//   graphql(UPDATE_TOGGLE_STATUS, {name: "updateToggle"})
// )(VendorEdit);
export default withStyles(styles)(VendorEdit);
