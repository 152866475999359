
import React,{useEffect , useRef, useState} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Row, Col, Container, Badge } from "react-bootstrap";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Card from "react-bootstrap/Card";
import gigs from "../../../assets/img/imageGigs.png";
import GradeIcon from "@mui/icons-material/Grade";
import vendorconstant from "../../vendorconstant";

const Gigs = () => {

  const [gigsData , setGigsData] = useState([])
  const [GigsBaseUrl , setGigsBaseUrl] = useState('')
  const [vendorBaseUrl , setVendorBaseUrl] = useState("")
  const gigsdata = [
    {
      image: gigs,
      name: "Henery",
      amount: "$10",
      description: "Lorem Ipsum is simply dummy text of the printing...",
    },
    {
      image: gigs,
      name: "Henery",
      amount: "$10",
      description: "Lorem Ipsum is simply dummy text of the printing...",
    },
    {
      image: gigs,
      name: "Henery",
      amount: "$10",
      description: "Lorem Ipsum is simply dummy text of the printing...",
    },
    {
      image: gigs,
      name: "Henery",
      amount: "$10",
      description: "Lorem Ipsum is simply dummy text of the printing...",
    },
  ];

  const didMountRef = useRef(true)
  useEffect(() => {
    if (didMountRef.current) {
      axios
      .get(vendorconstant.API_URL+"frontendgigs/getgigsdata")
      .then((response) => {
          let data = response.data;
          setGigsData(response.data.data)
          setGigsBaseUrl(response.data.baseurl)
          setVendorBaseUrl(response.data.vendorbaseurl)
      })
      .catch((error) => {
          console.log(error);
      });
    }
    didMountRef.current = false;
}, []);    

  return (
    <Container>
      <Row className="align-items-center  mb-50">
        <Col md={10} xl={10} lg={10} sm={10}>
          <div className="section-tittle">
            <h2 className="tittle">
              <span class="shape"></span>Recently Booked Vendors
            </h2>
          </div>
        </Col>
        <Col md={2} xl={2} lg={2} sm={2} className="text-right">
          <Link to={'/filter-vendor'} className="see_all-all">See All</Link>
        </Col>
      </Row>
      <Row>
        {gigsData.map(( value , image) => {
          return (
            <Col lg={3} md={4} sm={6} className="mb-lg-0 mb-4">
              <Link to={'/category-vendor/'+value._id}>
              <div
                className="gigs-cat-outer">
                <div
                  className="nn_homproductctn">
                
                    <Card.Img variant="top" src={value.gigImages[0] && value.gigImages[0].image ? GigsBaseUrl+value.gigImages[0].image:gigs} />
               
                </div>
                <div className="allprodetails card-content">
                  <div className="titlegigs-cat">
                    <div className="inner-catgigstitle">
                    <div className="d-flex align-items-center">
                    {value.vendorid.image != null  ?   <Card.Img variant="top" src={vendorBaseUrl + value.vendorid.image} width={15} height={50}/>
                            :<AccountCircleIcon style={{ fontSize: "30px", marginRight: "5px", color: "#556080" }} />}
                      <h4>{value.title.slice(0,20)}</h4>
                    </div>
                    <h6>${value.price}</h6>
                    </div>

                    {/* <p>{value.description.slice(0,50)}</p> */}

                  </div>
                  {value.ratings > 0 ?
                              <div className="d-flex">
                              {Array.from({ length: 5 }, (_, i) =>
                      i < value.ratings ? (
                        <GradeIcon key={`colored-${i}`} style={{ color: "#E99318" }} />
                      ) : (
                        <GradeIcon key={`gray-${i}`} style={{ color: "#A4A4A4" }} />
                      )
                    )}
                               
                               
                            
                              </div>:""}
                </div>
              </div>
              </Link>
            </Col>
          );
        })}
      </Row>
    </Container >
  );
};

export default Gigs;
