import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css'; // Import ReactTable CSS
import { FaEdit, FaTrash } from 'react-icons/fa'; // Import icons for edit and delete actions
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Card from '../../../components/Card/Card';
import CardHeader from '../../../components/Card/CardHeader';
import Button from '../../../components/CustomButtons/Button';
import CardBody from '../../../components/Card/CardBody';
import { cardTitle } from "../../../assets/jss/material-dashboard-pro-react.jsx";
import withStyles from "@material-ui/core/styles/withStyles"; // Import withStyles
import axios from 'axios'

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    buttonStyle: {
        float: "right",
        position: "relative",
        marginTop: "20px"
    },
    smallButton: {
        marginRight: "5px",
        backgroundColor: "#00acc1 !important",
        marginTop: "25px"
    },
    imageCell: {
        display: 'flex',
        justifyContent: 'center'
    },
    image: {
        width: '50px',
        height: '50px',
        borderRadius: '50%'
    },
    tableWrapper: {
        overflowX: 'auto',
        maxWidth: '100%',
    }
};

const { REACT_APP_ADMIN_PATH,REACT_APP_Domain_Url } = process.env;
class CommentList extends React.Component {
    state = {
        data: []
    };
    componentDidMount = async () => {
        await this.getListFun();
    };

    getListFun = async () => {
        try {
            const res = await axios.get(REACT_APP_Domain_Url + "admin/getAllComments");
            if (res.data.status === 200) {
                this.setState({ data: res.data.comments });
            } else {
                this.setState({ data: [] });
            }
        } catch (error) {
            console.log(error);
        }
    };
    commentStatusChange = async (status, comment_id) => {
        const paramsVal = {
            "comment_id":comment_id,
            "status": status
        }
        try {
            const res = await axios.post(`${REACT_APP_Domain_Url}admin/commentStatus`, paramsVal);
            if (res.data.status === 200) {
                this.getListFun();
            } else {
                console.log("Failed to delete post");
            }
        } catch (error) {
            console.error("Error deleting post:", error);
        }
    };
    handleDelete = async (comment_id) => {
        try {
            const res = await axios.post(`${REACT_APP_Domain_Url}admin/deleteCommentById`, {comment_id : comment_id});
            if (res.data.status === 200) {
                this.getListFun();
            } else {
                console.log("Failed to delete post");
            }
        } catch (error) {
            console.error("Error deleting post:", error);
        }
    };
    renderMyTable() {
        const { classes } = this.props;
        const { data } = this.state;
        const columns = [
            {
                Header: 'Comment',
                accessor: 'comment'
            },
            {
                Header: 'User Name',
                accessor: 'userName'
            },
            {
                Header: 'Date',
                accessor: 'created_at',
                Cell: row => <span>{new Date(row.value).toLocaleString()}</span>
            },
            {
                Header: 'Actions',
                sortable: false,
                accessor: 'status',
                Cell: row => (
                    <div className="actions-right">

                        {
                            !row.value ?
                            <Button
                                round
                                color="success"
                                className="approve"
                                onClick={() => this.commentStatusChange(!row.value, row.original._id)}
                            >
                            Approve
                            </Button>
                            :
                            <Button
                                round
                                color="warning"
                                className="unapprove"
                                onClick={() => this.commentStatusChange(!row.value, row.original._id)}
                            >
                                Unapprove
                            </Button>
                        }
                        <Button
                            justIcon
                            round
                            simple
                            color="danger"
                            className="remove"
                            onClick={() => this.handleDelete(row.original._id)}
                        >
                            <FaTrash />
                        </Button>
                    </div>
                )
            }
        ];

        const tableData = data.map(comment => ({
            _id: comment.comment._id,
            post_id: comment.comment.post_id,
            comment: comment.comment.comment,
            status: comment.comment.status,
            userName: comment.userName,
            created_at: comment.comment.created_at
        }));

        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardBody>
                        <div className={classes.tableWrapper}>
                            <ReactTable
                              data={tableData}
                              columns={columns}
                              filterable
                              defaultPageSize={10}
                              className="-striped -highlight"
                              minRows={0}
                            />
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }

    render() {
        return (
            <div>
                {this.renderMyTable()}
            </div>
        );
    }
}

export default withStyles(styles)(CommentList);
