import React from "react";
import { compose, graphql } from "react-apollo";
import {
  CATEGORY_ID,
  GET_CURRENCIES,
  GET_SITE_INFO,
  GET_ALL_PRODUCTS,
  GET_CATEGORIES,
  GET_LANGUAGES,
  GET_STATIC_PAGE,
  CATE_LANG_REFETCH,
  GET_META_TAG,
  GET_CURRENT_USER

} from "../../../queries";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../../components/Grid/GridItem.jsx";
//import loginStyles from "../../../assets/jss/material-dashboard-pro-react/components/loginComponent.jsx";
import { Link } from "react-router-dom";
//import headerStyles from "../../../assets/jss/material-dashboard-pro-react/components/headerStyle.jsx";
import pagesStyle from "../../../assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";
import googleplay from "../../../assets/img/badge_googleplay.svg";
import appstroe from "../../../assets/img/badge_appstore.svg";
import { withTranslation } from "react-i18next";
// import "../css/style.css";
// import Footermobileimg from '../../../assets/img/signup.png'
import Grid from "@material-ui/core/Grid";
import { FooterMain } from '../css/styledcomponents';
import { Container, Form } from "react-bootstrap";
import FavoriteIcon from '@material-ui/icons/Favorite';
import Button from "../../../components/CustomButtons/Button";
import history from "../../../history";
import { Add, Camera, CameraAlt, Chat, Person } from "@material-ui/icons";

// var styles = {
//   ...loginStyles,
//   ...headerStyles(),
//   customBtn: {
//     borderColor: "white !important",
//     "&:hover": {
//       borderColor: "white !important"
//     }
//   }
// };

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: {},
      iosLink: "",
      androidLink: "",
      sitename: "",
      facebookLink: "",
      twitterLink: "",
      instagramLink: "",
      image: "",
      footerLogo: "",
      footerBatch: "",
      footerBackground: "",
      currency: "",
      totalLanguages: []
    };


  }
  change = e => {
    let { productsInfo, currencyInfo } = this.props;
    localStorage.setItem("currency", e.target.value);
    productsInfo.refetch({ filter: {} }).then(({ data }) => { });

    currencyInfo.getCurrencies.map((cur, i) => {
      if (cur.code === e.target.value) {
        localStorage.setItem("currencySymbol", cur.symbol);
      }
    });
    this.props.getRefetch({ variables: { categoryRefetch: true } });
  };

  changeLng = (e, i18n) => {
    let arr = ["ar", "fr", "en"];
    let langList = e;
    let { categoryInfo, staticPagesTerms, productsInfo, AdvancedFilter, getMetatags } = this.props;
    localStorage.setItem("lang", e);
    if (AdvancedFilter && AdvancedFilter !== undefined) {
      this.props.AdvancedFiltersubmit({
        fieldChild: [],
        rangeFilter: []
      });
    }
    localStorage.setItem("langList", e);
    if (window.location.pathname === "/") {
      categoryInfo.refetch().then(({ data }) => {
        this.props.getCategory(data);
      });
    }
    staticPagesTerms.refetch().then(({ data }) => { });

    productsInfo.refetch({ filter: {} }).then(({ data }) => {
    })

    if (e === "ar") {
      // document.getElementById("rtlsz").style.fontSize = "35px";
      document.body.setAttribute("dir", "rtl");
      localStorage.setItem("rtl", "rtl");
    } else if (e === "fr") {
      // document.getElementById("rtlsz").style.fontSize = "35px";
      document.body.setAttribute("dir", "ltr");
      localStorage.setItem("ltr", "ltr");
    } else {
      if (!arr.includes(e)) {
        //localStorage.setItem("lang", "en");
        langList = "en";
        localStorage.setItem("langList", e);
      } else {
        //  localStorage.getItem("lang")
        // localStorage.setItem("langList", e);
      }
      // document.getElementById("rtlsz").style.fontSize = "64px";
      document.body.setAttribute("dir", "ltr");
      localStorage.setItem("ltr", "ltr");
    }
    i18n.changeLanguage(langList);
    this.props.getRefetch({ variables: { categoryRefetch: true } });
    getMetatags.refetch().then(({ data }) => { });
  };

  componentWillMount() {
    let { siteInfo, getLanguages, getCurrentUser, currencyInfo } = this.props;
    siteInfo.refetch();
    if (siteInfo.getSiteInfo) {
      let {
        iosLink,
        androidLink,
        name,
        fbLink,
        twLink,
        instagramLink,
        image,
        footerLogo,
        footerBatch,
        footerBackground,
        copyrightsText
      } = siteInfo.getSiteInfo;
      this.setState({
        iosLink: iosLink,
        androidLink: androidLink,
        sitename: name,
        facebookLink: fbLink,
        twitterLink: twLink,
        instagramLink: instagramLink,
        image: image,
        footerLogo: footerLogo,
        footerBatch: footerBatch,
        footerBackground: footerBackground,
        copyrightsText: copyrightsText
      });
    }

    getLanguages.refetch({}).then(({ data }) => {
      if (data) {
        let langData = data && data.getLanguages;
        this.setState({
          totalLanguages: langData
        });
      }
    });
    getCurrentUser.refetch({}).then(({ data }) => {
      if (data) {
        let currentUser = data && data.getCurrentUser;
        this.setState({
          currentUser
        });
      }
    });

    currencyInfo.refetch({}).then(({ data }) => {
      if (data && data.getCurrencies) {
        let check = data.getCurrencies.find(cat => {
          if (cat.code === localStorage.getItem("currency")) {
            this.setState({
              currency: localStorage.getItem("currency")
            });
          }
          return cat.code === localStorage.getItem("currency");
        });

        if (check === undefined) {
          if (this.props.siteInfo && this.props.siteInfo.getSiteInfo) {
            const key = this.props.siteInfo.getSiteInfo;
            localStorage.setItem("currency", key.defaultCurrency);
            this.setState({
              currency: localStorage.getItem("currency")
            });
          }
        }
      }
    });
  }

  handleClick = (id) => {
    this.props.getCategoryId({ variables: { categoryId: id } });
    this.setState({ categoryId: id });
    var url = new URL(window.location);
    url.searchParams.set("categoryId", id)
    if (url.searchParams.get("dyn_filter")) {
      this.props.AdvancedFiltersubmit({
        fieldChild: []
      });
      url.searchParams.delete("dyn_filter")
    }
    if (url.searchParams.get("range_filter")) {
      const rangeArrayData = url.searchParams.get("range_filter").split("-")
      if (rangeArrayData && rangeArrayData.length) {
        for (var key in rangeArrayData) {
          url.searchParams.delete(rangeArrayData[key])
        }
        url.searchParams.delete("range_filter")
      }
      this.props.AdvancedFiltersubmit({
        rangeFilter: []
      });
    }
    history.push(`?${url.searchParams.toString()}`)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.siteInfo && nextProps.siteInfo.getSiteInfo) {
      let {
        iosLink,
        androidLink,
        name,
        fbLink,
        twLink,
        instagramLink,
        utubeLink,
        image,
        footerLogo,
        footerBatch,
        footerBackground,
        copyrightsText
      } = nextProps.siteInfo.getSiteInfo;
      this.setState({
        iosLink: iosLink,
        androidLink: androidLink,
        sitename: name,
        facebookLink: fbLink,
        twitterLink: twLink,
        instagramLink: instagramLink,
        utubeLink: utubeLink,
        image: image,
        footerLogo: footerLogo,
        footerBatch: footerBatch,
        footerBackground: footerBackground,
        copyrightsText: copyrightsText,
        currency: localStorage.getItem("currency")
      });
    }
  }
  playStore = link => {
    window.open(link, "_blank");
  };

  socialMedia = url => {
    window.open(url, "_blank");
  };



  render() {
    let { categoryInfo, staticPagesTerms } = this.props;
    var {
      currentUser,
      iosLink,
      androidLink,
      facebookLink,
      twitterLink,
      instagramLink,
      utubeLink,
      footerLogo,
      footerBatch,
      copyrightsText
    } = this.state;

    return (
      <FooterMain className="footer-outer">
        <div className="nn_footer footer-space">
          <div className="nn_ftmain">
            <Container>
              <div id="ftctn">
                {this.state.androidLink || this.state.iosLink !== "" ? (
                  <section
                    className={
                      localStorage.getItem("lang") === "fr" && "ar"
                        ? "nn_footerfirstpart friss"
                        : "nn_footerfirstpart"
                    }
                  >
                    <div className="nn_footermain footer-top-height" id="footerId">
                      <Grid container className="footer-content">
                        <GridItem xs={12} sm={12} md={4} className="nn_footermain1">
                          <div className="nn_footermobileimg nn_ftctn footertopimg">
                            <img src={footerBatch} className="img-fluid" alt="" />
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={8} className="width100">
                          <div className="nn_ftmainctn">
                            <div className="nn_ftctn">
                              {/* <h1
                              className={
                                localStorage.getItem("lang") === "fr" && "ar"
                                  ? "nn_getfreeapp frissues"
                                  : "nn_getfreeapp"
                              }
                              id="rtlsz"
                            >
                              {" "}
                              {t("footer._freeapp")}
                            </h1> */}
                              <div className="download_appcnt">
                                <p>GET THE <span className="free-app">FREE</span> <span>APP</span> NOW!</p>
                              </div>

                            </div>
                            <div
                              className={
                                localStorage.getItem("lang") === "fr" && "ar"
                                  ? "nn_ftapps frissus"
                                  : "nn_ftapps nn_ftctn"
                              }
                            >
                              <div
                                className={
                                  localStorage.getItem("lang") === "fr" && "ar"
                                    ? "nn_appsimg friss"
                                    : "nn_appsimg"
                                }
                              >
                                <div className="nn_footerapps">
                                  {this.state.androidLink !== "" ? (
                                    <div onClick={() => this.playStore(androidLink)} className="nn_googlepay">
                                      <img src={googleplay} alt="" />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.iosLink !== "" ? (
                                    <div onClick={() => this.playStore(iosLink)} className="nn_googlepay">
                                      <img src={appstroe} alt="" />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </GridItem>
                      </Grid>
                    </div>
                  </section>
                ) : (
                  ""
                )}
              </div>
            </Container>
          </div>

          <div className="nn_footersecpart footer-bg" >
            {/* <img src={footerBackground} className="nn_ftbgimg" alt="" style={{width: "100%"}}/> */}
            <Container>
              <div className="nn_footersecctn footer-bgspace">
                <Grid container className="footer-inner">
                  <GridItem xs={12} sm={12} md={3} className="mb-lg-0 mb-5 ">
                    <div className="nn_footerlogo mb-20">
                      <img src={footerLogo} alt="" />
                    </div>
                    <div className="footer-social">
                      <ul className="listing">
                        <li className="listItem">
                          <a target="" href="mailto:info@myplo.com" className="singleLinks">
                            <i class="fas fa-envelope icon"></i>
                            info@myplo.com
                          </a>
                        </li>



                      </ul>
                      <div className="nn_socialicons mb-5">
                        <div className="nn_ftsocialctn">
                          <ul className="newsletter">
                            {this.state.facebookLink !== "" ? (
                              <li
                                className="nn_fticon "
                                onClick={() => this.socialMedia(facebookLink)}
                              >
                                <a title="facebook" target="_blank">
                                  <i className="fa fa-facebook social-icon" aria-hidden="true"></i>
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                            {this.state.twitterLink !== "" ? (
                              <li
                                className="nn_fticon"
                                onClick={() => this.socialMedia(twitterLink)}
                              >
                                <a title="twitter" target="_blank">
                                  <i className="fa fa-twitter social-icon" aria-hidden="true"></i>
                                </a>
                              </li>
                            ) : (
                              ""
                            )}

                            {this.state.instagramLink !== "" ? (
                              <li
                                className="nn_fticon"
                                onClick={() => this.socialMedia(instagramLink)}
                              >
                                <a title="instagram" target="_blank">
                                  <i className="fa fa-instagram social-icon" aria-hidden="true"></i>
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                            {this.state.utubeLink !== "" ? (
                              <li
                                className="nn_fticon"
                                onClick={() => this.socialMedia(utubeLink)}
                              >
                                <a title="youtube" target="_blank">
                                  <i
                                    className="fa fa-youtube-play social-icon"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="quicklink">
                        <Link to='' target="" className="buttonfoot"
                          onClick={() => { this.props.openSidePanel() }}
                        >
                          <CameraAlt></CameraAlt> Sell Now
                        </Link>

                        <Link to={`/chat/conversation`} target="" className="buttonfoot">

                          <svg
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            className="sc-jTzLTM eWXXCS"
                            fill="#fff"
                          >
                            <path d="M7.249 21.204v-1.902c0-.58-.47-1.05-1.05-1.05A4.2 4.2 0 0 1 2 14.053v-5.86A4.194 4.194 0 0 1 6.193 4h11.734a4.193 4.193 0 0 1 4.193 4.193v5.866a4.193 4.193 0 0 1-4.193 4.193h-5.013c-.444 0-.87.177-1.185.49l-3.05 3.048c-.525.526-1.424.158-1.43-.586zm.617-8.828a1.255 1.255 0 1 0 0-2.512 1.256 1.256 0 1 0 0 2.512zm8.383 0a1.255 1.255 0 1 0 0-2.512 1.256 1.256 0 1 0 0 2.512zm-4.191 0a1.255 1.255 0 1 0 0-2.512 1.256 1.256 0 1 0 0 2.512z"></path>
                          </svg> Chat
                        </Link>
                        {currentUser && (
                          <>
                            <Link to={'/EditProfile/' + currentUser.id} target="" className="buttonfoot">
                              <Add></Add>  My Post
                            </Link>

                            <Link to={'/EditProfile/' + currentUser.id} target="" className="buttonfoot">
                              <Person />  Profile
                            </Link></>
                        )}

                      </div>
                      <img style={{ "margin-top": "10px", "width": "120px" }} src="https://myplo.com/fileStorage/static/seal.png" />
                    </div>

                  </GridItem>

                  <GridItem xs={12} sm={12} md={3} className="mb-lg-0 mb-5">
                    <div className="copyright-list footer-tittle">
                      <h4 class="footerTittle">Party Supplies by Occasion</h4>
                      <div className="footer-category-list">
                        <ul className="listing" >
                          {categoryInfo.getCategoryDetails &&
                            categoryInfo.getCategoryDetails.category.filter(x => x.status === "Active").map(
                              (p, index) => {
                                return (
                                  <li key={index} className="listItem ">
                                    {" "}
                                    <p onClick={() => { this.handleClick(p.id) }} className="singleLinks"> {p.name} </p>{" "}
                                  </li>
                                );
                              }
                            )}
                        </ul>
                      </div>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3} className="mb-lg-0 mb-5">
                    <div className="nn_ftdropdown">
                      <div className="nn_ftaboutctn">
                        <h5 className="footerTittle">About</h5>
                        <ul className="listing">

                          <li className="listItem ">
                            <Link to="/Info/contact" className="singleLinks">
                              {" "}
                              {this.props.t("footer._ContactUs")}{" "}
                            </Link>{" "}
                          </li>

                          <li className="listItem ">
                            <Link to="/blog" className="singleLinks">
                              Blog
                            </Link>{" "}
                          </li>

                          {staticPagesTerms.getstaticPageDetails &&
                            staticPagesTerms.getstaticPageDetails.filter(x => x.status === "Active").map(
                              (p, index) => {
                                return (
                                  <li key={index} className="listItem ">
                                    {" "}
                                    <Link to={p.url} className="singleLinks"> {p.title} </Link>{" "}
                                  </li>
                                );
                              }
                            )}
                        </ul>
                      </div>
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3} className="">
                    <div className="footer-tittle footer-tittle2">
                      <h4 className="footerTittle">Newsletter</h4>
                      <div className="footer-pera footer-pera2">
                        <div className="pera">
                          Be the first one to know news, offers and events weekly in your. Unsubscribe whenever you like with one click.
                        </div>
                      </div>
                    </div>
                    <div className="footer-form mt-10">
                      <div className="form-row mb-20">
                        <Form className="newsletter-footer">
                          <Form.Group className="mb-3 news-input">
                            <Form.Control
                              placeholder="Your Email Address"
                            />
                            <div className="append">
                              <Form.Group id="basic-addon2">Submit</Form.Group>
                            </div>
                          </Form.Group>
                        </Form>
                      </div>
                    </div>

                  </GridItem>
                </Grid>
                <hr />
                <Grid container>
                  <GridItem xs={12} sm={12}>
                    <div className="nn_footerPara txt_center copyright">
                      <p className="nn_ftpara" >
                        {copyrightsText}
                      </p>
                    </div>
                  </GridItem>
                </Grid>
              </div>
            </Container>
          </div>
        </div>
      </FooterMain>
    );
  }
}

var footer = compose(
  graphql(GET_SITE_INFO, { name: "siteInfo" }),
  graphql(GET_ALL_PRODUCTS, {
    name: "productsInfo",
    options: () => ({
      variables: {
        filter: {}
      }
    })
  }),

  graphql(GET_CATEGORIES, {
    name: "categoryInfo",
    options: () => ({
      fetchPolicy: "cache and network"
    })
  }),
  graphql(CATEGORY_ID, { name: "getCategoryId" }),
  graphql(GET_STATIC_PAGE, { name: "staticPagesTerms" }),
  graphql(GET_SITE_INFO, { name: "siteInfo" }),
  graphql(GET_CURRENCIES, { name: "currencyInfo" }),
  graphql(GET_LANGUAGES, { name: "getLanguages" }),
  graphql(CATE_LANG_REFETCH, { name: "getRefetch" }),
  graphql(GET_META_TAG, { name: "getMetatags" }),
  graphql(GET_CURRENT_USER, { name: "getCurrentUser" })
)(Footer);

export default withTranslation("common")(withStyles(pagesStyle)(footer));
