import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import vendorconstant from '../../vendorconstant';
import axios from 'axios';
import { ChatBox, LoginPopup } from '../css/styledcomponents';
import moment from 'moment';
import Button from '../../../components/CustomButtons/Button';
import { Close } from '@material-ui/icons';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from '../Login/PaymentMode';
import CategoryVendorPaymentMode from '../Dashboard/Filters/CategoryVendorPaymentMode';
const stripePromise = loadStripe('pk_test_txDJRlXoAFelCWzOrBdENCRS');
const ChatWindowVendor = ({ giData, closemodel, userid}) => {
	const didMountRef = useRef(true);
	const [message, setMessage] = useState('');
	const [messages, setMessages] = useState([]);
	const [isOpen, setIsOpen] = useState(false)
	const [ getRenderStatus , setGetRenderStatus] = useState(false)
	const [clickOnPay , setClickOnPay] = useState("")
	const chatContainerRef = useRef();
	useEffect(() => {
		if (didMountRef.current) {
			getchatsdata();
			if( chatContainerRef.current){
				chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
				}
		}
		didMountRef.current = false
	}, [messages]);
	const handleOpenPayAmount = (value) => {
		setIsOpen(true)
		setClickOnPay(value)
	}
	const handleClosePayAmount = () => {
		setIsOpen(false)
	}
	useEffect(() => {
		// Replace 'http://localhost:5000' with the URL of your backend server where the Socket.io server is running
		const socket = io("https://vendor.myplo.com/", {
			withCredentials: true, // Include credentials such as cookies in requests (if applicable)
		});
		// Handle new messages received from the server
		socket.on('message', (data) => {
	
			setMessages((prevMessages) => [...prevMessages, data]);
			// Update the chat interface with the new message
			// You can use state or other methods to display the message in the chat window
		});
		// Clean up the WebSocket connection on component unmount
		return () => {
			socket.disconnect();
		};
	}, []);
	const getchatsdata = async () => {
		try {
			const response = await axios.post(vendorconstant.API_URL + 'chat/getchatsdata', {
				userid: userid.getCurrentUser.id,				// Replace 'user_id' with the actual user ID of the sender
				gigId: giData._id, // Replace 'vendor_id' with the actual user ID of the recipient
				vendorid: giData.vendorid._id,
			});
			if (response.data.data && response.data.data.length > 0) {
				setMessages(response.data.data); // You can handle the response as needed
			}
		} catch (error) {
			console.error('Failed to send message:', error);
		}
	}
		
	const handleSendMessage = async () => {
	
		if (message == "") {
			alert("Pleaae Enter Something")
			return false
		}
		// Replace 'http://localhost:5000' with the URL of your backend server where the Socket.io server is running
		const socket = io("https://vendor.myplo.com/", {
			withCredentials: true, // Include credentials such as cookies in requests (if applicable)
		});
		// Emit the 'sendMessage' event with the message content
		socket.emit('sendMessage', { message });
		try {
			const response = await axios.post(vendorconstant.API_URL + 'chat/sendmessage', {
				userid: userid.getCurrentUser.id, // Replace 'user_id' with the actual user ID of the sender
				gigId: giData._id, // Replace 'vendor_id' with the actual user ID of the recipient
				vendorid: giData.vendorid._id,
				content: message,
				sentby: 'USER',
			});
			console.log(response.data); // You can handle the response as needed
		} catch (error) {
			console.error('Failed to send message:', error);
		}
		// Reset the message input after sending
		setMessage('');
	};
	const closechatmode = () => {
		closemodel(false)
	}
	const handleLoader = ()=>{
        setGetRenderStatus(true)
    }
	const scrollToBottom = () => {
		
	  };
	  

	// Render the component JSX
	return (
		<>
	
			<ChatBox className="nn_chatNow">
				<div className="nn_chatboxheader" style={{marginRight:"300px"}}>
					<div className="nn_chatboxtitle" >
						<div className="nn_chatleft">
							<div className="nn_msg_count_head">
								<div className="nn_msg_count">
									<span><i className="fa fa-envelope" aria-hidden="true"></i></span>
									<b>{giData.vendorid.vendorname}</b>
								</div>
								{/* {unReadMessageData && unReadMessageData.length > 0 && */}
								<div className="nn_notification">
								</div>
								{/* } */}

							</div>
							{/* <div className="chatusenae">  </div> */}
						</div>
						<div className="nn_chatright">
							<ul>
								<li > <span> <i className="fa fa-minus" aria-hidden="true" onClick={(e) => closechatmode()}></i> </span> </li>
							</ul>
						</div>
					</div>
					<div className="nn_chatconver" > 
						{messages && messages.length > 0 ? messages.map((value) =>
							<div class="nn_user" ref={chatContainerRef}>
								<div class="nn_senderpart">
									{value.sentby == "VENDOR" ?
										<div class="nn_chatmsgctn" style={{ float: "left" }}>
											<div class="nn_nortltrans" style={{
												backgroundColor: "var(--theme-color)", color: "rgb(255, 255, 255)", padding: "6px 20px", borderRadius: "5px 20px", fontSize: "14px", fontWeight: "500"
											}}>{value.message}</div>
											<span className="nn_dateseen">{moment(value.created_at).fromNow()}</span>
											{value.offeraccepted == 1 ?
												<button onClick={(e) => handleOpenPayAmount(value)}>Pay(${value.amount})</button> : ""}
											{/* <span class="nn_dateseen">now</span> */}
										</div>
										: <div class="nn_chatmsgctn" style={{ float: "right" }}>
											<div class="nn_nortltrans" style={{
												backgroundColor: "var(--theme-color)", color: "rgb(255, 255, 255)", padding: "6px 20px", borderRadius: "5px 20px", fontSize: "14px", fontWeight: "500"
											}}>{value.message}</div>
											<span className="nn_dateseen">{moment(value.created_at).fromNow()}</span>
											{value.offeraccepted == 1 ?
												<button >Pay</button> : ""}
											{/* <span class="nn_dateseen">now</span> */}
										</div>}
								</div>
							</div>
						) :
							<div className="nn_respchar">
								<p className="noMsgYet">No Message Yet</p>
							</div>
						}
					</div>
					<div className="nn_chatmsg_new">
						<div className="nn_sendctn">
							<div className="nn_sendinputctn">
								<input
									type="text"
									placeholder={'Type a message'}
									value={message}
									onChange={(e) => setMessage(e.target.value)}
                         		// onChange={(e) => this.handleInput(e)}
								// onKeyPress={e =>
								//     this.handleKeyPress(
								//       e,
								//       x,
								//       this.state.newObj
								//     )
								//   }
								/>
							</div>
							<div style={{ display: "flex" }} className="nn_sendbtn">
								<button className="boredrradus"
									style={{ marginLeft: "0px" }}
									onClick={handleSendMessage}
								>Send
								</button>
							</div>
						</div>
					</div>
				</div>
			</ChatBox>
			<LoginPopup
				isOpen={isOpen}
				contentLabel="Example Modal"
				className="loginModalboot"
			> <div className="loginModalbootouter">
					<div className="loginPopup" style={{ margin: "0px" }}>
						<div
							className="resnoban"
							style={{
								backgroundRepeat: "no-repeat",
								backgroundColor: "grey",
								backgroundSize: "cover",
								width: "15%",
								background: "#fff"
							}}
						>
							<div className="close-Id">
								<Button
									justIcon
									round
									simple
									onClick={handleClosePayAmount}
									color="twitter"
									className="edit"
									style={{ background: "#6610f2" }}
								>
									<Close />
								</Button>
								<Elements stripe={stripePromise}>
									<CategoryVendorPaymentMode  date={clickOnPay.date} time={clickOnPay.time} gigsdata={clickOnPay} type="payfromchat" setLoader={handleLoader} />
								</Elements>
							</div>
							<div>
								<div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</LoginPopup>
		</>
	);
};
export default ChatWindowVendor;
