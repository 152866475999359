import React, { useEffect, useRef, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from "axios";
import Button from '../../../../components/CustomButtons/Button';
import vendorconstant from '../../../vendorconstant';
import { useHistory } from 'react-router/cjs/react-router.min';
import { Loader } from '../../css/styledcomponents';
import { animateScroll as scroll } from "react-scroll";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import confirmorder from "../../../../assets/img/confirmorder.jpg"
import GradeIcon from "@mui/icons-material/Grade";
import ReactStars from "react-rating-stars-component";
const CategoryVendorPaymentMode = ({time , date , gigsdata,type,amount, setLoader,userid}) => {
  const didMountRef = useRef(true)
  const history = useHistory()
  const [openModal , setOpenModal] = useState(false)
  const [rating , setRating] = useState("")
  const [hidePayButton , setHidePayButton] = useState(false)
  const cardElementOptions = {hidePostalCode: true};
  useEffect(() => {
    if (didMountRef.current) {
      console.log(gigsdata)
        }
    didMountRef.current = false;
  });
  const stripe = useStripe();
  const elements = useElements();
  const handleSubmit = async () => {
    setHidePayButton(true)
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet, so do nothing.
      return;
    }

    // Get a PaymentMethod object representing the card details
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      // Handle error, display error to the user
      console.log(error.message);
      alert(error.message); 
      return false;
    } else {
      // PaymentMethod ID
      const paymentMethodId = paymentMethod.id;
      // Call your API with the paymentMethodId   

const datastring = {
  gigId : gigsdata._id,
  userid: userid,
  date: date,
  time: time,
  amount: amount,
  paymentMethodId:paymentMethodId,   
  type : type,
}
axios
    .post(vendorconstant.API_URL + "frontendgigs/createorder", datastring)
    .then((response) => {            
        if(response.data.status == "success"){
          setOpenModal(true)
      }
    else{
    
    }
    })  
      .catch((error) => {
          console.log(error);
      });
    }
  };
 const scrollToTop=()=> {
    scroll.scrollToTop();
}
const ratingChanged = (newRating) => {
  setRating(newRating);
};

const saveStarRatings=()=>{
  if(rating == ""){
    alert("Please select ratings")
  }
  const datastring ={
    gigId : gigsdata._id,
    userid: userid,
    vendorid: gigsdata.vendorid._id,
    ratings: rating
  }
  axios
    .post(vendorconstant.API_URL + "gigs/addratingsdata", datastring)
    .then((response) => {            
        if(response.data.status == "success"){
       window.location.reload()  
    }
    })  
}
  return (
    <>   

  
      <div>
        <label>
          Card details
          <CardElement  options={cardElementOptions} />
        </label>
      </div>
     {hidePayButton == false ? 
      <div className="text-center mt-4">
      <Button
       className="nn-chhting"
        style={{
          // color: "rgb(254, 58, 86)",
          padding: "12px",
        }}
        size="sm"
        onClick={handleSubmit}
      >Pay
      </Button>
    </div>:""}

    <Dialog
                   className="text-center"
                    open={openModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <div className="p-4">
                      <img src={confirmorder} className="conformorder" />
                      <DialogTitle id="alert-dialog-title">
                        {"Order Confirmed"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Kindly provide rating
                        </DialogContentText>
                        {/* <div className="d-flex justify-content-center">
                          <GradeIcon style={{ color: "#E99318" }} />
                          <GradeIcon style={{ color: "#E99318" }} />
                          <GradeIcon style={{ color: "#E99318" }} />
                          <GradeIcon style={{ color: "#E99318" }} />
                          <GradeIcon style={{ color: "#A4A4A4" }} />
                        </div>
                        */}
                        <ReactStars classNames="ratingstart" count={5} onChange={ratingChanged} size={24} activeColor="#ffd700" />
                      </DialogContent>
                      <DialogActions className="justify-content-center">
                        <div className="nn_pro_chatbtn mr-0">
                          <button className="nn-chhting px-5" onClick={saveStarRatings}>Rate Services</button>
                        </div>
                      </DialogActions>
                    </div>
                  </Dialog> 
                      </>
  );
};
export default CategoryVendorPaymentMode;
