import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "../../../assets/jss/material-dashboard-pro-react/components/loginComponent.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Close from "@material-ui/icons/Close";
import { Checkbox, Select } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import CardBody from "../../../components/Card/CardBody.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import { compose, graphql } from "react-apollo";
import user from "../../../assets/img/groupuser.svg";
import Vendor from "../../../assets/img/groupvendor.svg";
import ms from "../../../assets/img/user-plan.svg";
import Form from "react-bootstrap/Form";
import axios from "axios";
import {
  SIGNIN,
  SIGNUP,
  VENDOR,
  SOCIAL_LOGIN,
  FORGOT_PASSWORD,
  GET_SITE_INFO,
  GET_ROSTER,
  REDIRECT_HOME_FILTER,
  GET_REDIRECTFILTER_STATE,
  VERIFY_EMAIL,
} from "../../../queries";
import facebook from "../../../assets/img/facebook.png";
import google from "../../../assets/img/google.png";
import apple from "../../../assets/img/apple1.png";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import AppleLogin from "react-apple-login";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { error } from "../Toast.jsx";
import * as Toastr from "../Toast.jsx";
import history from "../../../history";
import { LoginPopup, DiscardPopup } from "../css/styledcomponents";
import loginImageLogo from "./../../../assets/img/myplo-logo.png";
import { Col, Dropdown, Row } from "react-bootstrap";
import vendorconstant from "../../vendorconstant.js";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from "./PaymentMode.js";

//eslint-disable-next-line
const REGEX = RegExp(
  //email verification
  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/i
);

const {
  REACT_APP_User_Login,
  REACT_APP_User_Password,
  REACT_APP_ENV,
  REACT_APP_Domain_Url,
} = process.env;

const initialState = {
  login: false,
  signUpOrIn: false,
  isLoggedIn: false,
  forgot: false,
  signUp: false,
  email: "",
  password: "",
  userName: "",
  location: {},
  errors: {},
  userID: "",
  popUpDetails: [],
  isButtonDisabled: true,
  image: "",
  emailPopup: false,
  modelState: false,
  finalObject: {},
  fblogin: false,
  googlelogin: false,
  applelogin: false,
  verifyUser: false,
  registerVendor: false,
  loginVendor: false,
  vendorname: "",
  vendoremail: "",
  companyName: "",
  vendorpassword: "",
  vendorphone: "",
  cardnumber: "",
  cvv: "",
  monthdata: "",
  cardmonth: "",
  cardyear: "",
  vendorRegisterid: "",
  vendorRegistername: "",
  errormessage: ""

};

const customStyles2 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "auto",
  },
};

const stripePromise = loadStripe('pk_test_txDJRlXoAFelCWzOrBdENCRS');
class LoginComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      signupcheckbox: false,
      signupcheckboxerror: "",
      showUserRegisterForm: false,
      showVendorRegisterForm: false,
      showVendorLoginForm: false,
      showLoginIcon: true,
      showRegisterIcon: true,
      showLoginForm: false,
      showLoginType: 'signin',
      vendorlogin: "",
      showAfterVendorRegisterForm: false,
    };
    this.forgetPasswordChange = this.forgetPasswordChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.hideComponent = this.hideComponent.bind(this);
    this.change = this.change.bind(this);
  }
  clearState() {
    this.setState({ ...initialState });
  }
  closePopup = (e) => {
    if (e.key === "Escape") {
      this.props.onClick(false);
    }
  };
  logintype(type) {
    this.setState({
      showVendorRegisterForm: !this.state.showVendorRegisterForm,
    });
  }
  hideComponent(name) {
    switch (name) {
      case "showUserRegisterForm":
        this.setState({
          showUserRegisterForm: !this.state.showUserRegisterForm,
        });
        break;
      case "showVendorRegisterForm":
        this.setState({
          showVendorRegisterForm: !this.state.showVendorRegisterForm,
        });
        break;
      case "showLoginForm":
        this.setState({ showLoginForm: !this.state.showLoginForm });


        break;
      case "showVendorLoginForm":
        this.setState({ showLoginForm: !this.state.showLoginForm });
        break;
      case "showAfterVendorRegisterForm":
        this.setState({ showAfterVendorRegisterForm: !this.state.showAfterVendorRegisterForm });
        break;

    }
  }
  handleRegisterShow = () => {
    this.setState({
      showRegisterIcon: true,
      showUserRegisterForm: false,
      showVendorRegisterForm: false,
      showLoginForm: false,
      showVendorLoginForm: false,
    });
  };

  handleRegisterHide = () => {
    this.setState({
      showRegisterIcon: false,
    });
  };
  signupcheckboxhandle = (e) => {
    var b = this.state.signupcheckbox;
    this.setState({
      signupcheckbox: !b,
    });
  };
  componentDidMount() {
    document.addEventListener("keydown", this.closePopup);
    let { verifyEmail } = this.props;
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const encryptVerify = params.get("id");
    const popup = params.get("pop");
    if (
      encryptVerify !== null &&
      encryptVerify !== undefined &&
      popup === "confirm-mail"
    ) {
      verifyEmail({
        variables: {
          code: encryptVerify,
        },
      })
        .then(async ({ data, error }) => {
          if (data.verifyEmail === true) {
            this.setState({
              verifyUser: true,
              login: true,
              signUpOrIn: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    let add = []
    for (var i = 1; i < 13; i++) {
      add.push(i)
      console.log(add)
      this.setState({ monthdata: add })
    }
    const currentYear = new Date().getFullYear()
    console.log(currentYear + 1)
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.closePopup);
  }
  validateInput(type) {
    let self = this;
    let required;
    switch (type) {
      case "signin":
        required = ["email", "password"];
        break;
      case "vendorsignin":
        required = ["email", "password"];
        break;

      case "signup":
        required = ["email", "password", "userName"];
        break;
      case "vendorsignup":
        required = [
          "vendorname",
          "companyname",
          "vendoremail",
          "vendorphone",
          "vendorpassword",
        ];
        break;
      // case "forgotPassword":
      //   required = ["email"];
      //   break;
      default:
        break;
    }
    let error = {},
      flag = false;
    required.forEach((data) => {
      if (!self.state[data] && !self.state[data]) {
        error[data] = `${this.props.t("login._Pleaseentervalid")} ${data === "email"
          ? this.props.t("Editprofile._email")
          : data === "password"
            ? this.props.t("Editprofile._Password")
            : this.props.t("Editprofile._userName")
          }`;
      } else if (data === "email" && !REGEX.test(self.state[data])) {
        error["email"] = this.props.t("login._Invalidemail");
      } else if (data === "password" && self.state[data].length < 4) {
        error["password"] = this.props.t("login._minimum4letters");
      }
      else {
        error[data] = "";
      }
    });

    this.setState({
      errors: error,
    });
    flag = Object.keys(error).find((obj) => {
      if (error[obj]) return true;
      return false;
    });
    return flag;
  }
  componentWillMount() {
    let { siteInfo } = this.props;

    console.log("siteInfo", siteInfo)

    siteInfo.refetch();
    if (siteInfo.getSiteInfo) {
      let {
        name,
        image,
        loginImage,
        appleClientId,
        facebookLogin,
        googleLogin,
        appleLogin,
      } = siteInfo.getSiteInfo;
      this.setState({
        sitename: name,
        image: image,
        loginImage: loginImage,
        appleClientId,
        fblogin: facebookLogin,
        googlelogin: googleLogin,
        applelogin: appleLogin,
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.siteInfo && nextProps.siteInfo.getSiteInfo) {
      let {
        name,
        image,
        loginImage,
        appleClientId,
        facebookLogin,
        googleLogin,
        appleLogin,
      } = nextProps.siteInfo.getSiteInfo;
      this.setState({
        sitename: name,
        image: image,
        loginImage: loginImage,
        appleClientId,
        fblogin: facebookLogin,
        googlelogin: googleLogin,
        applelogin: appleLogin,
      });
    }
  }
  handleSubmit(type) {
    let { signIn, signUp, vendorsignup, vendorlogin } = this.props;
    let { email, password, userName } = this.state;
    let { vendorname, vendorpassword, vendoremail, companyname, vendorphone, cardnumber } = this.state;

    console.log("vendorincome", this.validateInput(type))
    if (!this.validateInput(type)) {


      switch (type) {
        case "signin":
          signIn({
            variables: {
              email: email.toLowerCase(),
              password: password,
            },
            refetchQueries: [{ query: GET_ROSTER, variables: { type: "All" } }],
          })
            .then(async ({ data, error }) => {
              //console.log(data);
              if (!!data) {
                console.log(data.signin.result.userId)
                this.props.redirectHomeFilter({
                  variables: { pageCountFilter: true },
                });
                history.push(`/`);
                this.props.onClick(false, true);
              }
            })
            .catch((error) => {
              console.log(error);
              this.setState({
                // message: "Invalid credientials"
                popUpDetails: error.graphQLErrors.map((x) => x.message),
              });
              this.props.redirectHomeFilter({
                variables: { pageCountFilter: true },
              });
            });
          break;
        case "vendorsignin":
          console.log("vancat")
          // vendorlogin({
          //   variables: {
          //     vendoremail: vendoremail.toLowerCase(),
          //     vendorpassword: vendorpassword,
          //   },
          //   refetchQueries: [{ query: GET_ROSTER, variables: { type: "All" } }],
          // });

          const dataString = {
            "vendoremail": email.toLowerCase(),
            "vendorpassword": password
          }

          axios
            .post(vendorconstant.API_URL + "vendor/login", dataString)
            .then((response) => {
              let data = response.data;
              if (data.status == 'success') {
                console.log(vendorconstant.VENDOR_URL + 'login/' + data.Authtoken)
                window.location.href = vendorconstant.VENDOR_URL + 'login/' + data.Authtoken;
              } else {
                alert(data.message);
                return false;
              }
            })
            .catch((error) => {
              console.log(error);
            });
          break;
        case "vendorsignup":
          const dataString1 = {
            "vendoremail": vendoremail.toLowerCase(),
            "vendorpassword": vendorpassword,
            "vendorname": vendorname,
            "companyname": companyname,
            "vendormobile": vendorphone,
          }

          axios
            .post(vendorconstant.API_URL + "vendor/register", dataString1)
            .then((response) => {
              let data = response.data;
              console.log(this.vendorRegisterid)
              if (data.status == 'success') {
                this.setState({ vendorRegisterid: data.message._id })
                this.setState({ vendorRegistername: data.message.vendorname })
                this.hideComponent("showVendorRegisterForm");
                this.setState({ showAfterVendorRegisterForm: !this.state.showAfterVendorRegisterForm });
              } else {
                alert(data.message);


                return false;
              }
            })
          // vendorsignup({
          //   variables: {
          //     data: {
          //       vendorname: vendorname,
          //       vendoremail: vendoremail.toLowerCase(),
          //       vendorpassword: vendorpassword,
          //       companyname: companyname,
          //     },
          //   },
          // });
          // this.props.onClick(false, true);
          // this.props.redirectHomeFilter({
          //   variables: { pageCountFilter: true },
          // });
          // this.props.redirectHomeFilter({
          //   variables: { pageCountFilter: true },
          // });
          break;
        case "signup":
          if (!this.state.signupcheckbox) {
            this.setState({
              signupcheckboxerror: "Please allow Terms & Conditions.",
            });
            break;
          } else {
            this.setState({
              signupcheckboxerror: "",
            });
          }
          signUp({
            variables: {
              data: {
                email: email.toLowerCase(),
                password: password,
                userName: userName,
              },
            },
          })
            .then(async ({ data }) => {
              if (!!data) {
                Toastr.success(
                  <div className="msgg">
                    <div>
                      <svg
                        viewBox="0 0 24 24"
                        width="32"
                        height="32"
                        style={{ fill: "green" }}
                      >
                        <path d="M21.621,12.166 C21.621,6.953 17.38,2.711 12.166,2.711 C6.952,2.711 2.711,6.953 2.711,12.166 C2.711,17.38 6.952,21.622 12.166,21.622 C17.38,21.622 21.621,17.38 21.621,12.166 M23.332,12.166 C23.332,18.324 18.323,23.333 12.166,23.333 C6.009,23.333 1,18.324 1,12.166 C1,6.009 6.009,1 12.166,1 C18.323,1 23.332,6.009 23.332,12.166 M17.274,8.444 C17.43,8.61 17.512,8.829 17.504,9.058 C17.495,9.287 17.398,9.499 17.23,9.654 L10.507,15.93 C10.348,16.078 10.141,16.159 9.925,16.159 C9.695,16.159 9.48,16.07 9.319,15.909 L7.078,13.667 C6.917,13.507 6.827,13.292 6.827,13.064 C6.826,12.835 6.916,12.619 7.078,12.457 C7.4,12.134 7.965,12.134 8.287,12.457 L9.944,14.114 L16.065,8.402 C16.393,8.094 16.965,8.113 17.274,8.444"></path>
                      </svg>
                    </div>
                    <div>{this.props.t("login._verifyMail")}</div>
                  </div>
                );
                this.props.onClick(false, true);
                this.props.redirectHomeFilter({
                  variables: { pageCountFilter: true },
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.setState({
                //graphqlError: error.graphQLErrors.map(x => x.message)
                popUpDetails: error.graphQLErrors.map((x) => x.message),
              });
              this.props.redirectHomeFilter({
                variables: { pageCountFilter: true },
              });
            });
          break;
        default:
          break;
      }
    }
    else {
      if (type == "vendorsignup") {
        this.setState({ errormessage: this.validateInput(type) + " is Required" })
      }
      else {

      }
    }
  }


  submitAfterVendorRegister = async () => {
    //   let {cardnumber , cvv ,cardmonth,cardyear} = this.state;
    //   console.log("hello" , cardnumber)

    //   const dataString2 = {
    //    "cardname": cardnumber,
    //    "cvv": cvv,
    //    "month" : cardmonth,
    //    "year" : cardyear
    // }
    // console.log(dataString2)


  }

  handleChange(event) {
    let { id, value } = event.target;
    //eslint-disable-next-line
    const REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // console.log(REGEX.test(this.state.email))
    // if (this.state.email) {
    //   let validateemail = REGEX.test(this.state.email);
    //   if (validateemail) {
    //     this.setState({ isButtonDisabled: false });
    //   } else {
    //     this.setState({ isButtonDisabled: true });
    //   }
    // }
    this.setState({
      [id]: value.trimLeft(" "),
    });
  }
  change(event, stateName) {
    this.setState({ [stateName]: event.target.value });
  }
  preventSpace = (event, type) => {
    if (type === "userName") {
      if (
        (event.keyCode >= 47 && event.keyCode <= 64) ||
        (event.keyCode >= 91 && event.keyCode <= 111) ||
        (event.keyCode >= 219 && event.keyCode <= 222) ||
        (event.keyCode >= 190 && event.keyCode <= 192) ||
        (event.keyCode >= 186 && event.keyCode <= 189)
      ) {
        event.preventDefault();
      }
    } else if (type === "email" || type === "password") {
      if (event.keyCode === 32) {
        event.preventDefault();
      }
    }
  };
  responseGoogle = (response) => {

    // console.log("Google Response Deepak", response)
    // if (response.error) {
    //     Toastr.success(<div className="msgg"><div><svg viewBox="0 0 24 24" width="32" height="32" style={{ fill: "red" }}><path d="M11.09,12.167 L7.589,15.669 C7.291,15.966 7.291,16.448 7.589,16.745 C7.886,17.043 8.368,17.043 8.665,16.745 L12.167,13.244 L15.669,16.745 C15.966,17.043 16.448,17.043 16.745,16.745 C17.042,16.448 17.042,15.966 16.745,15.669 L13.243,12.167 L16.745,8.665 C17.042,8.368 17.042,7.886 16.745,7.589 C16.448,7.291 15.966,7.291 15.669,7.589 L12.167,11.09 L8.665,7.589 C8.368,7.291 7.886,7.291 7.589,7.589 C7.291,7.886 7.291,8.368 7.589,8.665 L11.09,12.167 Z M2.711,12.166 C2.711,17.38 6.953,21.622 12.166,21.622 C17.38,21.622 21.621,17.38 21.621,12.166 C21.621,6.952 17.38,2.711 12.166,2.711 C6.953,2.711 2.711,6.952 2.711,12.166 Z M1,12.166 C1,6.009 6.01,1 12.166,1 C18.323,1 23.332,6.009 23.332,12.166 C23.332,18.323 18.323,23.333 12.166,23.333 C6.01,23.333 1,18.323 1,12.166 Z"></path></svg></div><div>Something Went Wrong.Please Wait some Time and Try again later.</div></div>)
    //   this.props.onClick(false, true);
    // }
    if (response.profileObj.googleId) {
      let { socialLogin } = this.props;
      let type = "socialLogin";
      if (type) {
        socialLogin({
          variables: {
            data: {
              email: response.profileObj.email,
              userName: response.profileObj.name,
              googleId: response.profileObj.googleId,
              profileImage: response.profileObj.imageUrl,
            },
          },
          refetchQueries: [{ query: GET_ROSTER, variables: { type: "All" } }],
        })
          .then(async ({ data }) => {
            if (!!data) {
              //console.log("logged successfully");
              this.props.onClick(false, true);
              this.props.redirectHomeFilter({
                variables: { pageCountFilter: true },
              });
            }
          })
          .catch((error) => {
            var message = error.graphQLErrors.map((x) => x.message);
            Toastr.success(
              <div className="msgg">
                <div>
                  <svg
                    viewBox="0 0 24 24"
                    width="32"
                    height="32"
                    style={{ fill: "red" }}
                  >
                    <path d="M11.09,12.167 L7.589,15.669 C7.291,15.966 7.291,16.448 7.589,16.745 C7.886,17.043 8.368,17.043 8.665,16.745 L12.167,13.244 L15.669,16.745 C15.966,17.043 16.448,17.043 16.745,16.745 C17.042,16.448 17.042,15.966 16.745,15.669 L13.243,12.167 L16.745,8.665 C17.042,8.368 17.042,7.886 16.745,7.589 C16.448,7.291 15.966,7.291 15.669,7.589 L12.167,11.09 L8.665,7.589 C8.368,7.291 7.886,7.291 7.589,7.589 C7.291,7.886 7.291,8.368 7.589,8.665 L11.09,12.167 Z M2.711,12.166 C2.711,17.38 6.953,21.622 12.166,21.622 C17.38,21.622 21.621,17.38 21.621,12.166 C21.621,6.952 17.38,2.711 12.166,2.711 C6.953,2.711 2.711,6.952 2.711,12.166 Z M1,12.166 C1,6.009 6.01,1 12.166,1 C18.323,1 23.332,6.009 23.332,12.166 C23.332,18.323 18.323,23.333 12.166,23.333 C6.01,23.333 1,18.323 1,12.166 Z"></path>
                  </svg>
                </div>
                <div>{message}</div>
              </div>
            );
            this.props.onClick(false, true);
            this.props.redirectHomeFilter({
              variables: { pageCountFilter: true },
            });
          });
      }
    }
  };

  viewPassword = (e) => {
    e.target.classList.toggle("showw");
    let a = document.getElementById("password");
    var b = a.getAttribute("type");
    b === "password"
      ? a.setAttribute("type", "text")
      : a.setAttribute("type", "password");
  };

  componentClicked = () => console.log("Got response");

  closeModel = () => {
    this.setState({
      emailPopup: false,
      modelState: false,
    });
  };

  updateEmailValue = (evt) => {
    var emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let { errors } = this.state;
    if (!emailRex.test(evt.target.value)) {
      this.setState({
        isButtonDisabled: true,
      });
      errors.popEmail = this.props.t("Editprofile._emailValid");
    } else {
      errors.popEmail = "";
      this.setState({
        isButtonDisabled: false,
        inputValue: evt.target.value,
      });
    }
  };

  updateSocialLogin = (value) => {
    let { socialLogin } = this.props;
    let { finalObject } = this.state;
    let type = "socialLogin";
    if (type) {
      socialLogin({
        variables: {
          data: {
            email: value,
            userName: finalObject.userName,
            faceBookId: finalObject.faceBookId,
            profileImage: `https://graph.facebook.com/${finalObject.faceBookId}/picture`,
          },
        },
        refetchQueries: [{ query: GET_ROSTER, variables: { type: "All" } }],
      })
        .then(async ({ data }) => {
          this.props.onClick(false, true);
          this.props.redirectHomeFilter({
            variables: { pageCountFilter: true },
          });
        })
        .catch((error) => {
          console.log(error);
          var message = error.graphQLErrors.map((x) => x.message);
          Toastr.success(
            <div className="msgg">
              <div>
                <svg
                  viewBox="0 0 24 24"
                  width="32"
                  height="32"
                  style={{ fill: "red" }}
                >
                  <path d="M11.09,12.167 L7.589,15.669 C7.291,15.966 7.291,16.448 7.589,16.745 C7.886,17.043 8.368,17.043 8.665,16.745 L12.167,13.244 L15.669,16.745 C15.966,17.043 16.448,17.043 16.745,16.745 C17.042,16.448 17.042,15.966 16.745,15.669 L13.243,12.167 L16.745,8.665 C17.042,8.368 17.042,7.886 16.745,7.589 C16.448,7.291 15.966,7.291 15.669,7.589 L12.167,11.09 L8.665,7.589 C8.368,7.291 7.886,7.291 7.589,7.589 C7.291,7.886 7.291,8.368 7.589,8.665 L11.09,12.167 Z M2.711,12.166 C2.711,17.38 6.953,21.622 12.166,21.622 C17.38,21.622 21.621,17.38 21.621,12.166 C21.621,6.952 17.38,2.711 12.166,2.711 C6.953,2.711 2.711,6.952 2.711,12.166 Z M1,12.166 C1,6.009 6.01,1 12.166,1 C18.323,1 23.332,6.009 23.332,12.166 C23.332,18.323 18.323,23.333 12.166,23.333 C6.01,23.333 1,18.323 1,12.166 Z"></path>
                </svg>
              </div>
              <div>{message}</div>
            </div>
          );
          this.props.onClick(false, true);
          this.props.redirectHomeFilter({
            variables: { pageCountFilter: true },
          });
        });
    }
  };
  responseFacebook = (response) => {
    if (response.userID) {
      let { socialLogin } = this.props;
      let type = "socialLogin";
      if (type) {
        socialLogin({
          variables: {
            data: {
              email: response.email,
              userName: response.name,
              faceBookId: response.userID,
              profileImage: `https://graph.facebook.com/${response.userID}/picture`,
            },
          },
          refetchQueries: [{ query: GET_ROSTER, variables: { type: "All" } }],
        })
          .then(async ({ data }) => {
            if (data.socialLogin.noEmail === true) {
              let userdata = {};
              userdata.userName = response.name;
              userdata.faceBookId = response.userID;
              userdata.profileImage = response.picture.data.url;
              this.setState({
                emailPopup: true,
                modelState: true,
                noEmail: data.socialLogin.noEmail,
                finalObject: userdata,
              });
            } else if (!!data) {
              this.props.onClick(false, true);
              this.props.redirectHomeFilter({
                variables: { pageCountFilter: true },
              });
            }
          })
          .catch((error) => {
            console.log(error);
            var message = error.graphQLErrors.map((x) => x.message);
            Toastr.success(
              <div className="msgg">
                <div>
                  <svg
                    viewBox="0 0 24 24"
                    width="32"
                    height="32"
                    style={{ fill: "red" }}
                  >
                    <path d="M11.09,12.167 L7.589,15.669 C7.291,15.966 7.291,16.448 7.589,16.745 C7.886,17.043 8.368,17.043 8.665,16.745 L12.167,13.244 L15.669,16.745 C15.966,17.043 16.448,17.043 16.745,16.745 C17.042,16.448 17.042,15.966 16.745,15.669 L13.243,12.167 L16.745,8.665 C17.042,8.368 17.042,7.886 16.745,7.589 C16.448,7.291 15.966,7.291 15.669,7.589 L12.167,11.09 L8.665,7.589 C8.368,7.291 7.886,7.291 7.589,7.589 C7.291,7.886 7.291,8.368 7.589,8.665 L11.09,12.167 Z M2.711,12.166 C2.711,17.38 6.953,21.622 12.166,21.622 C17.38,21.622 21.621,17.38 21.621,12.166 C21.621,6.952 17.38,2.711 12.166,2.711 C6.953,2.711 2.711,6.952 2.711,12.166 Z M1,12.166 C1,6.009 6.01,1 12.166,1 C18.323,1 23.332,6.009 23.332,12.166 C23.332,18.323 18.323,23.333 12.166,23.333 C6.01,23.333 1,18.323 1,12.166 Z"></path>
                  </svg>
                </div>
                <div>{message}</div>
              </div>
            );
            this.props.onClick(false, true);
            this.props.redirectHomeFilter({
              variables: { pageCountFilter: true },
            });
          });
      }
    }
  };

  /*   responseFacebook = (response) => {
    console.log(response);
    }
    
    componentClicked = (data) => {
    console.log(data)
    }  */

  handleForgetPasswordSubmit = (e) => {
    let { forgotPassword } = this.props;
    let { email, errors } = this.state;
    if (REGEX.test(email)) {
      this.setState({
        errors: {},
        isButtonDisabled: true,
      });
      forgotPassword({
        variables: {
          email: email.toLowerCase(),
        },
      })
        .then(async ({ data }) => {
          if (data) {
            Toastr.success(
              <div className="msgg">
                <div>
                  <svg
                    viewBox="0 0 24 24"
                    width="32"
                    height="32"
                    style={{ fill: "green" }}
                  >
                    <path d="M21.621,12.166 C21.621,6.953 17.38,2.711 12.166,2.711 C6.952,2.711 2.711,6.953 2.711,12.166 C2.711,17.38 6.952,21.622 12.166,21.622 C17.38,21.622 21.621,17.38 21.621,12.166 M23.332,12.166 C23.332,18.324 18.323,23.333 12.166,23.333 C6.009,23.333 1,18.324 1,12.166 C1,6.009 6.009,1 12.166,1 C18.323,1 23.332,6.009 23.332,12.166 M17.274,8.444 C17.43,8.61 17.512,8.829 17.504,9.058 C17.495,9.287 17.398,9.499 17.23,9.654 L10.507,15.93 C10.348,16.078 10.141,16.159 9.925,16.159 C9.695,16.159 9.48,16.07 9.319,15.909 L7.078,13.667 C6.917,13.507 6.827,13.292 6.827,13.064 C6.826,12.835 6.916,12.619 7.078,12.457 C7.4,12.134 7.965,12.134 8.287,12.457 L9.944,14.114 L16.065,8.402 C16.393,8.094 16.965,8.113 17.274,8.444"></path>
                  </svg>
                </div>
                <div>
                  {this.props.t("login._sentanemail")} {email}.{" "}
                  {this.props.t("login._Clickthelink")}
                </div>
              </div>
            );
            this.setState({
              email: "",
              errors: {},
              isButtonDisabled: false,
            });
            this.props.onClick(false, true);
          }
        })
        .catch((error) => {
          Toastr.success(
            <div className="msgg">
              <div>
                <svg
                  viewBox="0 0 24 24"
                  width="32"
                  height="32"
                  style={{ fill: "red" }}
                >
                  <path d="M11.09,12.167 L7.589,15.669 C7.291,15.966 7.291,16.448 7.589,16.745 C7.886,17.043 8.368,17.043 8.665,16.745 L12.167,13.244 L15.669,16.745 C15.966,17.043 16.448,17.043 16.745,16.745 C17.042,16.448 17.042,15.966 16.745,15.669 L13.243,12.167 L16.745,8.665 C17.042,8.368 17.042,7.886 16.745,7.589 C16.448,7.291 15.966,7.291 15.669,7.589 L12.167,11.09 L8.665,7.589 C8.368,7.291 7.886,7.291 7.589,7.589 C7.291,7.886 7.291,8.368 7.589,8.665 L11.09,12.167 Z M2.711,12.166 C2.711,17.38 6.953,21.622 12.166,21.622 C17.38,21.622 21.621,17.38 21.621,12.166 C21.621,6.952 17.38,2.711 12.166,2.711 C6.953,2.711 2.711,6.952 2.711,12.166 Z M1,12.166 C1,6.009 6.01,1 12.166,1 C18.323,1 23.332,6.009 23.332,12.166 C23.332,18.323 18.323,23.333 12.166,23.333 C6.01,23.333 1,18.323 1,12.166 Z"></path>
                </svg>
              </div>
              <div> {this.props.t("login._ForgetPasswordError")}</div>
            </div>
          );
        });
    } else {
      let error = {};
      error["email"] = this.props.t("login._Invalidemail");
      this.setState({
        errors: error,
        isButtonDisabled: true,
      });
    }
  };

  handlePaste(event) {
    event.preventDefault();
    this.setState({
      isButtonDisabled: false,
      email: event.clipboardData.getData("text"),
    });
  }

  forgetPasswordChange(event) {
    let { id, value } = event.target;
    this.setState({
      isButtonDisabled: false,
      [id]: value,
    });
  }
  render() {
    const { showUserRegisterForm, showVendorRegisterForm, showLoginForm, vendoruserlogin } =
      this.state;
    let {
      vendorname,
      vendoremail,
      vendorphone,
      vendorpassword,
      companyname,
      cardnumber,
      cvv,
      cardyear,
      vendorRegisterid,
      vendorRegistername,
      cardmonth,
      vendorsignup,
      login,
      signUp,
      signUpOrIn,
      errors,
      email,
      password,
      userName,
      loginImage,
      message,
      popUpDetails,
      sitename,
      appleClientId,
      isButtonDisabled,
      image,
      googlelogin,
      fblogin,
      applelogin,
    } = this.state;
    let { classes, siteInfo, t } = this.props;

    let fbContent;
    let $imagePreview = loginImage;
    return (
      <>
        <LoginPopup
          isOpen={true}
          contentLabel="Example Modal"
          className="loginModalboot"
        >
          <div className="loginModalbootouter">
            <div className="loginPopup">
              <div
                className="resnoban"
                style={{
                  backgroundImage:
                    "linear-gradient(to right, #0f6aff, #f902e3)",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: "grey",
                  backgroundSize: "cover",
                  width: "45%",
                }}
              >
                <div className="close-Id">
                  <Button
                    justIcon
                    round
                    simple
                    onClick={(e) => this.props.onClick(false)}
                    color="twitter"
                    className="edit"
                  >
                    <Close />
                  </Button>
                </div>
                <div>
                  <div>
                    <div className="loginpo">
                      <h2 className="unlock-poup">
                        {t("login._unlockfeatures")}
                      </h2>
                      <div>
                        <div className="tJBQs">
                          {" "}
                          <div className="iconsvg">
                            {" "}
                            <svg
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              class=" fznnpf"
                            >
                              <path d="M6.892 5.571A3.468 3.468 0 0 1 10.262 3h3.477a3.473 3.473 0 0 1 3.369 2.571h1.416c1.92 0 3.476 1.536 3.476 3.43v8.57C22 19.464 20.442 21 18.524 21H5.476C3.556 21 2 19.465 2 17.571v-8.57c0-1.893 1.558-3.43 3.476-3.43h1.416zm9.456 7.715C16.348 10.919 14.4 9 12 9s-4.348 1.919-4.348 4.286c0 2.367 1.947 4.285 4.348 4.285s4.348-1.918 4.348-4.285zm-6.957 0c0-1.42 1.168-2.572 2.609-2.572 1.44 0 2.609 1.152 2.609 2.572 0 1.42-1.168 2.571-2.609 2.571-1.44 0-2.609-1.151-2.609-2.571z"></path>
                            </svg>
                          </div>
                          <div className="langsel">
                            {t("login._Postlistings")}
                          </div>
                        </div>
                        <div className="tJBQs">
                          {" "}
                          <div className="iconsvg">
                            <svg
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              class="sc-jTzLTM fznnpf"
                            >
                              <path d="M7.249 21.204v-1.902c0-.58-.47-1.05-1.05-1.05A4.2 4.2 0 0 1 2 14.053v-5.86A4.194 4.194 0 0 1 6.193 4h11.734a4.193 4.193 0 0 1 4.193 4.193v5.866a4.193 4.193 0 0 1-4.193 4.193h-5.013c-.444 0-.87.177-1.185.49l-3.05 3.048c-.525.526-1.424.158-1.43-.586zm.617-8.828a1.255 1.255 0 1 0 0-2.512 1.256 1.256 0 1 0 0 2.512zm8.383 0a1.255 1.255 0 1 0 0-2.512 1.256 1.256 0 1 0 0 2.512zm-4.191 0a1.255 1.255 0 1 0 0-2.512 1.256 1.256 0 1 0 0 2.512z"></path>
                            </svg>
                          </div>
                          <div className="langsel">
                            {t("login._Chatbuyers")}
                          </div>
                        </div>
                        <div className="tJBQs">
                          {" "}
                          <div className="iconsvg">
                            {" "}
                            <svg
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              class="sc-jTzLTM fznnpf"
                            >
                              <path d="M16.224 5c-1.504 0-2.89.676-3.802 1.854L12 7.398l-.421-.544A4.772 4.772 0 0 0 7.776 5C5.143 5 3 7.106 3 9.695c0 5.282 6.47 11.125 9.011 11.125 2.542 0 8.99-5.445 8.99-11.125C21 7.105 18.857 5 16.223 5z"></path>
                            </svg>
                          </div>
                          <div className="langsel">
                            {t("login._Savelistings")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {signUpOrIn ? (
                <div
                  className={`loginRight ${fblogin === true ||
                    googlelogin === true ||
                    applelogin === true
                    ? "nn_active"
                    : "nn_noactive"
                    }`}
                >
                  <div className={classes.loginContent + " "}>
                    <CardBody>
                      <div>
                        <div>
                          {login ? (
                            <div>
                              <div className="dtgBrv">
                                <div
                                  className="fvALSw auth-hedaing"
                                  onClick={() => {
                                    this.setState({
                                      signUpOrIn: false,
                                      login: false,
                                      email: "",
                                      password: "",
                                      errors: "",
                                      popUpDetails: "",
                                    });
                                  }}
                                >
                                  <span>
                                    <button
                                      onClick={() => {
                                        this.handleRegisterShow();
                                      }}
                                    >
                                      <svg
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        class="Authstyles__BackIconBlack-sc-1tlvt1v-8 hFnSvT sc-jTzLTM fznnpf"
                                      >
                                        <path d="M7.513 13.353l3.73 3.863a1.403 1.403 0 0 1-2.016 1.948l-6.082-6.298a1.39 1.39 0 0 1-.393-.998c.006-.359.149-.715.428-.985l6.298-6.082a1.402 1.402 0 0 1 1.948 2.017L7.562 10.55l12.309.215a1.402 1.402 0 1 1-.048 2.804l-12.31-.215z"></path>
                                      </svg>{" "}
                                      {"  "}
                                    </button>
                                  </span>
                                  <span className="diFwKh login-heading">
                                    <div className="section-tittle ">
                                      <h2 className="tittle ">
                                        <span class="shape"></span>
                                        {t("login.Login")}{" "}
                                      </h2>
                                    </div>
                                  </span>
                                </div>
                              </div>

                              {this.state.showRegisterIcon && (
                                <div align="center" className="registerants">
                                  <button
                                    className="loginasvendor"
                                    onClick={() => {
                                      this.hideComponent("showLoginForm");
                                      this.handleRegisterHide();
                                      this.setState({ vendoruserlogin: "signin" })
                                    }}
                                  >
                                    <img src={user} alt="user" />
                                    <div className="titles-vendor">
                                      {t("login._LogAsUser")}
                                    </div>
                                  </button>

                                  <div className="bGYxuY">
                                    <h3
                                      className={
                                        classes.continueWith + " " + classes.or
                                      }
                                    >
                                      <span className="dash">
                                        {" "}
                                        <span> {t("login._USEOR")}</span>
                                      </span>
                                    </h3>
                                  </div>
                                  <button
                                    className="loginasvendor"
                                    onClick={() => {
                                      this.handleRegisterHide();
                                      this.hideComponent("showVendorLoginForm");
                                      this.setState({ vendoruserlogin: "vendorsignin" })

                                    }}
                                  >
                                    <img src={Vendor} alt="vendor" />
                                    <div className="titles-vendor">
                                      {t("login._LogAsVen")}
                                    </div>
                                  </button>
                                </div>
                              )}

                              {showLoginForm && (
                                <div>
                                  <div className="textrightrtl form-control-input login-input">
                                    <div className="mbing-bottm">
                                      <span className="colorlgbn label">
                                        Email
                                        <span className="validatcolor">
                                          *
                                        </span>{" "}
                                      </span>
                                      <CustomInput
                                        customClass="inputpaddingremove"
                                        id="email"
                                        error={!!errors.email}
                                        success={!!errors.email}
                                        helpText={errors.email}
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        inputProps={{
                                          value: email,
                                          type: "email",
                                          onChange: this.handleChange,
                                          onKeyDown: (event) =>
                                            this.preventSpace(event, "email"),
                                          autoComplete: "email",
                                          //autoComplete: "off"
                                          className: "nn_email",
                                        }}
                                      />
                                    </div>
                                    <div className="pos_rel viewPsw mbing-bottm">
                                      <span className="colorlgbn label">
                                        Password
                                        <span class="validatcolor">*</span>{" "}
                                      </span>
                                      <CustomInput
                                        customClass="inputpaddingremove"
                                        id="password"
                                        error={!!errors.password}
                                        success={!!errors.password}
                                        helpText={errors.password}
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        inputProps={{
                                          value: password,
                                          type: "password",
                                          onChange: this.handleChange,
                                          onKeyDown: (event) =>
                                            this.preventSpace(
                                              event,
                                              "password"
                                            ),
                                          autoComplete: "new-password",
                                          //autoComplete: "off"
                                          className: "nn_password",
                                        }}
                                      />

                                      <button
                                        onClick={(e) => this.viewPassword(e)}
                                      >
                                        <svg
                                          viewBox="0 0 24 24"
                                          width="24"
                                          height="24"
                                        >
                                          <path d="M12.186 8.106a3.733 3.733 0 0 1 3.727 3.728 3.733 3.733 0 0 1-3.727 3.728 3.733 3.733 0 0 1-3.727-3.728 3.733 3.733 0 0 1 3.727-3.728zm0 5.966a2.24 2.24 0 0 0 2.236-2.238 2.237 2.237 0 0 0-4.472 0 2.24 2.24 0 0 0 2.236 2.238zm9.67-2.802c-3.113-4.254-6.476-6.379-9.997-6.266-5.697.165-9.577 6.05-9.74 6.3a.752.752 0 0 0 .02.84c3.152 4.424 6.491 6.661 9.928 6.661.122 0 .244-.004.365-.009 5.645-.258 9.313-6.445 9.466-6.708a.75.75 0 0 0-.042-.818"></path>
                                        </svg>
                                      </button>
                                    </div>
                                  </div>

                                  <div className="logtn btn-wrapper">
                                    <Button
                                      onClick={(e) =>
                                        this.handleSubmit(vendoruserlogin)
                                      }
                                      type="submit"
                                      simple
                                      size="sm"
                                    >
                                      <span className="cmn-btn-auth">
                                        {" "}
                                        {t("login.Login")}{" "}
                                      </span>
                                    </Button>

                                    {error && <p> {error}</p>}
                                    <div className="socialLoginouter d-flex justify-content-center" >
                                      <GoogleLogin
                                        clientId={
                                          siteInfo.getSiteInfo.googleAppId
                                        }
                                        render={(renderProps) => (
                                          <div className="google login-google">
                                            <a onClick={renderProps.onClick}>
                                              <img
                                                src={google}
                                                alt=""
                                                height="30px"
                                              />
                                            </a>
                                          </div>
                                        )}
                                        onSuccess={this.responseGoogle}
                                        onFailure={this.responseGoogle}
                                        cookiePolicy={"single_host_origin"}
                                      />

                                      <FacebookLogin
                                        appId={siteInfo.getSiteInfo.facebookAppId}
                                        fields="name,email,picture"
                                        render={renderProps => (
                                          <div className="facebook login-facebook">
                                            <a onClick={renderProps.onClick}>
                                              <img
                                                src={facebook}
                                                onClick={renderProps.onClick}
                                                height="30px"
                                              />
                                            </a>
                                          </div>
                                        )}
                                        onClick={this.componentClicked}
                                        callback={this.responseFacebook}
                                        disableMobileRedirect={true}
                                        isMobile={false}
                                      />

                                    </div>
                                    <Button
                                      type="submit"
                                      onClick={() => {
                                        this.setState({
                                          signUp: true,
                                          login: false,
                                          email: "",
                                          popUpDetails: "",
                                          errors: "",
                                        });
                                      }}
                                      style={{ color: "rgb(254, 58, 86)" }}
                                      simple
                                      size="sm"
                                    >
                                      {" "}
                                      <span className="fogpg forgot-pass">
                                        {t("login._ForgotPassword")}
                                      </span>
                                    </Button>
                                    <br />

                                    <Button
                                      type="submit"
                                      onClick={() => {
                                        this.setState({
                                          login: false,
                                          signUpOrIn: true,
                                          signUp: false,
                                          popUpDetails: "",
                                          email: "",
                                          password: "",
                                          errors: "",
                                        });
                                      }}
                                      style={{ color: "rgb(254, 58, 86)" }}
                                      simple
                                      size="sm"
                                    >
                                      <span className="alredy">
                                        {t("login._DontAccount")}
                                      </span>
                                    </Button>
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : signUp ? (
                            <div>
                              <div className="dtgBrv">
                                <div
                                  className="fvALSw auth-hedaing"
                                  onClick={() => {
                                    this.setState({
                                      login: true,
                                      email: "",
                                      password: "",
                                      errors: "",
                                      popUpDetails: "",
                                    });
                                  }}
                                >
                                  <span>
                                    <button
                                      onClick={() => {
                                        this.handleRegisterShow();
                                        this.hideComponent(
                                          "showVendorRegisterForm"
                                        );
                                      }}
                                    >
                                      <svg
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        class="Authstyles__BackIconBlack-sc-1tlvt1v-8 hFnSvT sc-jTzLTM fznnpf"
                                      >
                                        <path d="M7.513 13.353l3.73 3.863a1.403 1.403 0 0 1-2.016 1.948l-6.082-6.298a1.39 1.39 0 0 1-.393-.998c.006-.359.149-.715.428-.985l6.298-6.082a1.402 1.402 0 0 1 1.948 2.017L7.562 10.55l12.309.215a1.402 1.402 0 1 1-.048 2.804l-12.31-.215z"></path>
                                      </svg>{" "}
                                    </button>
                                  </span>
                                  <span className="diFwKh login-heading">
                                    <div className="section-tittle ">
                                      <h2 className="tittle ">
                                        <span class="shape"></span>
                                        {t("login._Resetpassword")}{" "}
                                      </h2>
                                    </div>
                                  </span>
                                </div>
                                <div className="fvALSw">
                                  <span>
                                    {/* <Link onClick={() => this.props.onClick(false)} to="/pages/terms_of_use"> Help </Link> */}
                                  </span>
                                </div>
                              </div>

                              <div className="">
                                <span className="restpss">
                                  {" "}
                                  <div className="forgot-pass">
                                    <p>{t("login._resetpassword")}</p>
                                  </div>
                                </span>
                                <div className="textrightrtl login-input form-control-input">
                                  <span className="colorlgbn label">
                                    Email
                                    <span class="validatcolor">*</span>{" "}
                                  </span>
                                  <CustomInput
                                    customClass="inputpaddingremove"
                                    id="email"
                                    error={!!errors.email}
                                    success={!!errors.email}
                                    helpText={errors.email}
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      value: email,
                                      type: "email",
                                      onChange: this.forgetPasswordChange,
                                      onPaste: (e) => this.handlePaste,
                                      onKeyDown: (event) =>
                                        this.preventSpace(event, "email"),
                                      autoComplete: "off",
                                      className: "nn_email",
                                    }}
                                  />{" "}
                                </div>

                                <div className="emsilsend">
                                  <p> {message}</p>
                                </div>
                                <Button
                                  className="btn-wrapper forgot-button"
                                  disabled={this.state.isButtonDisabled}
                                  type="submit"
                                  simple
                                  size="sm"
                                  onClick={(e) =>
                                    this.handleForgetPasswordSubmit(e)
                                  }
                                >
                                  <span className="cmn-btn-auth">
                                    {" "}
                                    {t("login._SendEmail")}{" "}
                                  </span>
                                </Button>
                              </div>
                            </div>
                          ) : !login ? (
                            <div>
                              <div className="dtgBrv">
                                <div
                                  className="fvALSw auth-hedaing"
                                  onClick={() => {
                                    this.setState({
                                      signUpOrIn: false,
                                      login: false,
                                      email: "",
                                      password: "",
                                      userName: "",
                                      errors: "",
                                      popUpDetails: "",
                                    });
                                  }}
                                >
                                  <span>
                                    <svg
                                      viewBox="0 0 24 24"
                                      width="24"
                                      height="24"
                                      class="Authstyles__BackIconBlack-sc-1tlvt1v-8 hFnSvT sc-jTzLTM fznnpf"
                                    >
                                      <path d="M7.513 13.353l3.73 3.863a1.403 1.403 0 0 1-2.016 1.948l-6.082-6.298a1.39 1.39 0 0 1-.393-.998c.006-.359.149-.715.428-.985l6.298-6.082a1.402 1.402 0 0 1 1.948 2.017L7.562 10.55l12.309.215a1.402 1.402 0 1 1-.048 2.804l-12.31-.215z"></path>
                                    </svg>{" "}
                                  </span>
                                  <span className="diFwKh login-heading">
                                    <div className="section-tittle ">
                                      <h2 className="tittle ">
                                        <span class="shape"></span>
                                        {t("login._newaccount")}
                                      </h2>
                                    </div>
                                  </span>
                                </div>
                                <div className="fvALSw">
                                  <span></span>
                                </div>
                              </div>

                              {showUserRegisterForm && (
                                <div>
                                  <div className="noruse"> {popUpDetails}</div>

                                  <div className="textrightrtl form-control-input login-input">
                                    <div className="mbing-bottm">
                                      <span className="colorlgbn label">
                                        Email
                                        <span class="validatcolor">*</span>{" "}
                                      </span>
                                      <CustomInput
                                        customClass="inputpaddingremove"
                                        id="email"
                                        error={!!errors.email}
                                        success={!!errors.email}
                                        helpText={errors.email}
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        inputProps={{
                                          value: email,
                                          type: "email",
                                          onChange: this.handleChange,
                                          autoComplete: "email",
                                          //autoComplete: "off"
                                          className: "nn_email",
                                        }}
                                      />
                                    </div>
                                    <div className="pos_rel viewPsw mbing-bottm">
                                      <span className="colorlgbn label">
                                        Password
                                        <span class="validatcolor">*</span>{" "}
                                      </span>
                                      <CustomInput
                                        customClass="inputpaddingremove"
                                        id="password"
                                        error={!!errors.password}
                                        success={!!errors.password}
                                        helpText={errors.password}
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        inputProps={{
                                          value: password,
                                          type: "password",
                                          onChange: this.handleChange,
                                          onKeyDown: (event) =>
                                            this.preventSpace(
                                              event,
                                              "password"
                                            ),
                                          autoComplete: "new-password",
                                          //autoComplete: "off"
                                          className: "nn_password",
                                        }}
                                      />

                                      <button
                                        onClick={(e) => this.viewPassword(e)}
                                      >
                                        <svg
                                          viewBox="0 0 24 24"
                                          width="24"
                                          height="24"
                                        >
                                          <path d="M12.186 8.106a3.733 3.733 0 0 1 3.727 3.728 3.733 3.733 0 0 1-3.727 3.728 3.733 3.733 0 0 1-3.727-3.728 3.733 3.733 0 0 1 3.727-3.728zm0 5.966a2.24 2.24 0 0 0 2.236-2.238 2.237 2.237 0 0 0-4.472 0 2.24 2.24 0 0 0 2.236 2.238zm9.67-2.802c-3.113-4.254-6.476-6.379-9.997-6.266-5.697.165-9.577 6.05-9.74 6.3a.752.752 0 0 0 .02.84c3.152 4.424 6.491 6.661 9.928 6.661.122 0 .244-.004.365-.009 5.645-.258 9.313-6.445 9.466-6.708a.75.75 0 0 0-.042-.818"></path>
                                        </svg>
                                      </button>
                                    </div>
                                    <div className="mbing-bottm">
                                      <span className="colorlgbn label">
                                        FullName
                                        <span class="validatcolor">*</span>{" "}
                                      </span>
                                      <CustomInput
                                        customClass="inputpaddingremove"
                                        id="userName"
                                        error={!!errors.userName}
                                        success={!!errors.userName}
                                        helpText={errors.userName}
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        inputProps={{
                                          value: userName,
                                          onChange: this.handleChange,
                                          onKeyDown: (event) =>
                                            this.preventSpace(
                                              event,
                                              "userName"
                                            ),
                                          //autoComplete: "off",
                                          autoComplete: "new-password",
                                          className: "nn_fullmame",
                                        }}
                                      />
                                    </div>

                                    <div className={classes.loginBottomLinks}>
                                      <p className="footer-trms text-left checkWrap2  ">
                                        <Checkbox
                                          color="primary"
                                          id="accept"
                                          className="checkbox_box"
                                          checked={this.state.signupcheckbox}
                                          onChange={this.signupcheckboxhandle}
                                        />
                                        {this.state.signupcheckboxerror && (
                                          <p style={{ color: "red" }}>
                                            {this.state.signupcheckboxerror}
                                          </p>
                                        )}
                                        {t("login._clickingon")} {sitename}
                                        <strong>
                                          <Link
                                            onClick={() =>
                                              this.props.onClick(false)
                                            }
                                            to="/pages/terms_and_conditions"
                                          >
                                            {" "}
                                            {t("footer._termsandConditions")}
                                          </Link>
                                        </strong>{" "}
                                        {t("login._and")}
                                        <strong>
                                          <Link
                                            onClick={() =>
                                              this.props.onClick(false)
                                            }
                                            to="/pages/privacy_policy"
                                          >
                                            {t("footer._privacypolicy")}
                                          </Link>
                                        </strong>
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    align="center"
                                    className="login-submit-input"
                                  >
                                    <Button
                                      className="btn-wrapper"
                                      onClick={(e) =>
                                        this.handleSubmit("signup")
                                      }
                                      type="submit"
                                      style={{
                                        color: "rgb(254, 58, 86)",
                                        padding: "0",
                                      }}
                                      simple
                                      size="sm"

                                    >
                                      <span className="cmn-btn-auth">
                                        {t("login._SignUp")}
                                      </span>
                                    </Button>
                                    <div className="other-account">
                                      <GoogleLogin
                                        clientId={
                                          siteInfo.getSiteInfo.googleAppId
                                        }
                                        render={(renderProps) => (
                                          <a onClick={renderProps.onClick}>
                                            <img
                                              src={google}
                                              alt="google"
                                              height="30px"
                                            />
                                          </a>
                                        )}
                                        onSuccess={this.responseGoogle}
                                        onFailure={this.responseGoogle}
                                        cookiePolicy={"single_host_origin"}
                                      />

                                      <FacebookLogin
                                        appId={siteInfo.getSiteInfo.facebookAppId}
                                        fields="name,email,picture"
                                        render={renderProps => (
                                          <a onClick={renderProps.onClick}>
                                            <img
                                              src={facebook}
                                              onClick={renderProps.onClick}
                                              height="30px"
                                            />
                                          </a>
                                        )}
                                        onClick={this.componentClicked}
                                        callback={this.responseFacebook}
                                        disableMobileRedirect={true}
                                        isMobile={false}
                                      />


                                    </div>

                                    <Button
                                      type="submit"
                                      onClick={() => {
                                        this.setState({
                                          login: false,
                                          signUpOrIn: false,
                                          signUp: true,
                                          popUpDetails: "",
                                          email: "",
                                          password: "",
                                          errors: "",
                                        });
                                      }}
                                      style={{ color: "rgb(254, 58, 86)" }}
                                      simple
                                      size="sm"
                                    >
                                      <span className="alredy">
                                        {t("login._AlreadyAccount")}
                                      </span>
                                    </Button>
                                  </div>
                                </div>
                              )}

                              {showVendorRegisterForm && (
                                <div>
                                  <div className="noruse">{popUpDetails}</div>
                                  {this.state.errormessage != "" ?
                                    <div class="alert alert-danger" role="alert">
                                      {this.state.errormessage}
                                    </div> : ""}
                                  <div className="textrightrtl form-control-input login-input">
                                    <div className="mbing-bottm">
                                      <span className="colorlgbn label">
                                        Name
                                        <span class="validatcolor">*</span>{" "}
                                      </span>
                                      <CustomInput
                                        customClass="inputpaddingremove"
                                        id="vendorname"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        inputProps={{
                                          value: vendorname,
                                          onChange: this.handleChange,
                                          onKeyDown: (event) =>
                                            this.preventSpace(
                                              event,
                                              "vendorname"
                                            ),
                                          //autoComplete: "off",
                                          autoComplete: "new-password",
                                          className: "nn_fullmame",
                                        }}
                                      />

                                    </div>
                                    <div className="mbing-bottm">
                                      <span className="colorlgbn label">
                                        Company Name
                                        <span class="validatcolor">*</span>{" "}
                                      </span>
                                      <CustomInput
                                        customClass="inputpaddingremove"
                                        id="companyname"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        inputProps={{
                                          value: companyname,
                                          onChange: this.handleChange,
                                          onKeyDown: (event) =>
                                            this.preventSpace(
                                              event,
                                              "companyname"
                                            ),
                                          //autoComplete: "off",
                                          autoComplete: "new-password",
                                          className: "nn_fullmame",
                                        }}
                                      />
                                    </div>
                                    <div className="mbing-bottm">
                                      <span className="colorlgbn label">
                                        Email
                                        <span class="validatcolor">*</span>{" "}
                                      </span>
                                      <CustomInput
                                        customClass="inputpaddingremove"
                                        id="vendoremail"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        inputProps={{
                                          value: vendoremail,
                                          type: "email",
                                          onChange: this.handleChange,
                                          autoComplete: "email",
                                          //autoComplete: "off"
                                          className: "nn_email",
                                        }}
                                      />
                                    </div>
                                    <div className="mbing-bottm">
                                      <span className="colorlgbn label">
                                        Mobile Number
                                        <span class="validatcolor">*</span>{" "}
                                      </span>
                                      <CustomInput
                                        customClass="inputpaddingremove"
                                        id="vendorphone"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        inputProps={{
                                          value: vendorphone,
                                          type: "number",
                                          onChange: this.handleChange,
                                          autoComplete: "vendorphone",
                                          //autoComplete: "off"
                                          className: "nn_email",
                                        }}
                                      />
                                    </div>
                                    <div className="pos_rel viewPsw mbing-bottm">
                                      <span className="colorlgbn label">
                                        Password
                                        <span class="validatcolor">*</span>{" "}
                                      </span>
                                      <CustomInput
                                        customClass="inputpaddingremove"
                                        id="vendorpassword"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        inputProps={{
                                          value: vendorpassword,
                                          type: "password",
                                          onChange: this.handleChange,
                                          onKeyDown: (event) =>
                                            this.preventSpace(
                                              event,
                                              "vendorpassword"
                                            ),
                                          autoComplete: "new-password",
                                          //autoComplete: "off"
                                          className: "nn_password",
                                        }}
                                      />

                                      <button
                                        onClick={(e) => this.viewPassword(e)}
                                      >
                                        <svg
                                          viewBox="0 0 24 24"
                                          width="24"
                                          height="24"
                                        >
                                          <path d="M12.186 8.106a3.733 3.733 0 0 1 3.727 3.728 3.733 3.733 0 0 1-3.727 3.728 3.733 3.733 0 0 1-3.727-3.728 3.733 3.733 0 0 1 3.727-3.728zm0 5.966a2.24 2.24 0 0 0 2.236-2.238 2.237 2.237 0 0 0-4.472 0 2.24 2.24 0 0 0 2.236 2.238zm9.67-2.802c-3.113-4.254-6.476-6.379-9.997-6.266-5.697.165-9.577 6.05-9.74 6.3a.752.752 0 0 0 .02.84c3.152 4.424 6.491 6.661 9.928 6.661.122 0 .244-.004.365-.009 5.645-.258 9.313-6.445 9.466-6.708a.75.75 0 0 0-.042-.818"></path>
                                        </svg>
                                      </button>
                                    </div>

                                    <div className={classes.loginBottomLinks}>
                                      <p className="footer-trms text-left checkWrap2  ">
                                        <Checkbox
                                          color="primary"
                                          id="accept"
                                          className="checkbox_box"
                                          checked={this.state.signupcheckbox}
                                          onChange={this.signupcheckboxhandle}
                                        />
                                        {this.state.signupcheckboxerror && (
                                          <p style={{ color: "red" }}>
                                            {this.state.signupcheckboxerror}
                                          </p>
                                        )}
                                        {t("login._clickingon")} {sitename}
                                        <strong>
                                          <Link
                                            onClick={() =>
                                              this.props.onClick(false)
                                            }
                                            to="/pages/terms_and_conditions"
                                          >
                                            {" "}
                                            {t("footer._termsandConditions")}
                                          </Link>
                                        </strong>{" "}
                                        {t("login._and")}
                                        <strong>
                                          <Link
                                            onClick={() =>
                                              this.props.onClick(false)
                                            }
                                            to="/pages/privacy_policy"
                                          >
                                            {t("footer._privacypolicy")}
                                          </Link>
                                        </strong>
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    align="center"
                                    className="login-submit-input"
                                  >

                                    <Button
                                      className="btn-wrapper"
                                      onClick={(e) =>
                                        this.handleSubmit("vendorsignup")
                                      }
                                      type="submit"
                                      style={{
                                        color: "rgb(254, 58, 86)",
                                        padding: "0",
                                      }}
                                      simple
                                      size="sm"
                                    >
                                      <span className="cmn-btn-auth">
                                        {t("login._SignUp")}
                                      </span>
                                    </Button>
                                    <div className="other-account">
                                      <GoogleLogin
                                        clientId={
                                          siteInfo.getSiteInfo.googleAppId
                                        }
                                        render={(renderProps) => (
                                          <a onClick={renderProps.onClick}>
                                            <img
                                              src={google}
                                              alt="google"
                                              height="30px"
                                            />
                                          </a>
                                        )}
                                        onSuccess={this.responseGoogle}
                                        onFailure={this.responseGoogle}
                                        cookiePolicy={"single_host_origin"}
                                      />
                                      <FacebookLogin
                                        appId={siteInfo.getSiteInfo.facebookAppId}
                                        fields="name,email,picture"
                                        render={renderProps => (
                                          <a onClick={renderProps.onClick}>
                                            <img
                                              src={facebook}
                                              onClick={renderProps.onClick}
                                              height="30px"
                                            />
                                          </a>
                                        )}
                                        onClick={this.componentClicked}
                                        callback={this.responseFacebook}
                                        disableMobileRedirect={true}
                                        isMobile={false}
                                      />
                                    </div>

                                    <Button
                                      type="submit"
                                      onClick={() => {
                                        this.setState({
                                          login: false,
                                          signUpOrIn: false,
                                          vendorsignup: true,
                                          popUpDetails: "",
                                          email: "",
                                          password: "",
                                          errors: "",
                                        });
                                      }}
                                      style={{ color: "rgb(254, 58, 86)" }}
                                      simple
                                      size="sm"
                                    >
                                      <span className="alredy">
                                        {t("login._AlreadyAccount")}
                                      </span>
                                    </Button>
                                  </div>
                                </div>
                              )}
                              {this.state.showAfterVendorRegisterForm && (
                                <div>
                                  <div className="text-center">
                                    <img src={ms} alt="ms" width="30%" />
                                  </div>
                                  <div className="membership-text">
                                    {t("login._Membership")}
                                  </div>
                                  <Elements stripe={stripePromise}>
                                    <PaymentForm id={vendorRegisterid} name={vendorRegistername} />
                                  </Elements>
                                </div>
                              )}
                              {this.state.showRegisterIcon && (
                                <div align="center" className="registerants">
                                  <button
                                    onClick={() => {
                                      this.hideComponent(
                                        "showUserRegisterForm"
                                      );
                                      this.handleRegisterHide();
                                    }}
                                  >
                                    <img src={user} alt="user" />
                                    <div className="titles-vendor">
                                      {t("login._AsUser")}
                                    </div>
                                  </button>

                                  <div className="bGYxuY">
                                    <h3
                                      className={
                                        classes.continueWith + " " + classes.or
                                      }
                                    >
                                      <span className="dash">
                                        {" "}
                                        <span> {t("login._USEOR")}</span>
                                      </span>
                                    </h3>
                                  </div>
                                  <button
                                    onClick={() => {
                                      this.hideComponent(
                                        "showVendorRegisterForm"
                                      );
                                      this.handleRegisterHide();
                                    }}
                                  >
                                    <img src={Vendor} alt="vendor" />
                                    <div className="titles-vendor">
                                      {t("login._AsVen")}
                                    </div>
                                  </button>
                                </div>
                              )}

                              {/* <div className="other-account">
																<ul className={classes.socialLogin} >
																	{this.state.isLoggedIn
																		? (fbContent = null)
																		: (fblogin === true) ? (fbContent = (
																			<FacebookLogin
																				appId={siteInfo.getSiteInfo.facebookAppId}
																				fields="name,email,picture"
																				render={renderProps => (
																					<li className="facebook login-facebook">
																						<a onClick={renderProps.onClick}>
																							<img
																								src={facebook}
																								onClick={renderProps.onClick}
																							/>
																						</a>
																					</li>
																				)}
																				onClick={this.componentClicked}
																				callback={this.responseFacebook}
																				disableMobileRedirect={true}
																				isMobile={false}
																			/>
																		)) : ""}

																	{(googlelogin === true) ? <GoogleLogin
																		clientId={siteInfo.getSiteInfo.googleAppId}
																		render={renderProps => (
																			<li className="google login-google">
																				<a onClick={renderProps.onClick}>
																					<img src={google} alt="" />
																				</a>
																			</li>
																		)}
																		onSuccess={this.responseGoogle}
																		onFailure={this.responseGoogle}
																		cookiePolicy={"single_host_origin"}
																	/> : ""}

																	{(applelogin === true) ? <AppleLogin
																		clientId={appleClientId}
																		redirectURI={`${REACT_APP_Domain_Url}web/auth/callback`}
																		responseType={"code"}
																		responseMode={"form_post"}
																		scope={"name email"}
																		render={renderProps => (
																			<li className="cls_apple">
																				<a onClick={renderProps.onClick}>
																					<img src={apple} alt="" />
																					<span className="whclg">
																						{t("login._ContinueApple")}
																					</span>
																				</a>
																			</li>
																		)}
																		designProp={{
																			height: 30,
																			width: 140,
																			color: "black",
																			border: false,
																			type: "sign-in",
																			border_radius: 50,
																			scale: 2,
																			locale: "en_US"
																		}}
																	/> : ""}
																</ul>
															</div> */}

                              <br />
                              {/* <Button
																type="submit"
																onClick={() => {
																	this.setState({
																		login: true,
																		popUpDetails: "",
																		email:
																			REACT_APP_ENV !== "package"
																				? REACT_APP_User_Login
																				: "",
																		password:
																			REACT_APP_ENV !== "package"
																				? REACT_APP_User_Password
																				: "",
																		userName: "",
																		errors: ""
																	});
																}}
																style={{
																	color: "rgb(254, 58, 86)",
																	padding: "0"
																}}
																simple
																size="sm"
															>
																<span className="alredy">
																	{t("login._AlreadyAccount")}
																</span>
															</Button> */}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </CardBody>
                  </div>{" "}
                </div>
              ) : (
                <div
                  className={`loginRight ${fblogin === true ||
                    googlelogin === true ||
                    applelogin === true
                    ? "nn_active"
                    : "nn_noactive"
                    }`}
                >
                  <div className={classes.loginContent + " "}>
                    <div className="dtgBrv">
                      <div className="fvALSw">
                        <svg
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          onClick={(e) => this.props.onClick(false)}
                          class="sc-kGXeez Pdhue"
                        >
                          <path
                            d="M12 9.988l3.822-3.822a1.423 1.423 0 0 1 2.011 2.011L14.012 12l3.821 3.822a1.42 1.42 0 0 1 0 2.011 1.42 1.42 0 0 1-2.011 0L12 14.011l-3.822 3.822a1.42 1.42 0 0 1-2.011 0 1.42 1.42 0 0 1 0-2.01L9.988 12 6.167 8.177a1.42 1.42 0 1 1 2.011-2.01L12 9.987z"
                            fill="var(--theme-color)"
                          ></path>
                        </svg>
                      </div>
                      <div className="fvALSws hghelp ">
                        <span>
                          {/* <Link onClick={() => this.props.onClick(false)} to="/pages/terms_of_use"> Help </Link> */}
                        </span>
                      </div>
                    </div>

                    <div className="ove_all ">
                      <div className={classes.textCenter + " " + "popupInfo"}>
                        <div className={classes.popupLogo}>
                          <img
                            src={loginImageLogo}
                            width="80px"
                            height="auto"
                            alt=""
                          />
                        </div>

                        <h2 className="quickbuy checkWrap2">
                          {" "}
                          {t("login._Buyandsell")}
                        </h2>

                        <div className="loginfeate">
                          <div className="unlocklogin">
                            {" "}
                            {t("login._unlockfeatures")}{" "}
                          </div>

                          <div className="jHTwzI">
                            <div className="fGPScD">
                              <div className="kwtHbv">
                                <svg
                                  viewBox="0 0 24 24"
                                  width="24"
                                  height="24"
                                  class="sc-jTzLTM fznnpf"
                                >
                                  <path d="M6.892 5.571A3.468 3.468 0 0 1 10.262 3h3.477a3.473 3.473 0 0 1 3.369 2.571h1.416c1.92 0 3.476 1.536 3.476 3.43v8.57C22 19.464 20.442 21 18.524 21H5.476C3.556 21 2 19.465 2 17.571v-8.57c0-1.893 1.558-3.43 3.476-3.43h1.416zm9.456 7.715C16.348 10.919 14.4 9 12 9s-4.348 1.919-4.348 4.286c0 2.367 1.947 4.285 4.348 4.285s4.348-1.918 4.348-4.285zm-6.957 0c0-1.42 1.168-2.572 2.609-2.572 1.44 0 2.609 1.152 2.609 2.572 0 1.42-1.168 2.571-2.609 2.571-1.44 0-2.609-1.151-2.609-2.571z"></path>
                                </svg>
                              </div>
                              <span className="hHLSvC">
                                {" "}
                                {t("login._Postlistings")}{" "}
                              </span>
                            </div>
                            <div className="fGPScD">
                              <div className="kwtHbv">
                                <svg
                                  viewBox="0 0 24 24"
                                  width="24"
                                  height="24"
                                  class="sc-jTzLTM fznnpf"
                                >
                                  <path d="M7.249 21.204v-1.902c0-.58-.47-1.05-1.05-1.05A4.2 4.2 0 0 1 2 14.053v-5.86A4.194 4.194 0 0 1 6.193 4h11.734a4.193 4.193 0 0 1 4.193 4.193v5.866a4.193 4.193 0 0 1-4.193 4.193h-5.013c-.444 0-.87.177-1.185.49l-3.05 3.048c-.525.526-1.424.158-1.43-.586zm.617-8.828a1.255 1.255 0 1 0 0-2.512 1.256 1.256 0 1 0 0 2.512zm8.383 0a1.255 1.255 0 1 0 0-2.512 1.256 1.256 0 1 0 0 2.512zm-4.191 0a1.255 1.255 0 1 0 0-2.512 1.256 1.256 0 1 0 0 2.512z"></path>
                                </svg>
                              </div>
                              <span className="hHLSvC">
                                {" "}
                                {t("login._Chatbuyers")}{" "}
                              </span>
                            </div>

                            <div className="fGPScD">
                              <div className="kwtHbv">
                                <svg
                                  viewBox="0 0 24 24"
                                  width="24"
                                  height="24"
                                  class="sc-jTzLTM fznnpf"
                                >
                                  <path d="M16.224 5c-1.504 0-2.89.676-3.802 1.854L12 7.398l-.421-.544A4.772 4.772 0 0 0 7.776 5C5.143 5 3 7.106 3 9.695c0 5.282 6.47 11.125 9.011 11.125 2.542 0 8.99-5.445 8.99-11.125C21 7.105 18.857 5 16.223 5z"></path>
                                </svg>
                              </div>
                              <span className="hHLSvC">
                                {t("login._Savelistings")}{" "}
                              </span>
                            </div>
                          </div>
                        </div>

                        {/* <div className={`bGYxuY nn_social ${((fblogin === true) || (googlelogin === true) || (applelogin === true)) ? "nn_active" : ""}`}>
												<h3 className={classes.continueWith + " " + classes.or}>
													<span className="dash">
														<span>{t("login._QUICKLYCONNECT")}</span>
													</span>
												</h3>
											</div>
											<ul className={classes.socialLogin}>
												{this.state.isLoggedIn
													? (fbContent = null)
													: (fblogin === true) ? (fbContent = (
														<FacebookLogin
															appId={siteInfo.getSiteInfo.facebookAppId}
															fields="name,email,picture"
															render={renderProps => (
																<li className="facebook">
																	<a onClick={renderProps.onClick}>
																		<img
																			src={facebook}
																			onClick={renderProps.onClick}
																		/>
																		<span className="whclg">
																			{t("login._ContinueFacebook")}
																		</span>
																	</a>
																</li>
															)}
															onClick={this.componentClicked}
															callback={this.responseFacebook}
															disableMobileRedirect={true}
															isMobile={false}
														/>
													)) : ""}

												{(googlelogin === true) ? <GoogleLogin
													clientId={siteInfo.getSiteInfo.googleAppId}
													render={renderProps => (
														<li className="google">
															<a onClick={renderProps.onClick}>
																<img src={google} alt="" />
																<span className="whclg">
																	{t("login._ContinueGoogle")}
																</span>
															</a>
														</li>
													)}
													onSuccess={this.responseGoogle}
													onFailure={this.responseGoogle}
													cookiePolicy={"single_host_origin"}
												/> : ""}

												{(applelogin === true) ? <AppleLogin
													clientId={appleClientId}
													redirectURI={`${REACT_APP_Domain_Url}web/auth/callback`}
													responseType={"code"}
													responseMode={"form_post"}
													scope={"name email"}
													render={renderProps => (
														<li className="cls_apple">
															<a onClick={renderProps.onClick}>
																<img src={apple} alt="" />
																<span className="whclg">
																	{t("login._ContinueApple")}
																</span>
															</a>
														</li>
													)}
													designProp={{
														height: 30,
														width: 140,
														color: "black",
														border: false,
														type: "sign-in",
														border_radius: 50,
														scale: 2,
														locale: "en_US"
													}}
												/> : ""}


											</ul> */}
                        <div
                          className={`bGYxuY  ${fblogin === true ||
                            googlelogin === true ||
                            applelogin === true
                            ? "nn_active"
                            : "nn_social"
                            }`}
                        >
                          <h3
                            className={classes.continueWith + " " + classes.or}
                          >
                            <span className="dash">
                              {" "}
                              <span> {t("login._USEYOUREMAIL")}</span>
                            </span>
                          </h3>
                        </div>
                        <div
                          className={`bGYxuY ${fblogin === true ||
                            googlelogin === true ||
                            applelogin === true
                            ? "nn_social"
                            : "nn_active"
                            }`}
                        >
                          <h3
                            className={classes.continueWith + " " + classes.or}
                          >
                            <span className="dash">
                              {" "}
                              <span> USE YOUR EMAIL</span>
                            </span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul
                    className={
                      classes.logLinks +
                      " " +
                      classes.textCenter +
                      " " +
                      classes.my3 +
                      " loginUl"
                    }
                  >
                    <li>
                      <Button
                        type="submit"
                        onClick={() => {
                          this.setState({
                            login: false,
                            signUpOrIn: true,
                            signUp: false,
                          });
                        }}
                        style={{ color: "var(--theme-color)" }}
                        simple
                        size="sm"
                      >
                        <span
                          className="signuinbtn"
                          onClick={this.handleRegisterShow}
                        >
                          {" "}
                          {t("login._SignUp")}{" "}
                        </span>
                      </Button>
                    </li>
                    <li className="rtlborder">
                      <Button
                        type="submit"
                        onClick={() => {
                          this.setState({
                            login: true,
                            signUpOrIn: true,
                            email:
                              REACT_APP_ENV !== "package"
                                ? REACT_APP_User_Login
                                : "",
                            password:
                              REACT_APP_ENV !== "package"
                                ? REACT_APP_User_Password
                                : "",
                          });
                        }}
                        style={{ color: "var(--theme-color)" }}
                        simple
                        size="sm"
                      >
                        <span
                          className="signuinbtn"
                          onClick={this.handleRegisterShow}
                        >
                          {t("login.Login")}
                        </span>
                      </Button>
                    </li>
                  </ul>
                  <div className={classes.loginBottomLinks}>
                    <p className="footer-trms checkWrap2 loginRightpadding">
                      {t("login._clickingon")} {sitename}
                      <strong>
                        <Link
                          onClick={() => this.props.onClick(false)}
                          to="/pages/terms_and_conditions"
                        >
                          {" "}
                          {t("footer._termsandConditions")}
                        </Link>
                      </strong>{" "}
                      {t("login._and")}
                      <strong>
                        <Link
                          onClick={() => this.props.onClick(false)}
                          to="/pages/privacy_policy"
                        >
                          {t("footer._privacypolicy")}
                        </Link>
                      </strong>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </LoginPopup>
        {this.state.modelState && (
          <DiscardPopup
            isOpen={this.state.emailPopup}
            contentLabel="Minimal Modal Example"
            style={customStyles2}
          >
            {this.state.noEmail ? (
              <div className="botnbtn">
                <p>
                  <b>{t("Productdetails._getEmail")}</b>
                </p>

                <Input
                  defaultValue={this.state.inputValue}
                  className={classes.popsp}
                  fullWidth
                  inputProps={{
                    onChange: (e) => this.updateEmailValue(e),
                  }}
                />
                <FormHelperText error={errors.popEmail}>
                  {errors.popEmail}
                </FormHelperText>
                <div className="brbtn">
                  <button
                    disabled={this.state.isButtonDisabled}
                    type="button"
                    className="reporlst"
                    onClick={(e) =>
                      this.updateSocialLogin(this.state.inputValue)
                    }
                  >
                    {t("Productdetails._sub")}
                  </button>
                  <button
                    type="button"
                    className="reporcl"
                    onClick={this.closeModel}
                  >
                    {" "}
                    {t("Productdetails._Cancel")}
                  </button>
                </div>
              </div>
            ) : (
              <div className="text-center thankmsg">
                <p>{t("Productdetails._listingreported")}</p>{" "}
              </div>
            )}
          </DiscardPopup>
        )}
      </>
    );
  }
}

LoginComponent.propTypes = {
  open: PropTypes.bool,
};

var Login = compose(
  graphql(SIGNIN, { name: "signIn" }),
  graphql(VERIFY_EMAIL, { name: "verifyEmail" }),
  graphql(VENDOR, { name: "vendorsignup" }),
  graphql(SIGNUP, { name: "signUp" }),
  graphql(SOCIAL_LOGIN, { name: "socialLogin" }),
  graphql(FORGOT_PASSWORD, { name: "forgotPassword" }),
  graphql(GET_SITE_INFO, { name: "siteInfo" }),
  graphql(REDIRECT_HOME_FILTER, {
    name: "redirectHomeFilter",
  }),
  graphql(GET_REDIRECTFILTER_STATE, {
    name: "pageCountFilter",
    options: () => ({
      fetchPolicy: "cache-only",
    }),
  })
)(LoginComponent);

export default withTranslation("common")(withStyles(styles)(Login));
