import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";

import { Link } from "react-router-dom";
import product from '../../../../assets/img/vendorimg.png';
import product2 from '../../../../assets/img/vendor2.png';
import product3 from '../../../../assets/img/vendor1.png';
import product4 from '../../../../assets/img/vendor3.png';
import product5 from '../../../../assets/img/vendor4.png';
import vendorconstant from "../../../vendorconstant";

const VendorCategories = () => {
	const [vendorCatData, setVendorCatData] = useState([])
	const [baseUrl, setBaseUrl] = useState("")
	const didMountRef = useRef(true)
	const vendor = [{
		image: product,
		name: "Anniversary"
	},
	{
		image: product,
		name: "Baby Shower"
	},
	{
		image: product,
		name: "Carnival"
	},
	{
		image: product,
		name: "Bridal Shower"
	},
	{
		image: product,
		name: "Party"
	}]


	useEffect(() => {
		if (didMountRef.current) {
			const dataString = {
				type: "homecategory"
			}
			axios
				.post(vendorconstant.API_URL + "frontendgigs/getvendorcatdata", dataString)
				.then((response) => {
					console.log("tyu", response)
					setVendorCatData(response.data.data)
					setBaseUrl(response.data.baseurl)
				})
				.catch((error) => {
					console.log(error);
				});
		}
		didMountRef.current = false;
	}, []);


	return (
		<Container>
			<Row className="align-items-center  mb-50">
				<Col sm={10} sx={10}>
					<div className="section-tittle">
						<h2 className="tittle">
							<span class="shape"></span>Vendor Services
						</h2>
					</div>
				</Col>
				<Col md={2} xl={2} lg={2} sm={2} sx={2} className=" text-right">
					<Link to={'/vendor'} className="see_all-all">See All</Link>
				</Col>
			</Row>
			<div className="nn_categorylist w-100">
				<ul className="vendorOutercat">
					{vendorCatData.map((value, image) => (

						<li className="vendorOutercatlist">
							<Link to="/filter-vendor" onClick={(e) => localStorage.setItem('FILTER_DATA', value._id)}>
								<div className="nn_categoryctn text-center">
									<img
										src={value.image != null ? baseUrl + value.image : product}
										alt=""
										className="cateimg category-icon"
										style={{ "borderRadius": "50%" }}
									/>
									<h3>{value.name}</h3>
								</div>
							</Link>
						</li>

					))}
				</ul>
			</div>
		</Container>
	);
};
export default VendorCategories;
