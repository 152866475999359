import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";


function RecentListing(props) {
    let product = props.recentProducts;
    if(!product.length){
        return null
    }
    return (
        <div className="recentListing section-padding  plr">
          
            <Container>
                <Row>
                    <Col md={10} xl={8} lg={7} sm={10}>
                        <div className="section-tittle mb-50">
                            <h2 className="tittle ">
                                <span class="shape"></span>Recent Browse
                            </h2>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {product && product.map((p, i) => (
                        <Col key={i} lg={6} xl={4}>
                            <div className="borderStyle style1 social">
                                <div className="singleFlexitem mb-24">
                                    <div className="recentImg">
                                        <Link to="">
                                            <img src={p.image} alt="img"></img>
                                        </Link>
                                    </div>
                                    <div className="recentCaption">
                                        <h5>
                                            <Link to={`/products/${p.id}`}>{p.name}</Link>
                                        </h5>
                                        <p className="featureCap">
                                            {p.address}
                                        {/* India, Mumbai · <strong className="subCap">Posted 18 Days Ago</strong> */}
                                        </p>
                                        <span className="featurePricing"> 
                                        $ {p.price}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))}
                    {/* <Col  lg={6} xl={4}>
                        <div className="borderStyle style1 social">
                            <div className="singleFlexitem mb-24">
                                <div className="recentImg">
                                    <Link to="">
                                        <img src={aboutImg} alt="img"></img>
                                    </Link>
                                </div>
                                <div className="recentCaption">
                                    <h5>
                                        <Link to="">Dinner Party</Link>
                                    </h5>
                                    <p className="featureCap">
                                    India, Mumbai · <strong className="subCap">Posted 18 Days Ago</strong>
                                    </p>
                                    <span className="featurePricing"> 
                                    ₹146,300
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col  lg={6} xl={4}>
                        <div className="borderStyle style1 social">
                            <div className="singleFlexitem mb-24">
                                <div className="recentImg">
                                    <Link to="">
                                        <img src={aboutImg} alt="img"></img>
                                    </Link>
                                </div>
                                <div className="recentCaption">
                                    <h5>
                                        <Link to="">Dinner Party</Link>
                                    </h5>
                                    <p className="featureCap">
                                    India, Mumbai · <strong className="subCap">Posted 18 Days Ago</strong>
                                    </p>
                                    <span className="featurePricing"> 
                                    ₹146,300
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col  lg={6} xl={4}>
                        <div className="borderStyle style1 social">
                            <div className="singleFlexitem mb-24">
                                <div className="recentImg">
                                    <Link to="">
                                        <img src={aboutImg} alt="img"></img>
                                    </Link>
                                </div>
                                <div className="recentCaption">
                                    <h5>
                                        <Link to="">Dinner Party</Link>
                                    </h5>
                                    <p className="featureCap">
                                    India, Mumbai · <strong className="subCap">Posted 18 Days Ago</strong>
                                    </p>
                                    <span className="featurePricing"> 
                                    ₹146,300
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col  lg={6} xl={4}>
                        <div className="borderStyle style1 social">
                            <div className="singleFlexitem mb-24">
                                <div className="recentImg">
                                    <Link to="">
                                        <img src={aboutImg} alt="img"></img>
                                    </Link>
                                </div>
                                <div className="recentCaption">
                                    <h5>
                                        <Link to="">Dinner Party</Link>
                                    </h5>
                                    <p className="featureCap">
                                    India, Mumbai · <strong className="subCap">Posted 18 Days Ago</strong>
                                    </p>
                                    <span className="featurePricing"> 
                                    ₹146,300
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col  lg={6} xl={4}>
                        <div className="borderStyle style1 social">
                            <div className="singleFlexitem mb-24">
                                <div className="recentImg">
                                    <Link to="">
                                        <img src={aboutImg} alt="img"></img>
                                    </Link>
                                </div>
                                <div className="recentCaption">
                                    <h5>
                                        <Link to="">Dinner Party</Link>
                                    </h5>
                                    <p className="featureCap">
                                    India, Mumbai · <strong className="subCap">Posted 18 Days Ago</strong>
                                    </p>
                                    <span className="featurePricing"> 
                                    ₹146,300
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Col> */}
                </Row>
            </Container>
        </div>
            
    );
}

export default RecentListing;