import React, { Component, useState } from "react";
// import { Container, Row, Col, Image } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import FormControlLabel from "@material-ui/core/FormControlLabel";

// import { EditProfileMain } from '../css/styledcomponents';

// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import Card from "react-bootstrap/Card";

// import GradeIcon from "@mui/icons-material/Grade";
// import {  Grid } from "@material-ui/core";
// import InputRange from "react-input-range-rtl";

const UserDetail = () => {
    

    return (
      <>
     <h1>takjfkg</h1>   
   
      </>
    );
};
export default UserDetail;
