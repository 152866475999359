import React from "react";
import { compose, graphql, ApolloConsumer } from "react-apollo";
import {
    GET_ALL_PRODUCTS,
    GET_CATEGORIES,
    UPDATE_CHAT_GROUP,
    GET_CURRENT_USER,
    GET_CACHE_STATE,
    ISOPEN,
    GET_USER,
    INACTIVE,
    GET_CATEGORY_ID,
    CATEGORY_ID,
    GET_SEARCH_INPUT,
    SEARCH_INPUT,
    GET_LOCATION,
    GET_SITE_INFO,
    LOCATION,
    PRICE,
    GET_PRICE_DETAILS,
    SORTBY,
    GET_SORTBY_DETAILS,
    DATEBY,
    GET_DATEBY_DETAILS,
    GET_REDIRECT_STATE,
    GET_REDIRECTFILTER_STATE,
    GET_CURRENCIES,
    RADIUS,
    GET_RADIUS,
    LOCATION_NAME,
    GET_LOCATION_NAME,
} from "../../queries";
import advetiser from "../../assets/img/Advertise-here-Banner.png"
import history from "../../history";
import { Helmet } from "react-helmet";
import axios from "axios";
import Products from "./Dashboard/Products.jsx";
import CategoryFilter from "./Dashboard/Filters/Category.jsx";
import Chat from "./Chat/index.jsx";
import Header from "./Header/index.jsx";
import deleteButton from "../../assets/img/delete.png";
import withStyles from "@material-ui/core/styles/withStyles";
import pagesStyle from "../../assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";
import HomeFilter from "./home_filter.js";
import Footer from "./Footer/Footer.jsx";
import { animateScroll as scroll } from "react-scroll";
import Slider from "react-slick";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { ProviderRefech, ProductConsumer } from "./ProductContext.js";
import "./css/orderview.css";
import "./css/script.js";
import { Main, Banner, HomeProduct, ScrollTop } from "./css/styledcomponents";
import { Col, Container, Form, Row } from "react-bootstrap";
import Button from "../../components/CustomButtons/Button";
import AboutArea from "../aboutArea";
import RecentListing from "../recentListing/recentListing";
import { Grid } from "@material-ui/core";
import GridItem from "../../components/Grid/GridItem";
import VendorCategories from "./Dashboard/Filters/VendorCategories";
import Gigs from "./Dashboard/Gigs";
import VendorContextProvider from "../../vendorContext/VendorContext";
import Hello from "../../components/Hello";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cUser: {},
            recentProducts: [],
            openHandler: false,
            showScroll: false,
            title: "",
            favicon: "",
            srch: "",
            logoImage: "",
            city: "",
            country: "",
            locationType: "location",
            locationName: "",
            iosLink: "",
            androidLink: "",
            isPaneOpen: false,
            clearFilter: false,
            bannerImages: [],
            bannerUrl: "",
            categoryList: "",
            currency: "",
            unit: "",
        };
        this.handleActiveScreen = this.handleActiveScreen.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePlacesChanged = this.handlePlacesChanged.bind(this);
        this.setRef = this.setRef.bind(this);
        this.bRef = this.bRef.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.bannerLink = this.bannerLink.bind(this);
    }

    setRef(node) {
        this.wrapperRef = node;
    }
    bRef(node) {
        this.blockRef = node;
    }
    componentWillMount() {
        let { currentUser, siteInfo, categoryInfo } = this.props;
        if (!currentUser.getCurrentUser) currentUser.refetch();
        this.setState({
            cUser: currentUser.getCurrentUser && currentUser.getCurrentUser,
        });
        siteInfo.refetch();
        if (siteInfo.getSiteInfo) {
            let { iosLink, androidLink, favicon, name, image, defaultUnit } =
                siteInfo.getSiteInfo;
            this.setState({
                title: name,
                favicon: favicon,
                iosLink: iosLink,
                logoImage: image,
                androidLink: androidLink,
                defaultUnit: defaultUnit,
            });
        }
        let bannerImages = [];
        let bannerUrl = "";
        categoryInfo
            .refetch()
            .then(({ data }) => {
                if (data) {
                    bannerImages =
                        data.getCategoryDetails &&
                        data.getCategoryDetails.adBannerDetails;
                    this.setState({
                        bannerImages: bannerImages,
                        bannerUrl: bannerUrl,
                    });
                }
            })
            .catch((e) => console.log(e));
    }
    componentDidMount() {
        this.getGeoInfo();
        var cls_header_height = document.getElementById("hdrFx");
        var cls_he = cls_header_height.clientHeight;
        document.getElementById("categorydesk").style.marginTop = 0 + "px";
        var url = new URL(window.location);
        //category
        if (url.searchParams.get("categoryId")) {
            this.props.getCategoryId({
                variables: { categoryId: url.searchParams.get("categoryId") },
            });
        }
        //sort
        if (url.searchParams.get("sort")) {
            this.props.getSortBy({
                variables: { sort: url.searchParams.get("sort") },
            });
        }

        //price min and max
        if (url.searchParams.get("min") && !url.searchParams.get("max")) {
            this.props.getPrice({
                variables: { max: "", min: url.searchParams.get("min") },
            });
        } else if (
            !url.searchParams.get("min") &&
            url.searchParams.get("max")
        ) {
            this.props.getPrice({
                variables: { max: url.searchParams.get("max"), min: "" },
            });
        } else if (url.searchParams.get("min") && url.searchParams.get("max")) {
            this.props.getPrice({
                variables: {
                    max: url.searchParams.get("max"),
                    min: url.searchParams.get("min"),
                },
            });
        }

        //location
        if (url.searchParams.get("location")) {
            let locationArrayData = url.searchParams.get("location").split("_");
            this.props.getLocation({
                variables: {
                    lat_lon: [
                        parseFloat(locationArrayData[0]),
                        parseFloat(locationArrayData[1]),
                    ],
                },
            });
        }

        //location name
        if (url.searchParams.get("locationName")) {
            let placeName = url.searchParams.get("locationName");
            this.props.getLocationName({
                variables: { locationName: placeName },
            });
        }

        //radius
        if (url.searchParams.get("radius")) {
            let radiusData = url.searchParams.get("radius");
            this.props.getRadius({
                variables: { radius: radiusData.toString() },
            });
        }

        // search text
        if (url.searchParams.get("searchText")) {
            let searchKey = url.searchParams.get("searchText");
            this.props.searchResult({
                variables: { searchInput: searchKey },
            });
        }
        // window.addEventListener(
        //     "scroll",
        //     () => {
        //         this.componentScroll();
        //     },
        //     true
        // );

    }



    getGeoInfo = () => {
        axios
            .get("https://ipapi.co/json/")
            .then((response) => {
                let data = response.data;
                this.setState({
                    country: data.country_name,
                    city: data.latitude + "_" + data.longitude,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    handleChangeSrch = (event) => {
        let name = event.target.name;
        let resultData = event.target.value;
        this.setState({ [name]: resultData });
    };
    goHome = () => {
        this.clr();
        var url = new URL(window.location);
        if (window.location.pathname !== "/") {
            this.props.history.push("/");
        }
        if (this.state.srch !== "") {

            this.props.searchResult({
                variables: { searchInput: this.state.srch.trim() },
            });
            url.searchParams.set("searchText", this.state.srch.trim());
        }
        var placeName = "";
        if (this.state.locationType === "location") {
            placeName = this.state.city;
            url.searchParams.set("location", placeName);
            var locationArrayData = placeName.split("_");
            this.props.getLocation({
                variables: {
                    lat_lon: [
                        parseFloat(locationArrayData[0]),
                        parseFloat(locationArrayData[1]),
                    ],
                },
            });
        } else {
            placeName = this.state.country;
            this.props.getLocationName({
                variables: { locationName: placeName },
            });
            url.searchParams.set("locationName", placeName);
        }

        history.push(`?${url.searchParams.toString()}`);
    };

    clr = () => {
        var url = new URL(window.location);
        this.setState({ srch: "" });
        this.props.searchResult({ variables: { searchInput: "" } });
        if (url.searchParams.get("searchText")) {
            url.searchParams.delete("searchText");
            history.push(`?${url.searchParams.toString()}`);
        }
        this.props.getLocation({ variables: { lat_lon: null } });
        this.props.getLocationName({ variables: { locationName: "" } });
        if (url.searchParams.get("locationName")) {
            url.searchParams.delete("locationName");
            history.push(`?${url.searchParams.toString()}`);
        }
        if (url.searchParams.get("location")) {
            url.searchParams.delete("location");
            history.push(`?${url.searchParams.toString()}`);
        }
    };

    setCategory = (data) => {
        const categoryData =
            data.getCategoryDetails && data.getCategoryDetails.category
                ? data.getCategoryDetails.category
                : [];
        this.setState({
            categoryList: categoryData,
        });
    };

    // componentScroll = () => {
    //     const scope = this;
    //     var winheight =
    //         window.innerHeight ||
    //         (document.documentElement || document.body).clientHeight;
    //     var D = document;
    //     var docheight = Math.max(
    //         D.body.scrollHeight,
    //         D.documentElement.scrollHeight,
    //         D.body.offsetHeight,
    //         D.documentElement.offsetHeight,
    //         D.body.clientHeight,
    //         D.documentElement.clientHeight,
    //     );
    //     var scrollTop =
    //         window.pageYOffset || document.documentElement.scrollTop;
    //     var trackLength = docheight - winheight;
    //     var pctScrolled = Math.floor((scrollTop / trackLength) * 100);
    //     if (pctScrolled > 10) {
    //         scope.setState({
    //             showScroll: true,
    //         });
    //     } else {
    //         scope.setState({
    //             showScroll: false,
    //         });
    //     }
    // };

    componentWillUnmount() {
        // window.removeEventListener(
        //     "scroll",
        //     () => {
        //         this.componentScroll();
        //     },
        //     true,
        // );
    }

    scrollToTop() {
        scroll.scrollToTop();
    }

    componentWillReceiveProps(nextProps) {
        let {
            currentUser,
            getCacheData,
            getLoactionNameData,
            getCacheCategorytypeData,
            getCacheCategoryData,
            getCacheSearchInput,
            getCacheLocationData,
            getPriceData,
            getSortByData,
            getDateByData,
            getLoadCount,
            getLoadCountFilter,
            getCacheRadiusData,
        } = nextProps;
        if (
            nextProps.getCacheData !== this.props.getCacheData ||
            nextProps.getCacheCategoryData !==
            this.props.getCacheCategoryData ||
            nextProps.getCacheSearchInput !== this.props.getCacheSearchInput ||
            nextProps.getCacheLocationData !==
            this.props.getCacheLocationData ||
            nextProps.getPriceData !== this.props.getPriceData ||
            nextProps.getDateByData != this.props.getDateByData ||
            nextProps.getSortByData !== this.props.getSortByData ||
            nextProps.getLoadCount !== this.props.getLoadCount ||
            nextProps.getLoadCountFilter !== this.props.getLoadCountFilter ||
            nextProps.getCacheRadiusData !== this.props.getCacheRadiusData ||
            nextProps.getLoactionNameData !== this.props.getLoactionNameData
        ) {
            this.setState({
                inActive: getCacheData.inActive,
                categoryId: getCacheCategoryData.categoryId,
                searchInput: getCacheSearchInput.searchInput,
                lat_lon: getCacheLocationData.lat_lon,
                locationName: getLoactionNameData.locationName,
                min_max: getPriceData,
                sortDate: getDateByData.sortDate,
                min: getPriceData && getPriceData.min,
                max: getPriceData && getPriceData.max,
                sort: getSortByData.sort,
                pageCount: getLoadCount.pageCount,
                pageCountFilter: getLoadCountFilter.pageCountFilter,
                radius: getCacheRadiusData.radius,
            });
        }

        currentUser.refetch();

        this.setState({
            cUser: currentUser.getCurrentUser && currentUser.getCurrentUser,
        });
        if (currentUser.getCurrentUser) {
            this.props.getUser.refetch({ id: Number(currentUser.getCurrentUser.id) }).then(({ data }) => {
                if (data && data.getUserDetails) {
                    this.setState({
                        recentProducts: data.getUserDetails.foundUser.recentProducts
                    });
                }
            });
        } else {
            this.setState({
                recentProducts: []
            });
        }
        if (nextProps.siteInfo && nextProps.siteInfo.getSiteInfo) {
            let { iosLink, androidLink, name, favicon, image, defaultUnit } =
                nextProps.siteInfo.getSiteInfo;
            this.setState({
                title: name,
                favicon: favicon,
                logoImage: image,
                iosLink: iosLink,
                androidLink: androidLink,
                defaultUnit: defaultUnit,
            });
        }
    }

    handleActiveScreen(e) {
        e.preventDefault();
        if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
            this.props.inActiveScreen({ variables: { inActive: false } });
        }
        if (this.blockRef && !this.blockRef.contains(e.target)) {
            this.props.isOpenScreen({ variables: { open: false } });
        }
    }
    handleClick(e, id) {
        e.preventDefault();
        this.props.getCategoryId({ variables: { categoryId: id } });
    }

    handleChange(event) {
        event.preventDefault();
        this.props.searchResult({ variables: { searchInput: event } });
    }
    handlePlacesChanged() {
        // this.props.getLocation({variables:{lat_lon:getCacheLocationData.lat_lon}})
    }

    gooApp = (link) => {
        window.open(link, "_blank");
    };

    bannerLink(bannerUrl, e) {
        window.open(bannerUrl, "_blank");
    }

    clearFilter = (cng) => {
        this.setState({
            clearFilter: cng,
        });
    };

    head() {
        return (
            <Helmet>
                <link rel="shortcut icon" href={this.state.favicon} />
            </Helmet>
        );
    }

    playStore = (link) => {
        window.open(link, "_blank");
    };

    render() {
        const currencies = [
            {
                value: "USD",
                label: "$",
            },
            {
                value: "EUR",
                label: "€",
            },
            {
                value: "BTC",
                label: "฿",
            },
            {
                value: "JPY",
                label: "¥",
            },
        ];

        var settings = {
            dots: true,
            infinite: true,
            speed: 1000,
            fade: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
        };

        const { showScroll, bannerImages } = this.state;
        let {
            classes,
            match,
            history,
            location,
            getCacheCategoryData,
            categoryInfo,
        } = this.props;
        let {
            inActive,
            cUser,
            categoryId,
            searchInput,
            lat_lon,
            locationName,
            min_max,
            sort,
            sortDate,
            pageCount,
            pageCountFilter,
            radius,
            androidLink,
            iosLink,
            min,
            max,
            recentProducts
        } = this.state;
        let inActiveStyle = inActive ? classes.inActive : "";
        return (

            <Main id="content">
                <ProviderRefech>
                    {this.head()}
                    <div className="dynamcss">
                        <div className={
                            classes.wrap + " " + inActiveStyle + "nn_cate"
                        }
                        >
                            <ProductConsumer>
                                {(value) => (
                                    <>
                                        <div
                                            className={"unvisible"}
                                        >
                                            <Header
                                                clearFilter={this.clearFilter}
                                                AdvancedFiltersubmit={
                                                    value.AdvancedFiltersubmit
                                                }
                                                stuffImage={value.stuffImage}
                                                categorySubmitted={
                                                    value.categorySubmitted
                                                }
                                                CategoryWithImage={
                                                    value.CategoryWithImage
                                                }
                                                discardStuffStatus={
                                                    value.discardStuff
                                                }
                                                discardYourStuff={
                                                    value.discardYourStuff
                                                }
                                                manageBeforeLogin={
                                                    value.stuffValue
                                                }
                                                refetchValue={value}
                                                match={match}
                                                setRef={this.setRef}
                                                isPaneOpen={
                                                    this.state.isPaneOpen
                                                }
                                                closeSidePanel={() => {
                                                    this.setState({
                                                        isPaneOpen: false,
                                                    });
                                                }}
                                                openSidePanel={() => {
                                                    this.setState({
                                                        isPaneOpen: true,
                                                    });
                                                }}
                                                location={location}
                                                history={history}
                                                currentUser={cUser}
                                                postAnotherListing={
                                                    value.postAnotherListing
                                                }
                                                contextConsumerInner={value}
                                                getCacheCategoryData={
                                                    categoryId
                                                }
                                                clickPosting={
                                                    value.clickPosting
                                                }
                                                clearValue={value.clearValue}
                                                showValue={value.showValue}
                                                postDone={value.postDone}
                                            />
                                        </div>
                                    </>
                                )}
                            </ProductConsumer>
                            {location.pathname === "/chat/conversation" ? (
                                <Chat
                                    setRef={this.bRef}
                                    history={history}
                                    currentUser={cUser}
                                />
                            ) : (
                                <div>
                                    <Banner className={"nn_banner banner-outer"} >
                                        <div className={bannerImages.length > 0 ? "banner banner-section" : "dfsdg"} >
                                            {bannerImages.length > 0 && (
                                                <Slider {...settings}>
                                                    {bannerImages
                                                        .filter(
                                                            (item) =>
                                                                item.status !==
                                                                "Inactive",
                                                        )
                                                        .map((item, i) => (
                                                            <>
                                                                <div key={i} data-id={item.id} >
                                                                    {" "}
                                                                    <div style={{
                                                                        backgroundImage:
                                                                            "url(" +
                                                                            item.webBannerImage +
                                                                            ")",
                                                                    }} alt="" className="banner-image" >
                                                                        <div className="banner-content">
                                                                            <Row className="justify-content-center align-items-center">
                                                                                <Col item xs={8} md={12} >
                                                                                    <h1 className="banner-title text-black">
                                                                                        The go-to platform for party
                                                                                        leftovers and event services
                                                                                    </h1>
                                                                                </Col>
                                                                                <Col item xs={8} md={12}>
                                                                                    <Form className="search-box">
                                                                                        <div className="select-form">
                                                                                            <div className="select-itms">
                                                                                                <div className="select-location">
                                                                                                    <Form.Group controlId="formBasicPassword">
                                                                                                        <Form.Control
                                                                                                            as="select"
                                                                                                            name="locationType"
                                                                                                            value={
                                                                                                                this
                                                                                                                    .state
                                                                                                                    .locationType
                                                                                                            }
                                                                                                            onChange={
                                                                                                                this
                                                                                                                    .handleChangeSrch
                                                                                                            }
                                                                                                        >
                                                                                                            <option
                                                                                                                value={
                                                                                                                    "location"
                                                                                                                }
                                                                                                            >
                                                                                                                My
                                                                                                                Location
                                                                                                            </option>
                                                                                                            <option
                                                                                                                value={
                                                                                                                    "nation"
                                                                                                                }
                                                                                                            >
                                                                                                                Nationwide
                                                                                                            </option>
                                                                                                        </Form.Control>
                                                                                                    </Form.Group>
                                                                                                </div>
                                                                                                <div className="searchBox-wrapper close-btn">
                                                                                                    <Form.Group controlId="exampleForm.ControlInput1">
                                                                                                        <Form.Control
                                                                                                            type="text"
                                                                                                            name="srch"
                                                                                                            placeholder="Search Here..."
                                                                                                            value={
                                                                                                                this
                                                                                                                    .state
                                                                                                                    .srch
                                                                                                            }
                                                                                                            onChange={
                                                                                                                this
                                                                                                                    .handleChangeSrch
                                                                                                            }
                                                                                                        />
                                                                                                    </Form.Group>
                                                                                                    {this
                                                                                                        .state
                                                                                                        .srch
                                                                                                        .length >
                                                                                                        0 && (
                                                                                                            <button
                                                                                                                onClick={() =>
                                                                                                                    this.clr()
                                                                                                                }
                                                                                                                className="closeicon"
                                                                                                            >
                                                                                                                <img className="closeimg" src={deleteButton} alt="" width={20} height={20} />
                                                                                                            </button>
                                                                                                        )}
                                                                                                </div>
                                                                                                <div className="search-form">
                                                                                                    <Button
                                                                                                        className="search-Btn"
                                                                                                        tabindex="0"
                                                                                                        onClick={
                                                                                                            this
                                                                                                                .goHome
                                                                                                        }
                                                                                                    >
                                                                                                        Search
                                                                                                    </Button>
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    </Form>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </div>
                                                                    {" "}
                                                                    <p onClick={this.bannerLink.bind(this, item.bannerUrl)}></p>
                                                                </div>
                                                            </>
                                                        ))}
                                                </Slider>
                                            )}
                                        </div>
                                    </Banner>


                                   

                                    {/* section2 for recent listing */}
                                    <HomeProduct>
                                        <div
                                            className="home_product "
                                            id="categorydesk1"
                                            style={{
                                                ...(getCacheCategoryData.categoryId !==
                                                    undefined &&
                                                    getCacheCategoryData.categoryId !==
                                                    ""
                                                    ? { marginTop: "0px" }
                                                    : { marginTop: "0px" }),
                                            }}
                                        >
                                            <div className="rermode">
                                                <HomeFilter
                                                    clearFilter={
                                                        this.state.clearFilter
                                                    }
                                                    getCacheCategoryData={
                                                        categoryId
                                                    }
                                                    getLoadCount={pageCount}
                                                    getPriceData={min_max}
                                                    getSortByData={sort}
                                                    getDateByData={sortDate}
                                                    getCacheLocationData={
                                                        lat_lon
                                                    }
                                                    defaultUnit={
                                                        this.state.defaultUnit
                                                    }
                                                />
                                            </div>
                                            <ApolloConsumer>
                                                {(client) => (
                                                    <ProductConsumer>
                                                        {(value) => (
                                                            <Products
                                                                clearbyLocation={
                                                                    this.state
                                                                        .clearFilter
                                                                }
                                                                homePage={true}
                                                                currentUser={
                                                                    cUser
                                                                }
                                                                client={client}
                                                                getCacheCategoryData={
                                                                    categoryId
                                                                }
                                                                getCacheSearchInput={
                                                                    searchInput
                                                                }
                                                                getCacheLocationData={
                                                                    lat_lon
                                                                }
                                                                getCacheLocationNameData={
                                                                    locationName
                                                                }
                                                                getPriceData={
                                                                    min_max
                                                                }
                                                                min={min}
                                                                max={max}
                                                                getSortByData={
                                                                    sort
                                                                }
                                                                getDateByData={
                                                                    sortDate
                                                                }
                                                                getLoadCount={
                                                                    pageCount
                                                                }
                                                                getCacheRadiusData={
                                                                    radius
                                                                }
                                                                getLoadCountFilter={
                                                                    pageCountFilter
                                                                }
                                                                history={
                                                                    history
                                                                }
                                                                allproductsValue={
                                                                    value.mystate
                                                                }
                                                                userEditClicked={
                                                                    value.userEditClicked
                                                                }
                                                                userEditActivated={
                                                                    value.userEditActivated
                                                                }
                                                                PostProduct={
                                                                    value.PostProduct
                                                                }
                                                                postAnotherListing={
                                                                    value.postAnotherListing
                                                                }
                                                                CategorySubmittedinProducts={
                                                                    value.CategorySubmittedinProducts
                                                                }
                                                                categorySubmitted={
                                                                    value.categorySubmitted
                                                                }
                                                                stuffImage={
                                                                    value.stuffImage
                                                                }
                                                                stuffImageEdit={
                                                                    value.stuffImageEdit
                                                                }
                                                                AdvancedFiltersubmit={
                                                                    value.AdvancedFiltersubmit
                                                                }
                                                                FilterValue={
                                                                    value.FilterValue
                                                                }
                                                                AdvancedFilter={
                                                                    value.AdvancedFilter
                                                                }
                                                                showValue={
                                                                    value.showValue
                                                                }
                                                                postDone={
                                                                    value.postDone
                                                                }
                                                                defaultUnit={
                                                                    this.state
                                                                        .defaultUnit
                                                                }
                                                            />
                                                        )}
                                                    </ProductConsumer>
                                                )}
                                            </ApolloConsumer>
                                        </div>
                                    </HomeProduct>
                                    {/* section3 for vendor category */}


                                    <div
                                        className="nn_category test"
                                        id="categorydesk"
                                    >
                                        <ProductConsumer>
                                            {(value) => (
                                                <CategoryFilter
                                                    clearFilter={
                                                        this.state.clearFilter
                                                    }
                                                    getLoadCount={pageCount}
                                                    categoryList={
                                                        this.state.categoryList
                                                    }
                                                    AdvancedFiltersubmit={
                                                        value.AdvancedFiltersubmit
                                                    }
                                                    FilterValue={
                                                        value.FilterValue
                                                    }
                                                />
                                            )}
                                        </ProductConsumer>
                                    </div>}

                                    <div
                                        className="nn_category nn_category-vendor"
                                        id="categorydesk"
                                    >
                                        <ProductConsumer>
                                            {(value) => (
                                                <VendorCategories
                                                    clearFilter={
                                                        this.state.clearFilter
                                                    }
                                                    getLoadCount={pageCount}
                                                    categoryList={
                                                        this.state.categoryList
                                                    }
                                                    AdvancedFiltersubmit={
                                                        value.AdvancedFiltersubmit
                                                    }
                                                    FilterValue={
                                                        value.FilterValue
                                                    }
                                                />
                                            )}
                                        </ProductConsumer>
                                    </div>


                                    {/* section4 */}
                                    <HomeProduct>
                                        <div
                                            className="home_product"
                                            id="categorydesk1"
                                            style={{
                                                ...(getCacheCategoryData.categoryId !==
                                                    undefined &&
                                                    getCacheCategoryData.categoryId !==
                                                    ""
                                                    ? { marginTop: "50px" }
                                                    : { marginTop: "50px" }),
                                            }}
                                        >
                                            <div className="rermode">
                                                <HomeFilter
                                                    clearFilter={
                                                        this.state.clearFilter
                                                    }
                                                    getCacheCategoryData={
                                                        categoryId
                                                    }
                                                    getLoadCount={pageCount}
                                                    getPriceData={min_max}
                                                    getSortByData={sort}
                                                    getDateByData={sortDate}
                                                    getCacheLocationData={
                                                        lat_lon
                                                    }
                                                    defaultUnit={
                                                        this.state.defaultUnit
                                                    }
                                                />
                                            </div>
                                            <ApolloConsumer>
                                                {(client) => (
                                                    <ProductConsumer>
                                                        {(value) => (
                                                            <Gigs
                                                                clearbyLocation={
                                                                    this.state
                                                                        .clearFilter
                                                                }
                                                                homePage={true}
                                                                currentUser={
                                                                    cUser
                                                                }
                                                                client={client}
                                                                getCacheCategoryData={
                                                                    categoryId
                                                                }
                                                                getCacheSearchInput={
                                                                    searchInput
                                                                }
                                                                getCacheLocationData={
                                                                    lat_lon
                                                                }
                                                                getCacheLocationNameData={
                                                                    locationName
                                                                }
                                                                getPriceData={
                                                                    min_max
                                                                }
                                                                min={min}
                                                                max={max}
                                                                getSortByData={
                                                                    sort
                                                                }
                                                                getDateByData={
                                                                    sortDate
                                                                }
                                                                getLoadCount={
                                                                    pageCount
                                                                }
                                                                getCacheRadiusData={
                                                                    radius
                                                                }
                                                                getLoadCountFilter={
                                                                    pageCountFilter
                                                                }
                                                                history={
                                                                    history
                                                                }
                                                                allproductsValue={
                                                                    value.mystate
                                                                }
                                                                userEditClicked={
                                                                    value.userEditClicked
                                                                }
                                                                userEditActivated={
                                                                    value.userEditActivated
                                                                }
                                                                PostProduct={
                                                                    value.PostProduct
                                                                }
                                                                postAnotherListing={
                                                                    value.postAnotherListing
                                                                }
                                                                CategorySubmittedinProducts={
                                                                    value.CategorySubmittedinProducts
                                                                }
                                                                categorySubmitted={
                                                                    value.categorySubmitted
                                                                }
                                                                stuffImage={
                                                                    value.stuffImage
                                                                }
                                                                stuffImageEdit={
                                                                    value.stuffImageEdit
                                                                }
                                                                AdvancedFiltersubmit={
                                                                    value.AdvancedFiltersubmit
                                                                }
                                                                FilterValue={
                                                                    value.FilterValue
                                                                }
                                                                AdvancedFilter={
                                                                    value.AdvancedFilter
                                                                }
                                                                showValue={
                                                                    value.showValue
                                                                }
                                                                postDone={
                                                                    value.postDone
                                                                }
                                                                defaultUnit={
                                                                    this.state
                                                                        .defaultUnit
                                                                }
                                                            />
                                                        )}
                                                    </ProductConsumer>
                                                )}
                                            </ApolloConsumer>
                                        </div>
                                    </HomeProduct>
                                    {/* <Container className="addvertinghere mt-5 pt-md-5">
                                        <Grid >
                                            <GridItem xs={12} sm={12} md={12} className="mb-lg-0 mb-5">
                                                <img src={advetiser} alt="" className="img-fluid" />
                                            </GridItem>
                                        </Grid>
                                    </Container> */}

                                    <AboutArea openSidePanel={() => {
                                        this.setState({
                                            isPaneOpen: true,
                                        });
                                    }}></AboutArea>
                                    {/* <Container className="addvertinghere mt-4">
                                        <Grid className="row" spacing={2}>
                                            <GridItem item xs={6} sm={12} md={6} className="mb-lg-0 mb-4">
                                                <img src={advetiser} alt="" className="img-fluid" />
                                            </GridItem>
                                            <GridItem item xs={6} sm={12} md={6} className="mb-lg-0">
                                                <img src={advetiser} alt="" className="img-fluid" />
                                            </GridItem>
                                        </Grid>
                                    </Container> */}
                                    {cUser && recentProducts && (
                                        <RecentListing recentProducts={recentProducts} ></RecentListing>
                                    )}
                                    <ApolloConsumer>
                                        {(client) => (
                                            <ProductConsumer>
                                                {(value) => (
                                                    <Footer
                                                        openSidePanel={() => {
                                                            this.setState({
                                                                isPaneOpen: true,
                                                            });
                                                        }}
                                                        getCategory={
                                                            this.setCategory
                                                        }
                                                        AdvancedFiltersubmit={
                                                            value.AdvancedFiltersubmit
                                                        }
                                                        FilterValue={
                                                            value.FilterValue
                                                        }
                                                        AdvancedFilter={
                                                            value.AdvancedFilter
                                                        }
                                                        client={client}
                                                    />
                                                )}
                                            </ProductConsumer>
                                        )}
                                    </ApolloConsumer>
                                    {/* <ScrollTop>
                                        <div
                                            className={
                                                cUser && cUser.id
                                                    ? "anchor-fixed cls_loganchor"
                                                    : "anchor-fixed"
                                            }
                                            onClick={this.scrollToTop}
                                        >
                                            <a>
                                                <span>
                                                    {" "}
                                                    <i
                                                        className="fa fa-chevron-up"
                                                        aria-hidden="true"
                                                    ></i>
                                                </span>{" "}
                                            </a>
                                        </div>
                                    </ScrollTop> */}
                                </div>
                            )}
                        </div>
                    </div>
                </ProviderRefech>
            </Main>
        );
    }
}

var DashboardComponent = compose(
    graphql(GET_ALL_PRODUCTS, {
        name: "productsInfo",
        options: () => ({
            variables: {
                filter: {},
            },
        }),
    }),
    graphql(GET_USER, { name: "getUser" }),
    graphql(GET_CATEGORIES, {
        name: "categoryInfo",
    }),
    graphql(GET_SITE_INFO, { name: "siteInfo" }),

    graphql(UPDATE_CHAT_GROUP, "updateChatGroup"),
    graphql(GET_CURRENT_USER, {
        name: "currentUser",
    }),
    graphql(INACTIVE, { name: "inActiveScreen" }),
    graphql(ISOPEN, { name: "isOpenScreen" }),
    graphql(GET_CACHE_STATE, {
        name: "getCacheData",
        options: () => ({
            fetchPolicy: "cache-only",
        }),
    }),
    graphql(CATEGORY_ID, { name: "getCategoryId" }),
    graphql(SEARCH_INPUT, { name: "searchResult" }),
    graphql(GET_CATEGORY_ID, {
        name: "getCacheCategoryData",
        options: () => ({
            fetchPolicy: "cache-only",
        }),
    }),

    graphql(GET_SEARCH_INPUT, {
        name: "getCacheSearchInput",
        options: () => ({
            fetchPolicy: "cache-only",
        }),
    }),
    graphql(GET_REDIRECT_STATE, {
        name: "getLoadCount",
        options: () => ({
            fetchPolicy: "cache-only",
        }),
    }),

    graphql(LOCATION, { name: "getLocation" }),
    graphql(GET_LOCATION, {
        name: "getCacheLocationData",
        options: () => ({
            fetchPolicy: "cache-only",
        }),
    }),
    graphql(PRICE, { name: "getPrice" }),
    graphql(GET_PRICE_DETAILS, {
        name: "getPriceData",
        options: () => ({
            fetchPolicy: "cache-only",
        }),
    }),
    graphql(GET_CURRENCIES, { name: "currencyInfo" }),
    graphql(SORTBY, { name: "getSortBy" }),
    graphql(GET_SORTBY_DETAILS, {
        name: "getSortByData",
        options: () => ({
            fetchPolicy: "cache-only",
        }),
    }),
    graphql(DATEBY, { name: "getDateBy" }),
    graphql(GET_DATEBY_DETAILS, {
        name: "getDateByData",
        options: () => ({
            fetchPolicy: "cache-only",
        }),
    }),
    graphql(GET_REDIRECTFILTER_STATE, {
        name: "getLoadCountFilter",
        options: () => ({
            fetchPolicy: "cache-only",
        }),
    }),
    graphql(RADIUS, { name: "getRadius" }),
    graphql(GET_RADIUS, {
        name: "getCacheRadiusData",
        options: () => ({
            fetchPolicy: "cache-only",
        }),
    }),
    graphql(LOCATION_NAME, { name: "getLocationName" }),
    graphql(GET_LOCATION_NAME, {
        name: "getLoactionNameData",
        options: () => ({
            fetchPolicy: "cache-only",
        }),
    }),
)(Dashboard);

export default withStyles(pagesStyle)(DashboardComponent);
