import React, { useEffect, useState } from 'react';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Card from '../../../components/Card/Card';
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import { MailOutline } from '@material-ui/icons';
import CardBody from '../../../components/Card/CardBody';
import { withStyles } from "@material-ui/core/styles";
import { FormLabel  } from 'react-bootstrap';
import CKEditor from "react-ckeditor-component";
import PictureUpload from "../../../components/CustomUpload/ImageUpload.jsx";
import Button from '../../../components/CustomButtons/Button.jsx';
import Input from "@material-ui/core/Input";
import axios from 'axios'
import { withRouter } from 'react-router-dom'; 
const styles = {
    cardIconTitle: {
        // Add your styles here
    },
    labelHorizontal: {
        // Add your styles here
    }
};

const AddBlog = ({ classes,history,location  }) => {
    const { REACT_APP_ADMIN_PATH,REACT_APP_Domain_Url } = process.env;

    const id = location.search.substring(1);

    const [blogDetails, setBlogDetails] = useState(null)

    const [formData, setFormData] = useState({
        id: id,
        title: '',
        content: '',
        attachment: null
    });

    useEffect(() =>{
        if(id){
            getBlogDetails()
        }
    },[id])

    const getBlogDetails = async () =>{
        try {
        const res = await axios.post(REACT_APP_Domain_Url+"admin/getPostById", {post_id: id});
        if(res.data.status == 200){
            setFormData({
                ...formData, 'title' : res.data.post.title,'content' : res.data.post.content, attachment : REACT_APP_Domain_Url +  res.data.post.image
            })
            setBlogDetails(res.data.post)
        }else{
            setBlogDetails(null)
        }
        } catch (error) {
            console.log(error)
        }
    }

    console.log("blogDetails",blogDetails)
    console.log("formData",formData)

    const handleEditorChange = (e) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            content: e.editor.getData()
        }));
    };

    const handleImageUpload = (fieldName, file, event) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            attachment: file
        }));
    };

    const handleSubmit = async  (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('post_id', formData.id);
        formDataToSend.append('title', formData.title);
        formDataToSend.append('content', formData.content);
        formDataToSend.append('attachment', formData.attachment);
        try {
            const response = await axios.post(REACT_APP_Domain_Url +`admin/${id ? 'update_post' : 'createPost'}`, formDataToSend);
            history.push(`${REACT_APP_ADMIN_PATH}/bloglist`)
            setFormData({
                id: '',
                title: '',
                content: '',
                attachment: null
            });
        } catch (error) {
            console.error('Error adding blog:', error);
        }
    };



    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <MailOutline />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>
                            Add Blog
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={handleSubmit}>
                            <GridContainer>
                                <GridItem xs={12} sm={2}>
                                    <FormLabel className={`${classes.labelHorizontal} float-right`}>
                                        Title<span className="validatcolor">*</span>
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={10}>
                                <Input
                                    name="title"
                                    label="Title"
                                    variant="outlined"
                                    fullWidth
                                    value={formData.title}
                                    onChange={(e)=>setFormData({...formData, title: e.target.value})}
                                />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={2}>
                                    <FormLabel className={`${classes.labelHorizontal} float-right`}>
                                        Description<span className="validatcolor">*</span>
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={10}>
                                    <CKEditor
                                        activeClass="p10"
                                        content={formData.content}
                                        events={{
                                            change: handleEditorChange
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={3}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Feature Image
                                        <span className="validatcolor">*</span>
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={7}>
                                    <PictureUpload
                                        mode={"edit"}
                                        imageUrl={formData.attachment}
                                        handleSubmit={(f, e) => handleImageUpload("attachment", f, e)}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer justify="flex-end">
                                <Button type="submit" color="rose">
                                   {id ? "Update" : "Submit"}
                                </Button>
                            </GridContainer>
                        </form>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
};

export default withStyles(styles)(withRouter(AddBlog));