import React, { Component, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import axios from "axios";
import { ScrollTop, ProductDetailsMain, Loader, DiscardPopup, FeaturedModal, AdvancedFilter1, AdvancedFilter } from '../css/styledcomponents';
import { ProviderRefech, ProductConsumer } from "../ProductContext.js";
import Footer from "../Footer/Footer";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Card from "react-bootstrap/Card";
import gigs from "../../../assets/img/imageGigs.png";
import GradeIcon from "@mui/icons-material/Grade";
import { Checkbox, Select } from "@material-ui/core";
import InputRange from "react-input-range-rtl";
import vendorconstant from "../../vendorconstant";

class FilterVendor extends Component {

 
  constructor(props) {
    super(props);
    this.state = {
      gigsData : [] , 
      baseUrl : "",
      imagesArray : [],
      vendorData : [],
      filterData : [],
      value: {  max: 10000, min: 0 },
      openChat: false,
      stateData : [],
      stateChangeData : "",
      vendorBaseUrl : ""

    };
  }
  componentDidMount() {
    console.log("",this.state.value)
    if(localStorage.getItem('FILTER_DATA') != null){
      this.state.filterData.push(localStorage.getItem('FILTER_DATA'))
    }
const dataString={
  type : "allcategory"
}
    axios
    .post(vendorconstant.API_URL+"frontendgigs/getvendorcatdata" , dataString)
    .then((response) => {
       console.log("tyu",response)
       const vendordata = response.data.data
          this.setState({vendorData:vendordata})
    })
    .catch((error) => {
      console.log(error);
    });
   const datastring ={
      "categoryIds":this.state.filterData,
      "pricerange": this.state.value,
      "locationfilter": this.state.stateChangeData
    }
    axios
      .post(vendorconstant.API_URL+"frontendgigs/getallgigsdata",datastring)
      .then((response) => {
        const gigsdata = response.data.data
        const imagesarray = response.data.data.gigImages
        const baseurl = response.data.baseurl
        this.setState({gigsData:gigsdata})
        this.setState({baseUrl : baseurl})
        this.setState({imagesArray:imagesarray})
        this.setState({vendorBaseUrl : response.data.vendorbaseurl})
       
      })
      .catch((error) => {
          console.log(error);
      })


      const dataString1={

      }
      axios
      .post(vendorconstant.API_URL+"frontendgigs/getstatesdata",dataString1 )
      .then((response) => {
        if(response.data.status == "success"){
          this.setState({stateData:response.data.data})
        }
      })
      .catch((error) => {
        console.log(error);
      });

}

 addfilterdata=(id,e)=>{
  
 
if(e.target.checked == true){
  this.state.filterData.push(id)

}
else{
  var index = this.state.filterData.indexOf(id);
  if (index > -1) {
    this.state.filterData.splice(index, 1);
  }

}

 }

  savefilter=()=>{
    console.log(this.state.stateChangeData)
const datastring ={
  "categoryIds":this.state.filterData,
  "pricerange": this.state.value,
  "locationfilter": this.state.stateChangeData
}
    axios
    .post(vendorconstant.API_URL+"frontendgigs/getallgigsdata" , datastring)
    .then((response) => {
      const gigsdata = response.data.data
      const imagesarray = response.data.data.gigImages
      const baseurl = response.data.baseurl
      this.setState({gigsData:gigsdata})
      this.setState({baseUrl : baseurl})
      this.setState({imagesArray:imagesarray})
     
    })
    .catch((error) => {
        console.log(error);
    });
 }
 
  
  render() {
    let { classes } = this.props;

    const gigsdata = [
      {
        image: gigs,
        name: "Henery",
        amount: "$10",
        description: "Lorem Ipsum is simply dummy text of the printing...",
      },
      {
        image: gigs,
        name: "Henery",
        amount: "$10",
        description: "Lorem Ipsum is simply dummy text of the printing...",
      },
      {
        image: gigs,
        name: "Henery",
        amount: "$10",
        description: "Lorem Ipsum is simply dummy text of the printing...",
      },
      {
        image: gigs,
        name: "Henery",
        amount: "$10",
        description: "Lorem Ipsum is simply dummy text of the printing...",
      },
      {
        image: gigs,
        name: "Henery",
        amount: "$10",
        description: "Lorem Ipsum is simply dummy text of the printing...",
      },
    ]; 
    const maxStars = 5; 

    const stars = [];
    for (let i = 0; i < maxStars; i++) {
      if (i < 3) {
        stars.push(<GradeIcon key={`colored-${i}`} style={{ color: "#E99318" }} />);
      } else {
        stars.push(<GradeIcon key={`gray-${i}`} style={{ color: "#A4A4A4" }} />);
      }
    }

    return (
    <>
        <div className="catepro-outer">
          <Container>
            <div className="section-heading">
              <div className="section-tittle mb-md-5  mb-4">
                <h2 className="tittle ">
                  <span class="shape"></span>Vendor Categories Name
                </h2>
              </div>
            </div>
          </Container>

          <div className="productmain">
            <Container>
              <div className=" productctn">
                {/* <div className="nnnn_adfilter">
                  <AdvancedFilter id="srcFil" className="cls_overflowx">
                    <div className="filertcler">
                      <p className='filterNAme'>FILTERS </p>
                      <div className="sav_chang">
                        <button
                          type="submit"
                          className='cleBtmn'

                        >
                        </button>
                      </div>
                    </div>
                    <section>
                      <div className="nn_fliterctn">
                        <div className="nn_fltproctn">Categories</div>
                       {this.state.vendorData && this.state.vendorData.length > 0 ?
                        this.state.vendorData.map((value)=>(
                        <>
                        <FormControlLabel className="labelName-filter"
                          control={
                            <Checkbox
                            onClick={(e) => this.addfilterdata(value._id, e)} 
                              defaultChecked = {this.state.filterData == value._id ?true:""}
                              classes={{
                                root: classes.root,
                                checked: classes.checked + " nn_adlabel",
                              }}
                            />
                          }
                          label={value.name}
                        />
                        </>)):""}
                      
                      </div>
                    </section>
                    <section>
                      <div>
                        <div className="dPKTsi" >
                          <div className="ewsakU yesrsnover nn_fliterctn">
                            <div className="fIVfGS">
                              <div className=""> </div>
                              <div className="yearway respmileage nn_fltproctn">
                                Filter By Price
                              </div>
                              <div className="rightyesd reskm">
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        <div className="inpydeanrr">
                          <InputRange
                            minValue={0}
                            maxValue={10000}
                          
                           value={this.state.value}
                           onChange={value => this.setState({ value })} 
                          />
                        
                        </div>
                      </div>
                    </section>
                    <section>
                      <div>
                        <div className="dPKTsi" >
                          <div className="ewsakU yesrsnover nn_fliterctn">
                            <div className="fIVfGS">
                              <div className=""> </div>
                              <div className="yearway respmileage nn_fltproctn mb-2">
                                Filter By Location
                              </div>
                              <div className="rightyesd reskm">
                              <select className="form-control" onChange={(e)=>{this.setState({stateChangeData: e.target.value})}} >
                        <option value="">Select Location</option>
                        {this.state.stateData.map((value)=>(
                        <option value={value.name}>{value.name}</option>
                        ))}
                      </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </section >

                    <div className="border-topB btn-wrapper mt-5">
                      <button
                        type="submit"
                         onClick={this.savefilter}
                        className="cmn-btn2 w-100"
                      >
                        Save Filters
                      </button>
                    </div >
                  </AdvancedFilter >
                </div> */}
                <div className="right-vendro_filter1 ">
                  <Row>
                    {this.state.gigsData.map(( value , image ) => (
                    
                        <Col lg={4} md={4} sm={6} className="mb-4">
                           <Link to={'/category-vendor/'+value._id}>
                          <div
                            className="gigs-cat-outer">
                            <div
                              className="nn_homproductctn">
                             
                                <Card.Img variant="top" src={value.gigImages.length > 0  ?this.state.baseUrl + value.gigImages[0].image:gigs} />
                          
                            </div>
                            <div className="allprodetails card-content">
                                
                              <div className="titlegigs-cat">
                                <div className="inner-catgigstitle">
                                  <div className="d-flex align-items-center">
                                     {/* <AccountCircleIcon style={{ fontSize: "30px", marginRight: "5px", color: "#556080" }} /> */}
                                     {value.vendorid.image != null  ?<Card.Img variant="top" src={this.state.vendorBaseUrl + value.vendorid.image} width={15} height={50}/>
                                     :<AccountCircleIcon style={{ fontSize: "30px", marginRight: "5px", color: "#556080" }} />}
                                    
                                   <h4>{value.title.slice(0,20)}</h4>
                                  </div>
                                  <h6> ${value.price}</h6>
                                </div>

                                {/* <p>{value.description.slice(0,40)}</p> */}

                              </div>
                              {value.ratings > 0 ?
                              <div className="d-flex">
                              {Array.from({ length: 5 }, (_, i) =>
                      i < value.ratings ? (
                        <GradeIcon key={`colored-${i}`} style={{ color: "#E99318" }} />
                      ) : (
                        <GradeIcon key={`gray-${i}`} style={{ color: "#A4A4A4" }} />
                      )
                    )}
                               
                               
                            
                              </div>:""}
                            </div>
                          </div>
                          </Link>
                        </Col>
                      
                      ))}
                  </Row>
                </div>
              </div >
            </Container >
          </div >

        </div >

      <Footer />
    </>
    );
  }
}
export default FilterVendor;
