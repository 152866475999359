import React from "react";
import PropTypes from "prop-types";
import { compose, graphql } from "react-apollo";
import axios from "axios";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import UsersIcon from "@material-ui/icons/SupervisorAccount";
import ProductsIcon from "@material-ui/icons/ShoppingBasket";
import SoldIcon from "@material-ui/icons/ShoppingCart";
// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardIcon from "../../../components/Card/CardIcon.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import CardFooter from "../../../components/Card/CardFooter.jsx";
// style component
import dashboardVendorStyle from "../../../assets/jss/material-dashboard-pro-react/components/dashboardVendorStyle.jsx";
import {GET_ALL_USERS, GET_ALL_ADMIN_PRODUCTS, GET_ALL_VENDORs, GET_ALL_VENDORS} from "../../../queries";
import vendorconstant from "../../vendorconstant.js";
import { CategoryIcon, UserIcon } from "../../../routes/ImportTitles.js";
import { CardMembership, PaymentOutlined } from "@material-ui/icons";
const { REACT_APP_ADMIN_PATH } = process.env;


var styles = {
  ...dashboardVendorStyle,
  smallButton: {
    backgroundColor: "white",
    color: "green",
    border: "solid 1px",
    "&:hover": {
      backgroundColor: "#22c4d8 !important",
      color: "white !important",
      borderColor: "#22c4d8 !important"
    }
  }
}
var todayCount = function(data, type) {
  var filtered = data.filter(function (data) {
    var date = new Date();
    let dateList = type === "sold" ? data.sellingTimeStamp ? data.sellingTimeStamp : 0 : data.createdAt;
    return (dateList >= date.setHours(0,0,0) && dateList <= date.setHours(23,59,59));
  });
  return filtered && filtered.length;
};

class dashboardVendor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        dashboardData:""
    };
}


  componentDidMount=()=>{
    axios
          .get(vendorconstant.API_URL+"admin/dashboardvendorcount")
          .then((response) => {
            this.setState({dashboardData:response.data})
          })
          .catch((error) => {
              console.log(error);
          });
          
  }
  render() {
    const { classes, usersInfo, productInfo } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                <UserIcon />
                </CardIcon>
                <h3 className={classes.cardTitle}>{this.state.dashboardData.vendorcount}</h3>
                <p className={classes.cardCategory}>Total Vendor</p>
              </CardHeader>
              <CardFooter stats >
              <div>
              <Button
              className={classes.smallButton}
              size="sm"
              onClick={() => this.props.history.push(`${REACT_APP_ADMIN_PATH }/vendor`)}>More Info</Button>
              </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                <ProductsIcon />
                </CardIcon>
                <h3 className={classes.cardTitle}>{this.state.dashboardData.gigscount}</h3>
                <p className={classes.cardCategory}>Total Services</p>
              </CardHeader>
              <CardFooter stats >
              <div>
              <Button
              className={classes.smallButton}
              size="sm"
              onClick={() => this.props.history.push(`${REACT_APP_ADMIN_PATH }/gigs`)}>More Info</Button>
              </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                <CategoryIcon />
                </CardIcon>
                <h3 className={classes.cardTitle}>{this.state.dashboardData.Categoriescount}</h3>
                <p className={classes.cardCategory}>Total Categories</p>
              </CardHeader>
              <CardFooter stats >
              <div>
              <Button
              className={classes.smallButton}
              size="sm"
              onClick={() => this.props.history.push(`${REACT_APP_ADMIN_PATH }/vendor-categories`)}>More Info</Button>
              </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                <PaymentOutlined />
                </CardIcon>
                <h3 className={classes.cardTitle}>${this.state.dashboardData.transactionscount}</h3>
                <p className={classes.cardCategory}>Total Membership Payment</p>
              </CardHeader>
              <CardFooter stats >
              <div>
              <Button
              className={classes.smallButton}
              size="sm"
              onClick={() => this.props.history.push(`${REACT_APP_ADMIN_PATH }/vendor`)}>More Info</Button>
              </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                <PaymentOutlined />
                </CardIcon>
                {/* <h3 className={classes.cardTitle}>${this.state.dashboardData.transactionscount}</h3> */}
                <p className={classes.cardCategory}> Vendor Intro Section</p>
              </CardHeader>
              <CardFooter stats >
              <div>
              <Button
              className={classes.smallButton}
              size="sm"
              onClick={() => this.props.history.push(`${REACT_APP_ADMIN_PATH }/vendor-intro`)}>More Info</Button>
              </div>
              </CardFooter>
            </Card>
          </GridItem>
             </GridContainer>
      </div>
    );
  }
}

dashboardVendor.propTypes = {
  classes: PropTypes.object.isRequired
};
var dashboardvendor = compose(
    graphql(GET_ALL_USERS, {
       name: "usersInfo"
    }),
    graphql(GET_ALL_ADMIN_PRODUCTS, {
       name: "productInfo",
       options: () => ({
        variables: {
          filter: {}
        }
      })
    }),
  )(dashboardVendor);
// var dashboardVendor = compose(
//   graphql(GET_ALL_VENDORS, {
//      name: "vendorInfo"
//   }),
//   graphql(GET_ALL_VENDORS, {
//      name: "vendorInfo",
//      options: () => ({
//       variables: {
//         filter: {}
//       }
//     })
//   }),
// )(dashboardVendor);


export default withStyles(styles)(dashboardvendor);
