import React, { useState, useEffect } from "react";
import Footer from "../Footer/Footer";
import { ScrollTop } from "../css/styledcomponents";
import { Col, Container, Row } from "react-bootstrap";
import axios from 'axios'
import { Link } from "react-router-dom";
const Blog = () => {
  const {REACT_APP_Domain_Url} = process.env;
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    getBlogListFun()
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, []);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [blogsList, setBlogsList] = useState([])
  const getBlogListFun = async () =>{
    try {
      const res = await axios.post(REACT_APP_Domain_Url+"admin/getAllPosts");
      if(res.data.status == 200){
        setBlogsList(res.data.posts)
      }else{
        setBlogsList([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
    <div className="blog-listing">
      <Container>
        <Row className="justify-content-center">
          <Col md={10} xl={8} lg={7} sm={10}>
            <div className="section-tittle text-center mb-50">
              <h2 className="tittle">
                <span class="shape"></span>Blog
              </h2>
            </div>
          </Col>
        </Row>
        <div className="row">
          {
            blogsList.length > 0 ? 
                <>
                {
                  blogsList.map((item) =>(
                    <div className="col-lg-4 col-md-6 col-xs-12 mb-4">
                      <div className="blog-item">
                        <Link to={`/blog-details/${item._id}`} data-cursor-class="cursor-link">
                          <figure className="image-holder">
                            <img src={REACT_APP_Domain_Url+item.image} />
                          </figure>
                        </Link>
                        <div className="card-details-blog">
                          <div className="date-line">{item.created_at}</div>
                          <h4 className="title">
                            <Link className="-unlink" to={`/blog-details/${item._id}`}>{item.title} </Link>
                          </h4>

                          <Link className="button-text" to={`/blog-details/${item._id}`}>
                            Read More
                            <svg
                              class="default"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z"></path>
                            </svg>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                }
                </>
                :
                <></>
          }
        </div>
      </Container>
      
    </div>
    <div className="newcontacrf">
        <Footer />
      </div>
      {showScroll && (
        <ScrollTop>
          <div className="anchor-fixed" onClick={scrollToTop}>
            <span>
              {" "}
              <i className="fa fa-chevron-up" aria-hidden="true"></i>
            </span>{" "}
          </div>
        </ScrollTop>
      )}
    </>
  );
};

export default Blog;