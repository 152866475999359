import styles from "../../../assets/jss/material-dashboard-pro-react/components/loginComponent.jsx";
import React from "react";
import Footer from "../Footer/Footer";
import { withStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { compose, graphql } from "react-apollo";
import { GET_CATEGORIES } from "../../../queries";
import { Col, Container, Row } from "react-bootstrap";
import Products from "./Products.jsx";

class ProductCategoryByID extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
        };
    }

    componentDidMount() {
        let { categoryInfo } = this.props;
        let categories = [];
        if (localStorage.getItem("lang")) {
            categoryInfo.refetch().then(({ data }) => {
                if (data) {
                    categories = data.getCategoryDetails && data.getCategoryDetails.category;
                    this.setState({
                        categories: categories
                    })
                }
            }).catch(e => console.log(e));
        }
    }

    render() {
        let { categories } = this.state;
        return (
            <div>
                <div className="catepro-outer">
                    <Container>
                        <div className="section-heading">
                            <Row>
                                <Col md={10} xl={8} lg={7} sm={10}>
                                    <div className="section-tittle">
                                        <h2 className="tittle ">
                                            <span class="shape"></span>
                                            {categories && categories.filter(x => x.id == this.props.match.params.id).map(v => {
                                                return v.name
                                            })}
                                        </h2>
                                    </div>
                                    <p>
                                        {categories && categories.filter(x => x.id == this.props.match.params.id).map(v => {
                                            return v.description
                                        })}
                                    </p>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    <Products getCacheCategoryData={this.props.match.params.id} />

                </div>
                <div className="newcontacrf">
                    <Footer />
                </div>
            </div>
        );
    }
}

var CategoryFilter = compose(
    graphql(GET_CATEGORIES, {
        name: "categoryInfo",
        options: () => ({
            fetchPolicy: "no-cache",
        }),
    })
)(ProductCategoryByID);

export default withTranslation("common")(withStyles(styles)(CategoryFilter));
