import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from "../../components/CustomButtons/Button";
import aboutImg from "./../../assets/img/aboutShape1.png";
import aboutImg2 from "./../../assets/img/aboutShape2.png";


function AboutArea(props) {

    return (
        <div className="aboutArea section-padding sectionBg1 pb-4 pt-4">
            <Container>
                <div className="position-relative">
                    <Row className="justify-content-center">
                        <Col xl={7} lg={6}>
                            <div className="about-caption about-caption2   text-center">
                                {/* <div className="section-tittle section-tittle2 mb-55">
                                    <h2 className="tittle">Sell or find<br></br>
                                        Your Next Party Decor
                                    </h2>
                                    <p>How many times have you thrown a party and after it’s all over you realize that you bought more party items than needed? Beautiful tableware, balloons, and creative party favors are now a permanent fixture in your garage or attic space!
                                        With MyPlo, you can easily sell your things and make some extra cash while clearing out your space.</p>
                                    <br />
                                    <p>Go with Plo  because our commitment to excellence starts with you.</p>
                                    <br />
                                    <p>MyPlo isn’t just a used party supplies platform, we’re a community! We created MyPlo with you in mind and we strive to provide our users with unmatched value. Not only can you sell and buy with us you can also share your party planning journey as well. Tag us on social media and let us see all the fun party leftovers you snagged.</p>
                                </div> */}
                                <div className="btn-wrapper">
                                    <Button onClick={(e) => { props.openSidePanel() }} className="cmn-btn2 mr-15 mb-10">
                                    Upload Your Party Leftovers 
                                    </Button>
                                    <Link to={"/categories"} className="cmn-btn2 mr-15 mb-10">
                                    Browse Party Leftovers 
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* <div className="aboutShape aboutShape1">
                        <img src={aboutImg} alt="img" className="bouncingAnimation"></img>
                    </div>
                    <div className="aboutShape aboutShape2">
                        <img src={aboutImg2} alt="img" className="bouncingAnimation"></img>
                    </div> */}
                </div>
            </Container>
        </div>
    );
}


export default AboutArea;