import styles from "../../../../assets/jss/material-dashboard-pro-react/components/loginComponent.jsx";
import React,{useEffect , useRef} from 'react';
import Footer from '../../Footer/Footer';
import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { animateScroll as scroll } from "react-scroll";
import { compose, graphql } from "react-apollo";
import { GET_CATEGORIES } from '../../../../queries';
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ScrollTop } from "../../css/styledcomponents.js";
import product from '../../../../assets/img/vendorimg.png';
import product2 from '../../../../assets/img/vendor2.png';
import product3 from '../../../../assets/img/vendor1.png';
import product4 from '../../../../assets/img/vendor3.png';
import product5 from '../../../../assets/img/vendor4.png';
import axios from "axios";
import vendorconstant from "../../../vendorconstant.js";

const initialState = {
    currentUser :{}
  }

class VendorSubCategoriesComponent extends React.Component {
	constructor(props) {
		
		super(props);
		this.state = {
			cUser: {},
			vendorData:[],
			baseUrl:'',
			vendor: [
				{
					image: product,
					name: "Anniversary"
				},
				{
					image: product3,
					name: "Baby Shower"
				},
				{
					image: product2,
					name: "Carnival"
				},
				{
					image: product4,
					name: "Bridal Shower"
				},
				{
					image: product5,
					name: "Party"
				},
				{
					image: product,
					name: "Anniversary"
				},
				{
					image: product3,
					name: "Baby Shower"
				},
				{
					image: product2,
					name: "Carnival"
				},
				{
					image: product4,
					name: "Bridal Shower"
				},
				{
					image: product5,
					name: "Party"
				},
				{
					image: product,
					name: "Anniversary"
				},
				{
					image: product3,
					name: "Baby Shower"
				},
				{
					image: product2,
					name: "Carnival"
				},
				{
					image: product4,
					name: "Bridal Shower"
				},
				{
					image: product5,
					name: "Party"
				},
				{
					image: product,
					name: "Anniversary"
				},
				{
					image: product3,
					name: "Baby Shower"
				},
				{
					image: product2,
					name: "Carnival"
				},
				{
					image: product4,
					name: "Bridal Shower"
				},
				{
					image: product5,
					name: "Party"
				}
				
			]
		}
		this.scrollToTop = this.scrollToTop.bind(this);
	}
		componentScroll = () => {
		const scope = this;
		var winheight =
			window.innerHeight ||
			(document.documentElement || document.body).clientHeight;
		var D = document;
		var docheight = Math.max(
			D.body.scrollHeight,
			D.documentElement.scrollHeight,
			D.body.offsetHeight,
			D.documentElement.offsetHeight,
			D.body.clientHeight,
			D.documentElement.clientHeight,
		);
		var scrollTop =
			window.pageYOffset || document.documentElement.scrollTop;
		var trackLength = docheight - winheight;
		var pctScrolled = Math.floor((scrollTop / trackLength) * 100);
		if (pctScrolled > 10) {
			scope.setState({
				showScroll: true,
			});
		} else {
			scope.setState({
				showScroll: false,
			});
		}
	};

	componentWillUnmount() {
		window.removeEventListener(
			"scroll",
			() => {
				this.componentScroll();
			},
			true,
		);
	}

	scrollToTop() {
		scroll.scrollToTop();
	}
	componentDidMount() {
      
        console.log("pyyee")
const dataString ={
	parentcat : this.props.match.params.id,
}
			axios
				.post(vendorconstant.API_URL+"admin/getvendorsubcatdata" ,dataString)
				.then((response) => {
				   console.log("tyu",response)
				   const vendordata = response.data.data
				   const baseurl = response.data.baseurl
		          this.setState({vendorData:vendordata})
				  this.setState({baseUrl:baseurl})
				})
				.catch((error) => {
					console.log(error);
				});
		let { categoryInfo } = this.props;
		let categories = [];
		if (localStorage.getItem("lang")) {
			categoryInfo.refetch().then(({ data }) => {
				if (data) {
					categories = data.getCategoryDetails && data.getCategoryDetails.category;
					this.setState({
						categories: categories
					})
				}
			}).catch(e => console.log(e));
		}
		window.addEventListener(
			"scroll",
			() => {
				this.componentScroll();
			},
			true
		);
	}
	render() {
		let { vendor, cUser } = this.state;
		const { showScroll } = this.state;
		return (
          
			<div>
				<div className="explore">
					<Container>
						<Row className="justify-content-center">
							<Col md={10} xl={8} lg={7} sm={10}>
								<div className="section-tittle text-center mb-50">
									<h2 className="tittle">
										<span className="shape"></span> Vendor SubCategories
									</h2>
								</div>
							</Col>
						</Row>
						<div className="nn_categorylist w-100 catefullpages">
							<ul className="vendorOutercat" ref={(cate) => { this.cate = cate }}>
								{this.state.vendorData.map((c, i) => {
									return (
										<li key={c.id} ref={(li) => { this.li = li }} className={"vendorOutercatlist"} title={c.name} >
												<Link to={{ pathname: "/filter-vendor"}} onClick={(e) => 	localStorage.setItem('FILTER_DATA', c.parent)}>
											<div className="nn_categoryctn text-center">
													<img
													src={c.image != null ? this.state.baseUrl + c.image:product}
													alt=""
													className="cateimg category-icon"
													style={{"borderRadius":"50%"}}
													/>
													<h3>{c.name.length > 16 ? c.name.slice(0, 15) + "..." : c.name}</h3>
												</div>
												</Link>
										</li>
									)
								})}
							</ul>
						</div>
					</Container>
					{showScroll && (
						<ScrollTop>
							<div
								className={
									cUser && cUser.id
										? "anchor-fixed cls_loganchor"
										: "anchor-fixed"
								}
								onClick={this.scrollToTop}
							>
								<a>
									<span>
										{" "}
										<i
											className="fa fa-chevron-up"
											aria-hidden="true"
										></i>
									</span>{" "}
								</a>
							</div>
						</ScrollTop>
					)}
				</div>
				<div className="newcontacrf">
					<Footer />
				</div>
			</div>
		)
	}
}


var CategoryFilter = compose(
	graphql(GET_CATEGORIES, {
		name: "categoryInfo", options: () => ({
			fetchPolicy: "no-cache"
		})
	})
)(VendorSubCategoriesComponent);

export default withTranslation("common")(withStyles(styles)(CategoryFilter));